<template>
  <div class="table" :style="{ width: width, height: height }" style="display:flex !important; justify-content:left;">
    <table v-if="this.tableData && this.tableData.HOSPITALS && this.tableData.DATA">
      <thead>
        <tr>
          
          <th>병원</th>
          <template v-if="!isColumnZero('SINGLE')">
            <th>SINGLE</th>
          </template>
          <template v-if="!isColumnZero('DUO')">
            <th>DUO</th>
          </template>
          <template v-if="!isColumnZero('TRIO')">
            <th>TRIO</th>
          </template>
          <template v-if="!isColumnZero('QUARTET')">
            <th>QUARTET</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for="hospital in tableData.HOSPITALS" :key="hospital">
          <template v-if="!isColumnZero('SINGLE') && !isColumnZero('DUO') && !isColumnZero('TRIO') && !isColumnZero('QUARTET') ">
            <td style="text-align:left !important;">{{ hospital }} ({{ tableData.DATA[hospital].SINGLE + tableData.DATA[hospital].DUO +tableData.DATA[hospital].TRIO +tableData.DATA[hospital].QUARTET   }}) </td>
          </template>
          <template v-if="!isColumnZero('SINGLE')">
            <td style="text-align:right !important;">{{ tableData.DATA[hospital].SINGLE }} ({{ calculateProportion(hospital, 'SINGLE') }}%)</td>
          </template>
          <template v-if="!isColumnZero('DUO')">
            <td style="text-align:right !important;">{{ tableData.DATA[hospital].DUO }} ({{ calculateProportion(hospital, 'DUO') }}%)</td>
          </template>
          <template v-if="!isColumnZero('TRIO')">
            <td style="text-align:right !important;">{{ tableData.DATA[hospital].TRIO }} ({{ calculateProportion(hospital, 'TRIO') }}%)</td>
          </template>
          <template v-if="!isColumnZero('QUARTET')">
            <td style="text-align:right !important;">{{ tableData.DATA[hospital].QUARTET }} ({{ calculateProportion(hospital, 'QUARTET') }}%)</td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'HospitalTypeTable',
  props: {
    tableData: {
      type: Object,
      default: () => ({
        HOSPITALS: ['A', 'B', 'C', 'D'],
        DATA: {
          A: { SINGLE: 1, DUO: 2, TRIO: 2, QUARTET: 3 },
          B: { SINGLE: 3, DUO: 4, TRIO: 5, QUARTET: 5 },
          C: { SINGLE: 5, DUO: 6, TRIO: 4, QUARTET: 7 },
          D: { SINGLE: 8, DUO: 9, TRIO: 3, QUARTET: 10 }
        }
      })
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: 'auto'
    }
  },
  methods: {
    calculateProportion(hospital, type) {
      const data = this.tableData.DATA[hospital];
      const total = data.SINGLE + data.DUO + data.TRIO + data.QUARTET;
      
      const value = data[type];
      let proportion="0.00";
      if (total != 0){
        proportion = ((value / total) * 100).toFixed(2);
      }
      
      return proportion;
    },
    isColumnZero(type) {
      return Object.values(this.tableData.DATA).every(data => data[type] === 0);
    }
  }
}
</script>

<style scoped>
.table {
  margin-top: 12px;
  width: v-bind(width);
  height: v-bind(height);
}

.table th {
  background-color:#04AA !important;
  color:white !important;
  font-size:12px !important;

  padding-left:10px !important;
  padding-right:12px !important;
  padding-bottom:4px !important;
  padding-top:4px !important;
  text-align: center !important;
  border: 1px sold #04AA !important;
}

.table td {
  text-align: center;
  font-size: 11px; /* Smaller font size */
  padding:2px !important;
  margin-left:2px !important;
  border: 1px solid #04AA !important;
}
.table tbody {
  margin-top: 10px; /* 10px margin at the top of tbody */
}
.table tr {
  border-bottom: none;
}
</style>
