<template>
  <div class="chart-container">
    
    <Doughnut :data="this.chartData" :options="this.options" :width="this.width" :height="this.height" id="screen-doughnut-chart" />
    
    <div ref="labels" v-for="(text,index) in this.chartData.labels" :key="index" :style="this.positionStyle(index)">
      <!--font color="black"-->{{  this.display_text(text,index)  }}<!--/font-->
    </div>
    <div ref='legend' class="legend-container" style="font-size:10pt;font-weight:700"/>
  </div>
  
</template>

<script>


import { Doughnut } from 'vue-chartjs'

import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
//import { reactive } from 'vue'

ChartJS.register(ChartDataLabels, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'AreaScreenDonutChart',
  components: { Doughnut },
  props:{
    chartData:{
      type:Object,
      required:true
    },
    options:{
      type: Object,
      default:()=>{}
    },
    height:Number,
    width:Number
  },
  plugins:[ChartDataLabels],
  data(){
    return {labelPositions:[]}
  },
  mounted(){
    this.labelPositions = this.calculate_label_positions()

    this.createCustomLegend()
   
  },
  methods: {
    display_text(text,index){

      if (text == "..."){

        if (index == (this.labelPositions.length-1)){
          return text
        }
        return ""
      }
      return text
    },
    calculate_label_positions(){
      const statData = this.chartData.datasets[0].data

      //console.log("this.chartData:" + JSON.stringify(this.chartData))
      //console.log("statData:"+JSON.stringify(statData))
      // 총합 계산
      const totalValue = Object.values(statData).reduce((sum, value) => sum + value, 0);

      // 각 값을 비율에 따라 각도로 변환하고 좌표 계산
      let labelPositions = [];
      let currentAngle = - Math.PI / 2; // 시작 각도를 -90도로 설정 (라디안)

      // 원 중심 좌표 (예: 0, 0 기준으로 계산)
      const calibrate = 5

      // labels position 이동
      const xlab_move = -12
      const ylab_move = 0

      const centerX = this.width /2 - calibrate; 
      const centerY = this.height/2 - calibrate;
      const labelSpaceX = 35
      const labelSpaceY = 25
      const radiusX = (centerX)  + labelSpaceX;
      const radiusY = (centerY)  + labelSpaceY;



      Object.keys(statData).forEach(regionCode => {
          const value = statData[regionCode];
          const ratio = value / totalValue; // 값의 비율
          
          const angle = ratio * 2 * Math.PI; // 비율에 따른 각도 (라디안)
    
          // 현재 각도의 중심점에서 좌표 계산
          const x = centerX + radiusX * Math.cos(currentAngle + angle / 2) + xlab_move;
          const y = centerY + radiusY * Math.sin(currentAngle + angle / 2) + ylab_move;


          labelPositions.push({ x, y });

          // 현재 각도 업데이트
          currentAngle += angle;
      });

      //console.log(labelPositions);
      return labelPositions
    },
    createCustomLegend() {
      const slice_index = 4
      const legendContainer = this.$refs.legend
      const ul = document.createElement('ul')
      this.chartData.legendlabels.slice(0,slice_index).forEach((legendtext, index) => {
        const li = document.createElement('li')
        li.style.color = this.chartData.datasets[0].backgroundColor[index]
        li.style.fontSize = '11px'
        li.style.listStyle='none'
        li.style.padding ='0'
        li.style.margin='0'
        li.textContent = legendtext
        li.style.position ='relative'
        li.style.left = '-15px';
        li.style.top = '5px';


        ul.appendChild(li)

        // if ((slice_index-1) == index){
        //   const lietc = document.createElement('li')
        //   lietc.style.color = this.chartData.datasets[0].backgroundColor[index+1]
        //   lietc.textContent = "..."
        //   ul.appendChild(lietc)
        // }
      })
      legendContainer.appendChild(ul)


    },
    positionStyle(index){
      if (this.labelPositions.length == 0)
        return { position:'absolute', left:0 + "px", top:0+"px", color:'#000000', 'font-size': "10px", 'font-weight':600}
 

      const position = this.labelPositions[index]  
      //console.log("position:"+JSON.stringify(position))
      const color = this.chartData.datasets[0].backgroundColor[index]
      
      return { position:'absolute', left:position.x + "px", top:position.y+"px", color:color, 'font-size': "10px", 'font-weight':600}
    }
  }


}

</script>


<!--/script-->
<style scoped>
.lable-container{
  position: absolute;
  top: 0%;
  left: 0%;
  transform: translate(-50%, -50%);
}
.chart-container {
  position: relative;
}
.legend-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
