<template>
  <statview-manager-data ref="statviewManagerData" @stat_loaded="barChartStatDataLoaded"  />

  <div class="d-flex flex-row" id='#layout-schematics-frame-row-height' >
    <div class="d-flex flex-column" id='#layout-schematics-frame-row-space' style="height:60px;width:100%">
    </div>
  </div>
  <div class="d-flex flex-row div-total-width" id='#layout-schematics-frame'  >
    <!-- 시작: 왼쪽 여백 -->
    <!--div class="d-flex flex-column" id="#layout-left-space" style="width:0px"> 
    </div-->
    <!-- 끝: 왼쪽 여백 -->
    <!--시작: 실제 contents-->
    <div class="d-flex flex-column div-total-width" id='#layout-schematics-pages' >
      <!--시작: 첫번째 줄 대시보드 통계 WES, Patients, Familiies, Completed, In Progress, Pre -->
      <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space" style="height:110px">
        <div class="d-flex flex-column" id="#layout-left-space" style="width:270px"> 
        </div>
        <div class="d-flex flex-column div-logo-layout" id='#layout-left-frame-leftcolumn' >
          <img src="../../assets/sig_1.png" class="img-fluid img-logo-image" alt="소아암희귀질환사업단로고">
        </div>
        <div class="d-flex flex-column" id="#layout-left-space" style="width:30px"> 
        </div>
        <div class="d-flex flex-column div-dash-item-layout" id='#layout-schematics-pages-contents-status-row-title' >
          <div v-if="this.statDash" >
            WES {{ this.priceToString(this.statDash["TOTAL"]) }} 명 <br/>
            Patients {{ this.priceToString(this.statDash["PROBAND"]) }} 명 <br/>
            Families {{ this.priceToString(this.statDash["FAMILY"]) }} 명 <br/>
          </div>
        </div>
        <div class="d-flex flex-column" id="#layout-left-space" style="width:50px"> 
        </div>
        <div class="d-flex flex-column" id="#layout-left-space" style="width:50px"> 
        </div>
        <div class="d-flex flex-column div-logo-layout" id='#layout-left-frame-leftcolumn' >
          <img src="../../assets/sig_2.png" class="img-fluid img-logo-image" alt="희귀질환사업부로고" >
        </div>
        <div class="d-flex flex-column" id="#layout-left-space" style="width:30px"> 
        </div>
        <div class="d-flex flex-column div-dash-item-layout" id='#layout-schematics-pages-contents-status-row-title' >
          <div v-if="this.statDash">
            Completed {{ this.priceToString(this.statDash["SCRCOMPLETE"]) }} 명 <br/>
            In Progress  {{ this.priceToString(this.statDash["SCRING"]) }} 명 <br/>
            Pre {{ this.priceToString(this.statDash["SCRPRE"]) }} 명 <br/>
          </div>
        </div>
        <div class="d-flex flex-column" id="#layout-left-space" style="width:270px"> 
        </div>
        
      </div>
      <!--끝: 첫번째 줄 대시보드 통계 WES, Patients, Familiies, Completed, In Progress, Pre -->

      <!-- 시작: DASH 보드 다음 공백 -->
      <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space">
        <div class="d-flex flex-column" id='#layout-schematics-pages-contents-status-row-title' style="width:100%;height:2px">
        </div>
      </div>
      <!-- 끝: DASH 보드 다음 공백 -->

      <!-- 시작: 통계 제목 -->
      <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space" style="width:100%">
        <div class="d-flex flex-column" id='#layout-schematics-pages-contents-status-row-title' style="width:100%">
          <div class="p-3 bg-gradient text-white div-title-layout" >
            유전체/기능연구 기반 희귀질환 진단지원 사업 참여 환자 전국 현황
          </div>
        </div>
      </div>
      <!-- 끝: 통계 제목  -->

      <!-- 시작: 제목과 통계 사이 여백 -->
      <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space" style="width:100%">
        <div class="d-flex flex-column" id='#layout-schematics-pages-contents-status-row-title' style="width:100%;height:10px">
        </div>
      </div>
      <!-- 끝: 제목과 통계 사이 여백  -->

      <!-- 시작: 지역별 진단율 -->
      <!-- 시작: 통계 제목 -->
      <div class="d-flex flex-column div-stat-column-layout" id='#layout-schematics-pages-contents-status-column-title' style="width:100%">

        <!-- 시작: 지역별 세부 진단율 현황 제목 -->
        <div class="d-flex flex-row" style="width:100%">
          <div class="d-flex flex-column div-stat-region-layout" style="width:100%">
            <div class="p-3 bg-gradient div-stat-region-title-layout" >
              <!--label class="label-stat-title" >지역별 세부 진단 현황 요약 (SCREEND: {{ this.priceToString(this.statAreaScreen['TOTAL'])}} 명 / UNDETERMINED {{ this.priceToString(this.statAreaScreen['UNDETERMINED']) }} 명)< </label-->
              <label class="label-stat-title" v-if="this.statAreaScreenProcData != undefined">지역별 세부 진단 현황 요약 (SCREEND: 명 / UNDETERMINED 명) </label>

            </div>
          </div>
        </div>
        <!-- 끝: 지역별 세부 진단율 현황 제목 -->

        <!-- 시작: 제목과 통계 사이 여백 -->
        <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space" style="width:100%">
          <div class="d-flex flex-column" id='#layout-schematics-pages-contents-status-row-title' style="width:100%;height:10px">
          </div>
        </div>
        <!-- 끝: 제목과 통계 사이 여백  -->
        <div class="d-flex flex-row" style="width:100%">
          
          <!-- 시작: NEGATIVE -->
          <div class="d-flex flex-column">
            <div class="d-flex flex-row">  
              <!-- 시작: NEGATIVE 진단율 제목  -->
              <div class="d-flex flex-column ">
                <div class="p-3 bg-gradient div-stat-subtitle-layout" v-if="this.getStatAreaScreen() != undefined"  @click="this.toggleTable('NEGATIVE')">
                  <label class="label-stat-title" v-if="this.isToggleTables['NEGATIVE']">NEGATIVE  {{this.statAreaScreen['META']['NEGATIVE_TOTAL']}} 명 (표 보기)</label> 
                  <label class="label-stat-title" v-else>NEGATIVE  {{this.statAreaScreen['META']['NEGATIVE_TOTAL']}} 명 (차트 보기)</label> 
                </div>
              </div>
              <!-- 끝: NEGATIVE 진단율 제목  -->

              <div class="d-flex flex-column div-stat-chart-empty-layout">

              </div>
            </div>
            <div class="d-flex flex-row"> 
              <!-- 시작: NEGATIVE 진단율 piechart -->
              <div class="d-flex flex-column div-stat-chart-layout" v-if="this.isToggleTables['NEGATIVE']">
                <div class="div-stat-chart-layout" >
                  <area-screen-donut-chart  ref="areaScreenDonutChart" :chartData="areascreen_data_collection_negative" 
                    :options="areascreen_option_setting" :width="270" :height="270" v-if="this.statAreaScreenProcData!= undefined"/>
                </div>
              </div>
              <div class="d-flex flex-column div-stat-table-layout" v-else>
                <div class="div-stat-table-layout">
                  <area-screen-donut-table  ref="areaScreenDonutTable" :tableData="areascreen_data_collection_negative_table" 
                     :width="270" :height="270" v-if="this.statAreaScreenProcTable!= undefined"/>
                </div>
              </div>
              <!-- 끝: NEGATIVE 진단율 piechart -->
            </div>           
          </div>
          <!-- 끝: NEGATIVE -->
          <!-- 시작: POSITIVE -->
          <div class="d-flex flex-column">
            <div class="d-flex flex-row">  
              <!-- 시작: POSITIVE 진단율 제목  -->
              <div class="d-flex flex-column ">
                <div class="p-3 bg-gradient div-stat-subtitle-layout" v-if="this.getStatAreaScreen() != undefined"  @click="this.toggleTable('POSITIVE')">
                  <label class="label-stat-title" v-if="this.isToggleTables['POSITIVE']">POSITIVE  {{this.statAreaScreen['META']['POSITIVE_TOTAL']}} 명 (표 보기)</label> 
                  <label class="label-stat-title" v-else>POSITIVE  {{this.statAreaScreen['META']['POSITIVE_TOTAL']}} 명 (차트 보기)</label> 
                </div>
              </div>
              <!-- 끝: POSITIVE 진단율 제목  -->
              <div class="d-flex flex-column div-stat-chart-empty-layout">

              </div>
            </div>
            <div class="d-flex flex-row"> 
              
              <!-- 시작: POSITIVE 진단율 piechart -->
              <div class="d-flex flex-column div-stat-chart-layout" v-if="this.isToggleTables['POSITIVE']">
                <div class="div-stat-chart-layout" >
                  <area-screen-donut-chart  ref="screenDonutChart" :chartData="areascreen_data_collection_positive" 
                    :options="areascreen_option_setting" :width="270" :height="270"  v-if="this.statAreaScreenProcData!= undefined"/>
                </div>
              </div>
              <div class="d-flex flex-column div-stat-table-layout" v-else>
                <div class="div-stat-table-layout">
                  <area-screen-donut-table  ref="areaScreenDonutTable" :tableData="areascreen_data_collection_positive_table" 
                     :width="270" :height="270" v-if="this.statAreaScreenProcTable!= undefined"/>
                </div>
              </div>
              <!-- 끝: POSITIVE 진단율 piechart -->
            </div>           
          </div>
          <!-- 끝: POSITIVE -->
          <!-- 시작: INCONCLUSIVE -->
          <div class="d-flex flex-column">
            <div class="d-flex flex-row">  
              <!-- 시작: INCONCLUSIVE 진단율 제목  -->
              <div class="d-flex flex-column ">
                <div class="p-3 bg-gradient div-stat-subtitle-layout" v-if="this.getStatAreaScreen() != undefined"  @click="this.toggleTable('INCONCLUSIVE')">
                  <label class="label-stat-title" v-if="this.isToggleTables['INCONCLUSIVE']">INCONCLUSIVE  {{this.statAreaScreen['META']['INCONCLUSIVE_TOTAL']}} 명 (표 보기)</label> 
                  <label class="label-stat-title" v-else>INCONCLUSIVE  {{this.statAreaScreen['META']['INCONCLUSIVE_TOTAL']}} 명 (차트 보기)</label> 
                </div>
              </div>
              <!-- 끝: INCONCLUSIVE 진단율 제목  -->

              <div class="d-flex flex-column div-stat-chart-empty-layout">

              </div>
            </div>
            <div class="d-flex flex-row"> 
              <!-- 끝: INCONCLUSIVE 진단율 제목  -->
              <!-- 시작: INCONCLUSIVE 진단율 piechart -->
              <div class="d-flex flex-column div-stat-chart-layout" v-if="this.isToggleTables['INCONCLUSIVE']">
                <div class="div-stat-chart-layout" >
                  <area-screen-donut-chart  ref="screenDonutChart" :chartData="areascreen_data_collection_inconclusive" 
                    :options="areascreen_option_setting" :width="270" :height="270"  v-if="this.statAreaScreenProcData!= undefined"/>
                </div>
              </div>
              <div class="d-flex flex-column div-stat-table-layout" v-else>
                <div class="div-stat-table-layout">
                  <area-screen-donut-table  ref="areaScreenDonutTable" :tableData="areascreen_data_collection_inconclusive_table" 
                     :width="270" :height="270" v-if="this.statAreaScreenProcTable!= undefined"/>
                </div>
              </div>
              <!-- 끝: INCONCLUSIVE 진단율 piechart -->
            </div>           
          </div>
          <!-- 끝: INCONCLUSIVE -->

          <!-- 시작: UNDETERMINED -->
          <div class="d-flex flex-column">
            <div class="d-flex flex-row">  
              <!-- 시작: UNDETERMINED 진단율 제목  -->
              <div class="d-flex flex-column ">
                <div class="p-3 bg-gradient div-stat-subtitle-layout" v-if="this.getStatAreaScreen() != undefined"  @click="this.toggleTable('UNDETERMINED')">
                  <label class="label-stat-title" v-if="this.isToggleTables['UNDETERMINED']">UNDETERMINED  {{this.statAreaScreen['META']['UNDETERMINED_TOTAL']}} 명 (표 보기)</label> 
                  <label class="label-stat-title" v-else>UNDETERMINED  {{this.statAreaScreen['META']['UNDETERMINED_TOTAL']}} 명 (차트 보기)</label> 
                </div>
              </div>
              <!-- 끝: UNDETERMINED 진단율 제목  -->

              <div class="d-flex flex-column div-stat-chart-empty-layout">

              </div>
            </div>
            <div class="d-flex flex-row"> 
              <!-- 끝: UNDETERMINED 진단율 제목  -->
              <!-- 시작: UNDETERMINED 진단율 piechart -->
              <div class="d-flex flex-column div-stat-chart-layout" v-if="this.isToggleTables['UNDETERMINED']">
                <div class="div-stat-chart-layout" >
                  <area-screen-donut-chart  ref="screenDonutChart" :chartData="areascreen_data_collection_undetermined" 
                    :options="areascreen_option_setting" :width="270" :height="270"  v-if="this.statAreaScreenProcData!= undefined"/>
                </div>
              </div>
              <div class="d-flex flex-column div-stat-table-layout" v-else>
                <div class="div-stat-table-layout">
                  <area-screen-donut-table  ref="areaScreenDonutTable" :tableData="areascreen_data_collection_undetermined_table" 
                     :width="270" :height="270" v-if="this.statAreaScreenProcTable!= undefined"/>
                </div>
              </div>
              <!-- 끝: UNDETERMINED 진단율 piechart -->
            </div>           
          </div>
          <!-- 끝: UNDETERMINED -->
        </div>
        

      </div>

    </div>
  </div>
  <!--끝: 실제 contents-->

</template>


<script>

//import base_parser from '@/helper/base_parser.js'


// import donutchart from './DonutChart.vue'
// import HospitalTypeTable from './HospitalTypeTable.vue'
// import HospitalTypeChart from './HospitalTypeChart.vue'
// import HospitalScreenChart from './HospitalScreenChart.vue'
// import HospitalScreenTable from './HospitalScreenTable.vue'


// import ScreenDonutChart from './ScreenDonutChart.vue'
// import ReqTypeDonutChart from './ReqTypeDonutChart.vue'

import StatViewManagerData from './StatViewManagerData.vue'

import AreaScreenDonutChart from './AreaScreenDonutChart.vue'
import AreaScreenDonutTable from './AreaScreenDonutTable.vue'

import {reactive} from 'vue';

export default {
  name:'StatViewManagerAreaScreen',
  components: {
    'statview-manager-data': StatViewManagerData,
    'area-screen-donut-chart': AreaScreenDonutChart,
    'area-screen-donut-table': AreaScreenDonutTable

  },
  props: {
  },

  data() {

    const report_types =['NEGATIVE','POSITIVE','INCONCLUSIVE','UNDETERMINED']

    const isToggleTables = reactive(Object.fromEntries(
      report_types.map(type => [type, true])
    ));

    // const 
    var datasets = {
      label:['진단율 현황'],
      backgroundColor:['#33dfff','#aaffee','#334555'],
      // backgroundColor:background_colors,
      data: [],
      datalabels:{ // 레이블 위치 옵션
        align:'start',
        anchor:'start',
        clamp:false,
        offset:-40,
      }
    }
    var statAreaScreenChart ={
      labels:['Positive','Negative', 'Inconclusive'],
      //labels: ['양성','음성', '미결정','검사진행중'],
      datasets: [datasets],
      legendlabels:[
        'LEGEND1','LEGEND2','LEGEND3'
      ]
    }


    var statAreaScreenTable = {
      AREA: ['A', 'B', 'C', 'D'],
      DATA: {
        A: { NUMBER: 1, RATIO: "2.0"},
        B: { NUMBER: 1, RATIO: "2.0"},
        C: { NUMBER: 1, RATIO: "2.0"},
        D: { NUMBER: 1, RATIO: "2.0"}
      }
    }
    return {
      statDash : undefined,      // 대쉬 보드 현황 통계 
      statAreaScreen : undefined, // 지역별 진단율 서버에서 받은 통계
      statAreaScreenProcData: undefined, // 지역별 진단율 처리한 통계 for  Chart
      statAreaScreenProcTable:undefined, //지역별 진단율 처리한 통계 for Table
      statAreaScreenChart,
      statAreaScreenTable,
      colorFirstHalfHexArray:[],
      colorLastHalfHexArray:[],
      report_types,
      isToggleTables


  //---------------
    }
  },
  async created() {

  },
  async mounted() {
    this.summarize();
  },
  computed:{
    areascreen_data_collection_negative_table(){
      if (this.statAreaScreenProcTable['NEGATIVE'] == undefined){
        return this.statAreaScreenTable
      }
      // console.log("this.statAreaScreenProcData['NEGATIVE']:"+JSON.stringify(this.statAreaScreenProcData['NEGATIVE']))
      return this.statAreaScreenProcTable['NEGATIVE']


    },
    areascreen_data_collection_positive_table(){
      if (this.statAreaScreenProcTable['POSITIVE'] == undefined){
        return this.statAreaScreenTable
      }
      // console.log("this.statAreaScreenProcData['NEGATIVE']:"+JSON.stringify(this.statAreaScreenProcData['NEGATIVE']))
      return this.statAreaScreenProcTable['POSITIVE']


    },
    areascreen_data_collection_inconclusive_table(){
      if (this.statAreaScreenProcTable['INCONCLUSIVE'] == undefined){
        return this.statAreaScreenTable
      }
      // console.log("this.statAreaScreenProcData['NEGATIVE']:"+JSON.stringify(this.statAreaScreenProcData['NEGATIVE']))
      return this.statAreaScreenProcTable['INCONCLUSIVE']


    },
    areascreen_data_collection_undetermined_table(){
      if (this.statAreaScreenProcTable['UNDETERMINED'] == undefined){
        return this.statAreaScreenTable
      }
      // console.log("this.statAreaScreenProcData['NEGATIVE']:"+JSON.stringify(this.statAreaScreenProcData['NEGATIVE']))
      return this.statAreaScreenProcTable['UNDETERMINED']


    },
    areascreen_data_collection_negative(){
      if (this.statAreaScreenProcData['NEGATIVE'] == undefined){
        return this.statAreaScreenChart
      }
      // console.log("this.statAreaScreenProcData['NEGATIVE']:"+JSON.stringify(this.statAreaScreenProcData['NEGATIVE']))
      return this.statAreaScreenProcData['NEGATIVE']

    },
    areascreen_data_collection_positive(){
      if (this.statAreaScreenProcData == undefined){
        return this.statAreaScreenChart
      } 
      return this.statAreaScreenProcData['POSITIVE']

    },
    areascreen_data_collection_inconclusive(){
      if (this.statAreaScreenProcData == undefined){
        return this.statAreaScreenChart
      } 
      return this.statAreaScreenProcData['INCONCLUSIVE']


    },
    areascreen_data_collection_undetermined(){
      if (this.statAreaScreenProcData == undefined){
        return this.statAreaScreenChart
      } 
      return this.statAreaScreenProcData['UNDETERMINED']

    },

    // 지역별 진단율 현황 donut chart의 옵션 설정
    areascreen_option_setting(){
      var options={
        plugins: {
          legend: {
            display: false,
            position: "chartArea",
            align:"center", // legend vertical 위치
            labels: {
              boxWidth: 8,
              padding: 10,
              usePointStyle: true,
              pointStyle: "circle",
              font: {
                size: 7.5
              }
            },
            fullSize: true
          },
          tooltip: {
            boxWidth: 15,
            bodyFont: {
              size: 14
            }
          },
          datalabels:{  //options plugins datalabels
            display: false, // default는 true, false 로 하면 값을 숨길 수 있다.
//            align: "end",
//            anchor: "end",
//            borderWidth: 2,
//            padding: {
//              bottom: 0,
//              left: 20,
//            },
            labels:{
              title:{
                font:{
                  weight:'bold'
                }
              },
              value:{
                color:'blue'
              }
            }
            
          },
        },
        maintainAspectRatio: false,
      //  maintainAspectRatio: true,
      //  cutoutPercentage: 120, // 중앙 공간 설정 - 안먹힘
        responsive: true,       
        layout: {
          padding: {
            top: 0,
            bottom: 0
          }
        },
        elements: {
          arc: {
            borderWidth: 2
          }
        },
        animation: {
          duration: 1000
        },
        cutout: '60%' // 안쪽 원 크기 제한 숫자 클수록 넓어짐
      }

      return options;
    },
    
  },
  methods: {
    toggleTable(result_type){
      this.isToggleTables[result_type] = !this.isToggleTables[result_type]
      
    },
    priceToString(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    // RegionBarChart callback function for emit 'stat_loaded' function
    barChartStatDataLoaded(){
      // alert('StatView:barChartStatDataLoaded()'+JSON.stringify(value))
      this.statDash =  this.$refs['statviewManagerData'].getStatDash();


      this.statAreaScreen = this.$refs['statviewManagerData'].getStatAreaScreen();

      //console.log("this.statAreaScreen:"+ JSON.stringify(this.statAreaScreen ))

      this.summarize()
      this.calculate_areascreen_data_collection()
      this.calculate_areascreen_data_table_collection()

    },
    get_report_type(){
      return this.report_types
    },
    getStatAreaScreen(){
      if (this.statAreaScreen == undefined ){
        return undefined
      }
      return this.statAreaScreen;

    },    
    getStatDash(){
      if (this.statDash == undefined ){
        return undefined
      }
      return this.statDash;
    },
    
    // summarize data received from child component
    summarize(){
      // console.log("summarize()")
      // alert("StatView::summarize()")
      this.colorFirstHalfHexArray = this.$refs['statviewManagerData'].getTotalColorFirstHalfHexArray()
      this.colorLastHalfHexArray= this.$refs['statviewManagerData'].getTotalColorLastHalfHexArray()
      

    },
    calculate_areascreen_data_table_collection(){
      let statAreaScreenResult = this.getStatAreaScreen()

      if (statAreaScreenResult == undefined)
        return this.statAreaScreenTable


      
      let meta_data = statAreaScreenResult['META']
      let area_data = meta_data['AREA']

      const report_types = this.get_report_type()
      // Our target
      this.statAreaScreenProcTable={}
      for (var v=0;v <report_types.length;v++){
        const report_type = report_types[v]
        console.log("report_type:"+ report_type)        

        const statData = statAreaScreenResult[report_type]

        const statDataTotal = statAreaScreenResult['META'][report_type+"_TOTAL"]

        // 시작 : stat_keys from statData
        //-------------------------------------------------------
        // [key, value] 배열로 변환 후 value 기준으로 내림차순 정렬
        // object.entries() // statData를 [key,value] 형태로 변환
        const sortedArray = Object.entries(statData).sort((a, b) => b[1] - a[1]);
        //console.log("sorted sortedArray by values:"+JSON.stringify(sortedArray))

        // 정렬된 배열을 다시 객체 형태로 변환하거나 그대로 사용 가능
        const stat_keys = sortedArray.map(([key, value]) => ({ [key]: value }));

        //console.log("sorted stat_keys by values:"+JSON.stringify(stat_keys))
        // 끝 : stat_keys from statData 
        //-------------------------------------------------------

        // statAreaScreenTable은 간단해서 굳이 JSON.parse(JSON.stringify(this.statAreaScreenTable)) 해서 가져 오지 않는다.
        // var statAreaScreenTable = JSON.parse(JSON.stringify(this.statAreaScreenTable))
        var statAreaScreenTable={}
        statAreaScreenTable['AREA']=[]
        statAreaScreenTable['DATA']={}
         // var statAreaScreenTable = {
          //   AREA: ['A', 'B', 'C', 'D'],
          //   DATA: {
          //     A: { NUMBER: 1, RATIO: "2.0%"},
          //     B: { NUMBER: 1, RATIO: "2.0%"},
          //     C: { NUMBER: 1, RATIO: "2.0%"},
          //     D: { NUMBER: 1, RATIO: "2.0%"}
          //   }
          // }
        
        for (let k=0;k <stat_keys.length;k++){

          const area_info = stat_keys[k]
          // area_code와 area_name 추출
          const area_code = Object.keys(area_info)[0]; // 객체의 키
          const area_name = area_data[area_code]
          const area_value = Object.values(area_info)[0]; // 객체의 값

          //console.log("k:"+k+",statDataTotal:"+statDataTotal+",area_code:"+area_code+",area_name:"+area_name+",area_value:"+area_value)

          // statAreaScreenTable['AREA'] 구성
          statAreaScreenTable['AREA'].push(area_name)

          var area_ratio = 0
          if (statDataTotal != 0){
            area_ratio = area_value/statDataTotal;
          }

          statAreaScreenTable['DATA'][area_name]={'NUMBER': area_value, 'RATIO': (100 * area_ratio).toFixed(1)}


        }
        this.statAreaScreenProcTable[report_type] = statAreaScreenTable
      }
      

      console.log("this.statAreaScreenProcTable:"+JSON.stringify(this.statAreaScreenProcTable))
    },
    // 진단율 현황을 지역별로 렌더링하기 위해 데이터를 구체화함
    // get Data
    calculate_areascreen_data_collection(){

      let statAreaScreenResult = this.getStatAreaScreen()

      if (statAreaScreenResult == undefined)
        return this.statAreaScreenChart

      //console.log("statAreaScreenResult:"+JSON.stringify(statAreaScreenResult))


      //const result_type='NEGATIVE'
      let meta_data = statAreaScreenResult['META']
      let area_data = meta_data['AREA']
      //console.log("area_data:" + JSON.stringify(area_data))


      // backgroundColor
      //let background_colors =["#F2F6FF", "#E6F2FF","#CCE6FF"]
      const background_colors = [
        "#0000FF", // 깊은 파란색
        "#1919FF",
        "#3C3CFF",
        "#4A4AFF",
        "#5858FF",
        "#6464FF",
        "#7272FF",
        "#7E7EFF",
        "#8A8AFF",
        "#9191FF",
        "#A0A0FF",
        "#B5B5FF",
        "#C8C8FF",  // 옅은 파란색
        "#E1E1FF", // 매우 옅은 파란색
        "#E8E8FF",
        "#F2F2FF",
        "#CCCCEE",
        "#DDDDDD",  
      ]
      const report_types = this.get_report_type()
      // Our target
      this.statAreaScreenProcData={}
      for (var v=0;v <report_types.length;v++){
        const report_type = report_types[v]
        

        const statData = statAreaScreenResult[report_type]

        const statDataTotal = statAreaScreenResult['META'][report_type+"_TOTAL"]


        //console.log("statData:"+JSON.stringify(statData))

        // 시작 : stat_keys from statData
        //-------------------------------------------------------
        // [key, value] 배열로 변환 후 value 기준으로 내림차순 정렬
        // object.entries() // statData를 [key,value] 형태로 변환
        const sortedArray = Object.entries(statData).sort((a, b) => b[1] - a[1]);
        //console.log("sorted sortedArray by values:"+JSON.stringify(sortedArray))

        // 정렬된 배열을 다시 객체 형태로 변환하거나 그대로 사용 가능
        const stat_keys = sortedArray.map(([key, value]) => ({ [key]: value }));

        //console.log("sorted stat_keys by values:"+JSON.stringify(stat_keys))
        // 끝 : stat_keys from statData 
        //-------------------------------------------------------

        // 시작 :
        //------------------------------------------------------
        var statAreaScreenChart = JSON.parse(JSON.stringify(this.statAreaScreenChart))
        statAreaScreenChart['legendlabels']=[]
        statAreaScreenChart['labels']=[]

        statAreaScreenChart['datasets'][0]['weight']=1
        statAreaScreenChart['datasets'][0]['backgroundColor'] = background_colors
        for (let k=0;k <stat_keys.length;k++){

          const area_info = stat_keys[k]
          // area_code와 area_name 추출
          const area_code = Object.keys(area_info)[0]; // 객체의 키
          const area_name = area_data[area_code]
          const area_value = Object.values(area_info)[0]; // 객체의 값

          var area_ratio = 0
          if (statDataTotal != 0){
            area_ratio = area_value/statDataTotal;
          }
           
          
          statAreaScreenChart['legendlabels'].push(area_name + " ["+this.priceToString(area_value) +"명,"+((100 * area_ratio)).toFixed(1)+" %]") // Legend 표기 Type[n명, ratio%]

          if (area_ratio < 0.02){ // 1% 미만이면 ...으로 레이블 추가함
            statAreaScreenChart['labels'].push("...")
          }
          else{
            statAreaScreenChart['labels'].push(area_name +" "+(100 * area_ratio).toFixed(1) +"%")
          }
          statAreaScreenChart['datasets'][0]['data'].push(area_value)
          
        }

        this.statAreaScreenProcData[report_type]=statAreaScreenChart
        //console.log("this.statAreaScreenProcData["+report_type+"]:"+JSON.stringify(this.statAreaScreenProcData[report_type]))

      }

    }
  }
}

</script>


<style lang="scss" scoped>

.div-stat-table-container{
  position: relative !important;
  top: 0px !important;
  left: 0px !important;
}

.div-stat-chart-container{
  position: absolute !important;
  top: 470px !important;
  left: 0px !important;
}

.div-stat-chart-layout{
  width: 335px !important;
  height: 335px !important;
  border-radius:0px !important;
  display:flex !important;
  justify-content:center !important;
  align-items:center !important;
  padding:40px !important;
}

.div-stat-table-layout{
  width: 335px !important;
  height: 335px !important;
  border-radius:0px !important;
  display:flex !important;
  justify-content:top !important;
  align-items:top !important;
  padding:10px !important;
}
.div-stat-chart-empty-layout{
  width: 60px !important;
  border-radius:0px !important;
  display:flex !important;
}

/* USED */

.div-stat-region-layout{
  width:1010px  !important;
}

.div-stat-column-layout{
  width:100% !important;
  height:20px !important;
  
}
.img-logo-image{
  vertical-align:middle !important;
  width:110px !important;
}

.div-logo-layout{
  width:110px !important;
  height:110px !important;
  vertical-align:middle !important
}
.div-dash-item-layout{
  width:300px !important;
  height:100px !important;
  border:solid 1px !important;
  border-color:#D9D9D9 #D9D9D9 #D9D9D9 #D9D9D9 !important;
  font-weight:bold !important;
  color:#172F6B !important;
  font-size:1.0rem !important;
  justify-content:center !important;
  padding:10px !important;

}
.div-total-width{
  width:1520px !important;
  text-align:left !important;
}
.div-title-layout{
  width:100% !important;
  height:40px !important;
  border-radius:0px !important;
  background-color:#0B2463 !important;
  display:flex !important;
  justify-content:center !important;
  align-items:center !important;
  font-weight:700 !important;
  font-size:16px !important;
  padding:0px !important;
}


.div-stat-region-title-layout {
  width:1520px !important;
  height:20px !important;
  border-radius:0px !important;
  background-color:#1b49be !important;
  display:flex !important;
  flex-direction:column !important;
  justify-content:center !important;
  text-align:center !important;
}
/* sub title */
.div-stat-subtitle-layout {
  width:335px !important;
  height:30px !important;
  border-radius:0px !important;
  background-color:#5581f0 !important;
  display:flex !important;
  flex-direction:column !important;
  justify-content:center !important;
  text-align:center !important;

}
.label-stat-title{
  font-size:14px;
  color:#ffffff;
  font-weight:600;
}

</style>
