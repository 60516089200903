<template>

<div>
    <div class="loading text-center" v-if="this.is_loading()"><img src="../../assets/loader.gif" ></div>
    <!-- 파일 보고 width:1400px -->
    <div class="containter" :class="{ 'blur' : this.is_loading() }" style="width:1390px;margin:0">
        
        <div class="row"  style="margin:0;width:100%">
            <div class="col " style="width:100%;margin:0">
                <b><font size="3">진단 결과 보고 관리</font></b> 
            </div>
        </div>
        <div class="row" style="margin:0;height:20px">
            <div class="col" style="margin:0;justify-content: left;" >
                <hr size="1" width="90%" />
            </div>
        </div>
        
        <div class="row fixed-global-width" style="margin:0;height:30px">
            <div class="col fixed-global-width" style="margin:0" align='left'>
                
            </div>
        </div>
        <div class="row fixed-global-width" style="margin:0">
            <div class="col fixed-global-width section-border-gray" style="margin:0" >
                
                
                <div class="row" >
                    <div class="col" style="text-align:left;height:10px">
                    
                    </div> 
                </div>
                <div class="row fixed-global-width " style="margin:0" >
                    <div class="col fixed-global-width " style="margin:0;justify-content: center;" >
                    <table >
                        <tr>
                            <th> 보고형식</th>
                            <th> 파일관리</th>
                            <th> 파일명</th>
                            <th> 검사명</th>
                            <th> 검사결과</th>
                            <th> 보고</th>
                        </tr>
                        <!-- 시작: FILE 보고 -->
                        <tr >
                            <td width="100px" style="text-align:left">
                                FILE 보고
                            </td>
                            <!-- 파일 관리-->
                            <td width="400px" style="text-align:center">
                            <table border="0" width="100%">
                            <tr>
                                <td width="200px">
                                    <input for="lab-report-file" type="file" style="height:20;font-size:14px" ref="ref_store_files" id="ref_store_files"
                                    @change="this.change_file($event.target)" :disabled="this.is_already_uploaded_files()"/>
                                
                                </td>
                                <td width="100px">
                                    <button href="#" class="btn btn-primary btn-sm btn-search-action" :disabled="this.changed_upload_file" id="ref_report_upload" ref="ref_report_upload"
                                    @click="this.upload_file()"
                                > Upload </button>
                                </td>
                                <td width="100px">
                                    <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_file_removed"
                                    style="float:center; margin-right: 10px;" @click="this.file_removed(this.report_wfile_data.patientfiles_id)" :disabled="this.report_wfile_data['patientfiles_id']==''">
                                    Delete</button>
                                </td>

                            </tr>
                            </table>
                            </td>
                            <!-- 파일 명-->
                            <td width="400px" class="word-wrap-func" style="text-align:center">
                                <div @click.stop="" @click="this.file_download(this.report_wfile_data.file_path, this.report_wfile_data.filename)">
                                    <font color="#0000ff">{{ this.report_wfile_data.filename }} </font>
                                </div>
                            </td>
                            <td width="100px">  
                                <!-- seq_type -->
                                
                                <select id="sel_seq_type_wfile" ref="sel_seq_type_wfile" name="sel_seq_type_wfile" 
                                    v-model="this.report_wfile_data.seq_type" class="form-select-sm select-search" style="width:100px;float:center"
                                    @change="this.change_seq_type('WFILE')"
                                    >
                                    <option value="WES" selected>WES</option>
                                    <option value="WGS" >WGS</option> 
                                </select>
                            </td>
                            <td>
                                
                                <!-- report_result -->
                                <select id="sel_report_result_wfile" ref="sel_report_result_wfile" name="sel_report_result_wfile"
                                    v-mode="this.report_wfile_data.report_result" class="form-select-sm select-search" style="width:100px;float:center"
                                    @change="this.change_report_result('WFILE')"
                                    >
                                    <option value="" selected>-</option>
                                    <option value="INC">Inconclusive</option>
                                    <option value="POS">Positive</option>
                                    <option value="NEG">Negative</option>
                                </select>
                            </td>
                            <td>
                                <!--button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_file_report"
                                    style="float:center; margin-right: 10px;" @click="this.report_delivered( report_wfile_data, fidx)" :disabled="report_wfile_data.report_date !=''">보고</button-->
                                <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_file_report"
                                    style="float:center; margin-right: 10px;" @click="this.report_delivered( this.report_wfile_data, 'WFILE')" :disabled="this.report_wfile_data['patientfiles_id']==''">
                                    Report
                                </button>
                            </td>
                        </tr>
                        <!-- 끝: FILE 보고 -->
                        <!-- 시작: FILE 없이 보고-->
                        <tr >


                            <td width="100px" style="text-align:left">
                                
                                FILE 없이 보고
                            </td>
                            <!-- 파일 관리-->
                            <td width="400px" style="text-align:center">

                            </td>
                            <!-- 파일 명-->
                            <td width="400px" class="word-wrap-func" style="text-align:center">
                                -
                            </td>
                            <td width="100px">  <!-- seq_type -->
                                
                                <select id="sel_seq_type_wofile" ref="sel_seq_type_wofile" name="sel_seq_type_wofile" 
                                    v-model="this.report_wofile_data.seq_type" class="form-select-sm select-search" style="width:100px;float:center"
                                    @change="this.change_seq_type('WOFILE')"
                                    :disabled="!this.has_reported()"
                                    >
                                    <option value="WES" selected>WES</option>
                                    <option value="WGS" >WGS</option> 
                                </select>
                            </td>
                            <td>
                                
                                <!-- report_result -->
                                <select id="sel_report_result_wofile" ref="sel_report_result_wofile" name="sel_report_result_wofile"
                                    v-mode="this.report_wofile_data.report_result" class="form-select-sm select-search" style="width:100px;float:center" 
                                    @change="this.change_report_result('WOFILE')" 
                                    :disabled="!this.has_reported()"
                                    >
                                    <option value="">-</option>
                                    <option value="INC" >Inconclusive</option>
                                    <option value="POS">Positive</option>
                                    <option value="NEG">Negative</option>
                                </select>
                            </td>
                            <td>
                                <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_file_report"
                                    style="float:center; margin-right: 10px;"  @click="this.report_delivered( this.report_wofile_data, 'WOFILE')" 
                                    :disabled="!this.has_reported()"
                                    >
                                    Report
                                </button>
                            </td>
                        </tr>
                        <!-- 끝: FILE 없이 보고 -->
                        <!-- 시작: EMR 보고 --> 
                        <tr>
                            
                            <td width="100px" style="text-align:left">
                                EMR 보고
                            </td>
                                <!-- 파일 관리-->
                            <td width="400px" style="text-align:center">

                            </td>
                            <!-- 파일 명-->
                            <td width="400px" class="word-wrap-func" style="text-align:center">
                                -
                            </td>
                            <td width="100px" > <!-- seq_type -->

                                
                                <select id="sel_seq_type_emr" ref="sel_seq_type_emr" name="sel_seq_type_emr" 
                                    v-model="this.report_emr_data.seq_type" class="form-select-sm select-search" style="width:100px;float:center"
                                    @change="this.change_seq_type('EMR')"
                                    >
                                    <option value="WES" selected>WES</option>
                                    <option value="WGS" >WGS</option> 
                                </select>
                                
                            </td> 
                            <td width="100px"> 
                                
                                <select id="sel_report_result_emr" ref="sel_report_result_emr" name="sel_report_result_emr"
                                    v-mode="this.report_emr_data.report_result" class="form-select-sm select-search" style="width:100px;float:center" 
                                    @change="this.change_report_result('EMR')" >
                                    <option value="">-</option>
                                    <option value="INC" >Inconclusive</option>
                                    <option value="POS">Positive</option>
                                    <option value="NEG">Negative</option>
                                </select>
                            </td>
                            <td width="150px"> 
                                <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_emr_report"
                                    style="float:center; margin-right: 10px;" @click="this.report_delivered( this.report_emr_data, 'EMR')" >Report</button>
                            </td>
                            
                        </tr>
                        <!-- 끝: EMR 보고 --> 
                    </table>
                    </div>
                </div>
                <div class="row fixed-global-width " style="margin:0" >
                    <div class="col fixed-global-width " style="margin:0;height:10px" >

                    </div>
                </div>
            </div>
        </div>
        
        <div class="row fixed-global-width" style="margin:0;height:10px">
            <div class="col fixed-global-width" style="margin:0" align='left'>
                
            </div>
        </div>
        <!-- div class="row"  style="margin:0;width:100%">
            <div class="col " style="width:100%;margin:0">
                <b><font size="3">진단 결과 보고 결과</font></b> 
            </div>
        </div-->
        <div class="row fixed-global-width" style="margin:0;height:10px">
            <div class="col fixed-global-width" style="margin:0" align='left'>
                
            </div>
        </div>
        
        <div class="row fixed-global-width" style="margin:0">
            <div class="col fixed-global-width section-border-darkblue" style="margin:0" >
                
                
                <div class="row" >
                    <div class="col" style="text-align:left;height:10px">
                    
                    </div> 
                </div>
                <div class="row fixed-global-width " style="margin:0" >
                    <div class="col fixed-global-width " style="margin:0" >
                        <table>
                        <tr >
                            <th> 번호</th>

                            <th> 검사명</th>
                            <th> 보고형식</th>
                            <th> 검사보고일시</th>
                            <th> 검사 결과</th>
                            <th> 보고 내용 </th>

                            <th> 비고 </th>
                            <th> </th>

                            <!--th> 보고종류 </th>
                            <th> 보고내용</th>
                            <th> 보고날짜 </th>
                            <th> 보고결과</th-->
                            <!--
                            <th> 보고내용</th>
                            <th> 업로드일자</th>
                            -->
                        </tr>
                        <!-- 시작: 보고 현황  : this.report_data (report_contents)-->
                        <!--tr>
                            <td colspan="10" height="40px">
                                report_data [0] DIA
                            </td>
                        </tr-->
                        <!-- DIA -->
                        <tr v-for="(report,id) in this.report_data" :key="id" style="padding-top:10px;">

                            <!--번호-->
                            <td width="50px"> {{ id +1 }} </td>
                            
                            <!-- 검사명 -->
                            <td width="50px">{{report.seq_type}}</td> 
                            <!-- 보고 형식 -->
                            <td width="100px">{{this.convert_report_type(report.report_type)}}</td> 
                            <!-- 검사보고 일시 -->
                            <td width="200px">{{report.report_date}}</td>
                            <!-- 검사 결과-->
                            <td width="100px"> {{this.convert_report_result(report.report_result)}}</td>

                            <!-- 보고 내용 -->
                            <td width="300px" class="word-wrap-func" v-if="report.report_type=='FILE'" align="left" >
                                <div v-if="report.latestStatus!=undefined" @click.stop="" @click="this.file_download(report.file_path, report.file_name)" >
                                    FILE: <font color="#0000ff">{{report.file_name}}</font> <font color="#ff0000">{{report.latestStatus}}</font>
                                </div>
                                <div v-else @click.stop="" @click="this.file_download(report.file_path, report.file_name)" >
                                    FILE: <font color="#0000ff">{{report.file_name}}</font>
                                </div>
                            </td>
                            <td width="300px" v-else-if="report.report_type=='EMR'" align="left" >
                                <div v-if="report.latestStatus!=undefined">
                                    EMR <font color="#ff0000">{{report.latestStatus}}</font>
                                </div>
                                <div v-else>
                                    EMR
                                </div>
                            </td>
                            <td width="300px" v-else-if="report.report_type=='URL'" align="left" >
                                <div v-if="report.latestStatus!=undefined">
                                    <a :href="report.report_value" target="_blnak">보고서 링크</a> <font color="#ff0000">{{report.latestStatus}}</font>
                                </div>
                                <div v-else>
                                    <a :href="report.report_value" target="_blnak">보고서 링크</a>
                                </div>
                                
                            </td>
                            <td width="300px" v-else-if="report.report_type=='WOFILE'" align="left" >
                                <div v-if="report.latestStatus!=undefined">
                                    -
                                </div>
                                <div v-else>
                                    -
                                </div>
                                
                            </td>
                            <td width="300px" v-else>NA</td>




                            <!-- 시작: 추가 comment-->
                            <td width="400px" align="left" >
                                <input v-model="report.comment" name="text_report_commented" id="text_report_commented"
                                    class="form-control-sm input-search"
                                    style="width:380px"
                                    />
                                                                
                            </td>
                            <td width="60px" style="justify-content:center"> 
                                <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_report_commented"
                                    style="float:center; margin-right: 10px; width:50px !important" @click="this.report_commented(id)" >수정</button>
                            </td>
                            <!-- 끝: 추가 comment-->

                        </tr>
                        
                        <!-- 끝: 보고 현황  : this.report_data (report_contents)-->
                        </table>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="row" style="margin:0;height:10px">
            <div class="col" style="margin:0">
            </div>
        </div>
        
        <div class="row" style="margin:0;height:20px">
            <div class="col" style="margin:0;justify-content: left;" >
                <hr size="1" width="90%" />
            </div>
        </div>
        <div class="row" style="margin:0;height:20px">
            <div class="col" style="margin:0" align='left'>
                
            </div>
        </div>
        
        
    </div>
</div>
</template>
<script>
import {ref} from 'vue'
//import {reactive} from 'vue';
import { useStore } from 'vuex'

import api from '@/services/base/index.js'

//import { FALSE } from 'node-sass'
// import { reactive} from 'vue'

export default {
    name: 'ReportManager',
    props:['id','files','report_contents',

        'patient_name',
        'patient_age',
        'patient_sex'
    ],

    data() {
        // 시작: upload 된 파일 > this.files
        // report_files for props files

        // 시작: WITH FILE 보고를 위한 자료 구조

        let report_wfile_data={
            'patientfiles_id':'',
            'filename':'',
            'file_path':'',
            'report_date':'',
            'report_kind':'',
            'report_type':'FILE',
            'report_value':'',
            'report_result':'',
            'seq_type':'WES',
            'revoke':'N',
            // comment
            'comment':''
        }
        // 업로드한 파일이 있을 경우 report_wfile_data 설정
        if (this.files.length > 0){
            // const last_uploaded_file_idx = this.files.length-1;
            const last_uploaded_file_idx = 0; // Sorting 되어서 옴
            const last_upaloded_fileinfo= this.files[last_uploaded_file_idx];

            // FILE 보고 에 값 설정
            report_wfile_data['patientfiles_id'] = last_upaloded_fileinfo['id'];
            report_wfile_data['filename'] = last_upaloded_fileinfo['filename'];
            report_wfile_data['file_path'] = last_upaloded_fileinfo['file_path'];

        }
        
        // 끝: WITH FILE 보고를 위한 자료 구조


        // 시작: report_data for props report_contents
        // From API server
        //  report_content=[] # report_content[REPORT_KIND][{}] # sorted report_date already
        //  report_content.append([]) #  0: DIA
        //  report_content.append([]) #  1: REA
        
        let report_data = [];

        // process this.report_contents
        // report_data[0] => DIA
        // report_data[1] => REA 
        // 분리하는 코드
        // for ( let i =0;i<this.report_contents.length;i++){
        //     let kind_report_info = this.report_contents[i]
        //     //report_data.push(kind_report_info)
        //     report_data.push([])
        //     for (let j=0;j<kind_report_info.length;j++){
        //         let kind_data = kind_report_info[j]
        //         report_data[i].push(kind_data)
        //         report_data[i][j].patient_id = this.id
        //         // report_file_data[i].updated_at = file_data.upload_date
        //         report_data[i][j].patientfiles_id = kind_data.id
        //         report_data[i][j].report_date =''
        //         report_data[i][j].report_kind= kind_data.category

                
                
        //         report_data[i][j].report_type = "FILE"
        //         report_data[i][j].report_value = ""
        //         report_data[i][j].report_result = ""
        //         report_data[i][j].revoke = ""
        //     }
            
        // }

        // 보고 일자 별로 정렬하면서 report_data[] 을 만듬
        let tmp_report_data=[];

        
        
        
        // report_conetns (2차 array) 파싱해서 tmp_report_data  (1차 array)로 변경하기
        for (let i =0; i<this.report_contents.length;i++){
            let kind_report_info = this.report_contents[i];


            // 시작: 최신 보고 날짜에 "(최종)" 달기 변수 선언 시작
            //------------------------------------------------
            let latestDate = undefined;
            let latestKindReportIdx = undefined;
            //------------------------------------------------
            // 끝: 최신 보고 날짜에 "(최종)" 달기 변수 선언 끝

            for (let j=0;j<kind_report_info.length;j++){
                let kind_data = kind_report_info[j];


                // report_data 끝에 일단 넣음
                tmp_report_data.push(kind_data);
                const idx = tmp_report_data.length - 1;

                // 시작: 최신보고 날짜 "(최종)" 달기 위해 날짜 비교
                //------------------------------------------------
                if (j==0){
                    latestDate = kind_data.report_date;
                    latestKindReportIdx = idx;
                }
                else{
                    const kindDateObj = new Date(kind_data.report_date);
                    const latestDateObj = new Date(latestDate);
                    let differenceObj = latestDateObj.getTime()- kindDateObj.getTime();
                    if (differenceObj <=0 ){ // true kind_data.report_date 이 최신
                        latestDate = kind_data.report_date;
                        latestKindReportIdx = idx;
                    }
                }
                //------------------------------------------------
                // 끝: 최신보고 날짜 "(최종)" 달기 위해 날짜 비교

                tmp_report_data[idx].patient_id = kind_report_info.patient_id;
                // report_file_data[i].updated_at = file_data.upload_date

             


                // 시작 : set patientfiles_id variables and file info
                //-----------------------------------
                // patient_patientreport.report_type = [FILE,EMR,URL]
                if (kind_data.report_type =="FILE"){
                    tmp_report_data[idx].patientfiles_id = kind_data.patientfiles_id;
                    tmp_report_data[idx].file_path = kind_data.patientfiles_file_path;
                    tmp_report_data[idx].file_name = kind_data.patientfiles_filename;
                }
                else if (kind_data.report_type =="EMR"){
                    tmp_report_data[idx].patientfiles_id = undefined;
                }
                else if (kind_data.report_type =="URL"){
                    tmp_report_data[idx].patientfiles_id = undefined;
                }
                // 끝 : set patientfiles_id variables and file info
                //-----------------------------------
                
                // ISO 'T' 제거
                tmp_report_data[idx].report_date = kind_data.report_date.replace('T',' ');

                // patient_patientreport.report_kind = [DIA,REA]
                tmp_report_data[idx].report_kind= kind_data.report_kind;

                // patient_patientreport.report_type = [FILE,EMR,URL]
                tmp_report_data[idx].report_type = kind_data.report_type;



                // added by DSRYU 20241103 seq_type
                if (kind_data.seq_type == "WES__"){
                    kind_data.seq_type = "WES"
                }
                tmp_report_data[idx].seq_type = kind_data.seq_type;


                // 시작 : set report_value variables
                //-----------------------------------
                if (kind_data.report_type=="URL"){
                    //tmp_report_data[idx].report_value =  "<a href='" + kind_data.report_value + "'>URL</a>'";
                    tmp_report_data[idx].report_value = kind_data.report_value 
                    //alert("report_value:"+ kind_data.report_value )
                }
                else if (kind_data.report_type=="FILE"){
                    tmp_report_data[idx].report_value = kind_data.patientfiles_filename;
                }
                // 끝 : set report_value variables
                //-----------------------------------

                // 시작 : set report_result variables
                //-----------------------------------
                tmp_report_data[idx].report_result = kind_data.report_result;
                
                // 끝 : set report_result variables
                //-----------------------------------

                // 시작 : set upload_date variables at the case of report_type is FILE
                //-----------------------------------
                if (kind_data.report_type == "FILE"){
                    tmp_report_data[idx].upload_date = kind_data.patientfiles_upload_date;
                }
                //-----------------------------------
                // 끝 : set upload_date variables at the case of report_type is FILE

                // 시작: set revoke varialbe
                tmp_report_data[idx].revoke = kind_data.revoke;
                // 끝: set revoke varialbe
                
                
            }
            // 시작: (최종) 달기
            //------------------------------------------------
            if (latestKindReportIdx != undefined){
                tmp_report_data[latestKindReportIdx].latestStatus ="(최종)";
            }
            // 끝: (최종) 달기
            //------------------------------------------------
            
        }

        

        // Sort report_data
        report_data = tmp_report_data.sort(
            (a,b) => Date.parse(a.report_date)- Date.parse(b.report_date)
        );

        // 끝: report_data for props report_contents

        


        // 시작: WITHOUT FILE 보고를 위한 자료 구조
        let report_wofile_data={
            'report_date':'',
            'report_kind':'',
            'report_type':'WOFILE',
            'report_value':'',
            'report_result':'',
            'seq_type':'WES',
            'revoke':'N',
            'comment':''
        }
        // 시작: WITHOUT FILE 보고를 위한 자료 구조

        // 시작: EMR 보고를 위한 자료 구조
        let report_emr_data={
            'report_date':'',
            'report_kind':'',
            'report_type':'EMR',
            'report_value':'',
            'report_result':'',
            'seq_type':'WES',
            'revoke':'N',
            'comment':'',
        }
        // 끝: EMR 보고를 위한 자료 구조

        // 입력 파일 변경 유무
        let changed_upload_file=true;


        // 시작: decide already_reported
        let already_reported = false
        for (let i=0;i<report_data.length;i++){
            if (report_data[i].length != 0){
                already_reported = true
                break
            }
        }
        // 끝: decide already_reported
        
        //console.log("report_data:"+JSON.stringify(report_data))

        let loading = ref(false)

 
        return {upload_files:'',changed_upload_file, already_reported, report_data, report_emr_data, report_wfile_data, report_wofile_data,loading}
    },
    mounted(){
        

    },
    setup(){
        const store = useStore();
        // var temp_basis_info = JSON.parse(localStorage.getItem('pb_info'));
        // alert ('loaded:'+JSON.stringify(temp_basis_info));
        // this.pb_obj = temp_basis_info;

        return {store}
    },
    methods:{
        has_reported(){
            if (this.report_data.length > 0){
                // for (let i=0;i<this.report_data.length;i++){
                //     const rep_data = this.report_data[i]
                //     if (rep_data.report_type == "FILE" || rep_data.report_type=="__FILE__"){
                //         return true

                //     }

                // }
                return true
                //return false
            }
            else{
                return false
            }
        },
        convert_report_type(_type){
            let res_type = "-"
            if (_type=="FILE"){
                res_type = "FILE 보고"
            }
            else if (_type == "WOFILE"){
                res_type ="FILE 없이 보고"
            }
            else if (_type=="EMR"){
                res_type = 'EMR'
            }
            return res_type;
        },
        // convert _res_code to full string
        convert_report_result(_res_code){
            let res_result = "-";
            if (_res_code == "POS"){
                res_result = "Positive";
            }
            else if (_res_code == "NEG" ) {
                res_result="Negative";
            }
            else if (_res_code == "INC"){
                res_result = "Inconclusive";
            }
            return res_result;

        },
        change_seq_type(_report_kind){
            const ref_seq_type = this.get_ref_seq_type(_report_kind)
            let ref_seq_type_obj = this.$refs[ref_seq_type];
            if (_report_kind == 'WFILE'){
                this.report_wfile_data.seq_type = ref_seq_type_obj.value;
            }
            else if (_report_kind == 'WOFILE'){
                this.report_wofile_data.seq_type = ref_seq_type_obj.value;
            }
            else if (_report_kind == 'EMR'){
                this.report_emr_data.seq_type = ref_seq_type_obj.value;
            }
            
        },
        change_report_result(_report_kind){
            const ref_report_result = this.get_ref_report_result(_report_kind)
            let ref_report_result_obj = this.$refs[ref_report_result];
            if (_report_kind == 'WFILE'){
                this.report_wfile_data.report_result = ref_report_result_obj.value;
            }
            else if (_report_kind == 'WOFILE'){
                this.report_wofile_data.report_result = ref_report_result_obj.value;
            }
            else if (_report_kind == 'EMR'){
                this.report_emr_data.report_result = ref_report_result_obj.value;
            }
            
        },
        // change_emr_report_kind(){
        //     let ref_report_kind = 'sel_emr_report_kind'
        //     if (this.$refs[ref_report_kind].value == "" || this.$refs[ref_report_kind].value == "DIA"){
        //         let ref_report_result = 'sel_emr_report_result';
        //         this.$refs[ref_report_result].value = "";
        //         this.report_emr_data.report_result ="";
        //     }
        // },
        // change_report_kind(_idx){
        //     let ref_report_kind = 'sel_file_report_kind'+_idx;
        //     if (this.$refs[ref_report_kind][0].value == "" || this.$refs[ref_report_kind][0].value == "DIA"){
        //         let ref_report_result = 'sel_file_report_result'+_idx;
        //         this.$refs[ref_report_result][0].value =""

        //         // delete by dsryu 20241103
        //         // this.report_files[_idx]['report_result'] = ""

        //     }
        // },
        is_already_reported(){
            // if (this.already_reported){
            //     alert ('is_already_reported true')
            // }
            // else{
            //     alert ('is_already_reported false')
            // }
            return this.already_reported
        },
        // /Check EMR reported or not 
        change_file(){
            
            if (this.$refs['ref_store_files'].value != ''){
                this.changed_upload_file = false
            }
            else{
                this.changed_upload_file = true
            }

        },

        // check already uploaded files
        is_already_uploaded_files(){
            if (this.files.length > 0){
                return true;
            }
            return false;

        },
        // patient_patientfiles table에만 관여
        // patient_patientfiles table의 category 컬럼의 'DIA'와 patient_patientreport table의 report_kind 컬럼 'DIA'는 별개
        // patient_patientfiles의 redeliver_date 컬럼은 데이터 입력 시작 시점의 불확실성으로 사용 안함
        upload_file(){
           

            const upload_files = this.$refs.ref_store_files.files

            // if (this.$refs['ref_sel_uploadfile_kind'].value == undefined || this.$refs['ref_sel_uploadfile_kind'].value=="" ){
            //     alert ('검진 파일 업로드를 위해, 검진 파일 보고 종류를 선택해주세요');
            //     this.$refs['ref_sel_uploadfile_kind'].focus();
            //     return false;
            // }

            if (upload_files.length < 1){
                return false;
            }
            // reqfile information

            for (let i =0;i<upload_files.length;i++){
                upload_files[i].src = this.get_file_src(upload_files[i])
                upload_files[i].prefix = this.$fileconstant.pb_prefix
                upload_files[i].req_id = this.id // patient id
                upload_files[i].filetype='DIA'
                upload_files[i].db_id = '-'
            }
            

           
            // alert ("target_file.src:" + target_files.src +", target_file.prefix:"+target_files.prefix + ",target_file.req_id:"+target_files.req_id+", target_file.filetype:"+target_files.filetype)

            api.uploadFileObj(upload_files)           
            .then(response =>{
                // console.log('mounted:function after change_pb_uploadfiles (response)'+JSON.stringify(response))
                if (response['result'] == 'success'){
                    // alert ('기본 환자 정보가 수정되었습니다')
                    // DB 에 수정된 파일 정보 갱신, DB ID 설정 필요
                    
                    let fields = {
                        p_id:this.id, // patient id
                        file_id:[] // file id
                    };
                    for (let i =0;i<response['data']['message'].length;i++){
                        const updated_file_info = response['data']['message'][i]
                        fields['file_id'].push({name:updated_file_info.filename,id:updated_file_info.id, file_path:updated_file_info.file_path})
                    }

                    //this.set_CRUDENV_loading(false);
                    //this.$router.go(0);

                    this.$emit('report_uploaded',fields)
        
                    return true
                }

                alert ('입력된 파일 정보 수정에 문제가 있습니다.')
                //this.set_CRUDENV_loading(false);
                return false


            }).catch(err =>{
                console.log(err)

                //this.set_CRUDENV_loading(false);
                return false
            })
            // this.$router.go(0);
        },
        // FileReader를 통해 파일을 읽어 thumbnail 영역의 src 값으로 셋팅
        async get_file_src (files) {
            return new Promise((resolve) => {
                const reader = new FileReader()
                reader.onload = async (e) => {
                    resolve(e.target.result)
                }
                reader.readAsDataURL(files)
            })
        },
        file_removed(_file_id){

            this.set_loading(true);
            const f_id =_file_id

            

            // 시작: 검사완료 로 변경 (from backend) 
            //  async deliverFileObj(user_email, id, reqstatus, _fidx){
            //--------------------------------------------------------
            api.deleteFileObj("",f_id)
            .then(response =>{
                const response_data = Object.freeze(response)
                //alert ('deleteFileObj :'+JSON.stringify(response_data))

                if (response_data['result'] =="success"){
                    //alert ('success')
                    // checked interface process
                    //this.setLocalStorageVariables();
                    //this.forceRender()
                    this.$router.go();

                    this.set_loading(false);
                }
                else{
                     alert ('some error in api.removeReport()');
                    
                }
                //this.set_loading(false);
                return response_data

            }).catch(err =>{
                console.log(err)
            })
            this.$emit('file_removed')


        },
        get_ref_seq_type(_report_kind){
            let ref_seq_type= 'sel_seq_type';
            if (_report_kind == 'WFILE'){
                ref_seq_type = ref_seq_type + "_wfile";
            }
            else if (_report_kind == 'WOFILE'){
                ref_seq_type = ref_seq_type + "_wofile";
            }
            else if (_report_kind=='EMR'){
                ref_seq_type = ref_seq_type + "_emr";
            }
            return ref_seq_type;
        },
        get_ref_report_result(_report_kind){
            let ref_report_result= 'sel_report_result';
            if (_report_kind == 'WFILE'){
                ref_report_result = ref_report_result + "_wfile";
            }
            else if (_report_kind == 'WOFILE'){
                ref_report_result = ref_report_result + "_wofile";
            }
            else if (_report_kind=='EMR'){
                ref_report_result = ref_report_result + "_emr";
            }
            return ref_report_result;
        },

        async report_commented(order){

            const report_content = this.report_data[order]

            let update_comment = report_content.comment

            if (update_comment == undefined){
                update_comment = ''
            }
            let fields={
                'report_id': report_content.id,
                'comment': update_comment
            }

            console.log("report_comment:"+ JSON.stringify(fields))

            
            this.$emit('report_commented',fields)

            return true;
        },
        // _idx for $refs focuss()
        // report_delivered
        // @param : _report_content: 보고할 report_content
        // @param : _report_kind: 보고할 report 형식 ['WFILE','WOFILE','EMR']
        async report_delivered(_report_content,_report_kind){ //_file_id){ // _file_id : db file id
            
            
            // 시작: check input data validation
            const report_content = _report_content

            // 시작: validate seq_type
            const ref_seq_type= this.get_ref_seq_type(_report_kind)
            let ref_seq_type_obj = this.$refs[ref_seq_type];
            if (ref_seq_type_obj.value == undefined || ref_seq_type_obj.value ==""){
                alert ("리포트 보고시, 검사명 지정이 필요합니다.")
                ref_seq_type_obj.focus();
                return false;
            }
            // 끝: validate seq_type

            // 시작: validate report_result
            const ref_report_result = this.get_ref_report_result(_report_kind);
            let ref_report_result_obj = this.$refs[ref_report_result];
            if (ref_report_result_obj.value == undefined || ref_report_result_obj.value ==""){
                alert ("리포트 보고시, 검사명 지정이 필요합니다.")
                ref_report_result_obj.focus()
                return false;
            }
            // 끝: validate seq_type

            // report_kind 설정
            const already_reported = this.is_already_reported()
            let report_kind_value = 'FIRST'
            if (already_reported){
                report_kind_value = 'OTHER'
            }


            // 시작: 보고 결과 전송을 위한 자료 구조 구축
            // _kind에 상관 없이 patient_id, report_kind,report_type, report_result, revoke 는 데이터 받아서 바로 지정 가능, report_date 은 api 전송시 front에서 자동 계산됨
            let fields = {
                patient_id:this.id, // fixed
                patientfiles_id:"", 
                report_date:"",
                report_kind: report_kind_value, // 'FIRST' or 'OTHER'
                report_type: _report_content.report_type,  // fixed
                report_value: "",
                report_result: _report_content.report_result, // fixed
                seq_type : _report_content.seq_type, // seq_type
                revoke: "N"
            }
            
            

            
            // // FILE 보고를 위한 자료 구조 구성
            // if (report_content.report_type == "FILE"){
            //     // fields['patient_id']=''
            //     // fields['report_date'] = ''
            //     // fields['report_kind'] = ''
            //     // fields['report_type'] = ''
            //     // fields['report_result'] = ''
            //     // fields['revoke'] =''
            //     fields['patientfiles_id'] = report_content['id']
            //     fields['report_value'] = '' // 기 URL 보고 때문 공백 지정                   
            // }
            // else if (report_content.report_type == "EMR"){ // EMR 데이터 추가시 아래 내용 참고
            //     // fields['patient_id']=''
            //     // fields['patientfiles_id'] = ''
            //     // fields['report_date'] = ''
            //     // fields['report_kind'] = ''
            //     // fields['report_type'] = ''
            //     // fields['report_value'] = ''
            //     // fields['report_result'] = ''
            //     // fields['revoke'] =''
            // }
            // FILE 보고를 위한 자료 구조 구성
            if (_report_kind == 'WFILE'){
                fields['patientfiles_id'] = report_content['patientfiles_id']
                fields['report_value'] = '' // 기 URL 보고 때문 공백 지정    
            }
            else if (_report_kind=='WOFILE'){
                fields['report_value'] = '' // 기 URL 보고 때문 공백 지정 
            }
            // else if (_report_kind=='EMR'){
               
            // }

            var confirm_data = "";

            // 시작: construct patient_info

            confirm_data = confirm_data + "환자 정보\n";
            confirm_data = confirm_data + " - 성함: " + this.patient_name +"\n";
            confirm_data = confirm_data + " - 성별: " + this.patient_sex + "\n";
            confirm_data = confirm_data + " - 나이: " + this.patient_age + "\n";
            // 끝: construct patient_info

            //confirm_data = confirm_data +"\n";

            // 시작: construct 보고 정보
            confirm_data = confirm_data +"보고 정보 \n";
            confirm_data = confirm_data +" - 보고 형식: "
            if (fields.report_type == "FILE"){

                confirm_data = confirm_data +"File ("+report_content['filename'] +")";
            }
            if (fields.report_type == "WOFILE"){
                confirm_data = confirm_data +"파일 없이 보고"
            }
            if (fields.report_type == "EMR"){
                confirm_data = confirm_data +"EMR" ;
            }
            confirm_data= confirm_data +"\n"
            

            
            confirm_data = confirm_data +" - 검사명: " + fields.seq_type +"\n"

            confirm_data = confirm_data + " - 검사 결과: "
            if (fields.report_result == "INC"){
                confirm_data = confirm_data + " Inconclusive";
            }
            if (fields.report_result == "NEG"){
                confirm_data = confirm_data + " Negative";
            }
            if (fields.report_result == "POS"){
                confirm_data = confirm_data + " Positive";
            }
            confirm_data = confirm_data +"\n";
                    
            
            // console.log("report fields:"+JSON.stringify(fields))

            // 끝: construct 보고 정보
           
            var answer = confirm (confirm_data)
            if (!answer){
                return;
            }
            

            this.$emit('report_delivered',fields)

            return true;
            


            // api.deliverReport1 (기존 api.deliverReport()) 분석 해보고 api에서 해당 기능 처리 필요!! deliverReport 는 ReportManager.vue 외에는 다른 곳에서 사용 안되는 것 확인함


            // // patient_patient table의 status 변경

            // const p_id = fields['patient_id']
            // const report_type = 'DIA'
            // const f_id = fields['patientfiles_id']

            // // const userEmail = this.store.getters['userStore/getUserEmail']

            // //this.set_loading(true)
     
            
            // // 시작: 검사완료 로 변경 (from backend) 
            // //  async deliverFileObj(user_email, id, reqstatus, _fidx){
            // //--------------------------------------------------------
            // api.deliverReport1(userEmail, p_id, "검사완료" , f_id, report_type)
            // .then(response =>{
            //     const response_data = Object.freeze(response)
            //     //alert ('deliverReport :'+JSON.stringify(response_data))
            //     if (response_data['result'] =="success"){
            //         //alert ('success')
            //         // checked interface process
            //         this.setLocalStorageVariables();
            //         this.forceRender()
            //     }
            //     else{
            //          alert ('some error in api.deliverReport1()');
                    
            //     }
            //     //this.set_loading(false);
            //     return response_data
            //     // this.patient_number_list = [];
            //     // for (let i = 0; i < response.data.length; i++) {
            //     //     this.patient_number_list.push(response.data[i].pb_obj.text_pb_number)
            //     // }
            //     // this.current_page = response['page_info']['current_page'];
            //     // this.total_item_cnt = response['page_info']['total_item_cnt'];

            //     // this.set_loading(false);

            // }).catch(err =>{
            //     console.log(err)
            // })




            // this.$emit('report_delivered')
        },
        report_rollback( _file_id){ // _file_id : db file id

            let fields = {
                p_id:this.id, // patient id
                file_id:_file_id // file id
            };
            this.$emit('report_rollback',fields)
        },
        async file_download (file_path, file_name) {
            api.downloadFileObj(file_path).then(response=>{
                let blob = new Blob([response.data], { type: response.headers['content-type'] })
                file_name = decodeURI(file_name) // 파일명 디코딩 (프로젝트에 따라 사용여부 옵션)
                if (window.navigator.msSaveOrOpenBlob) { // IE 10+
                    window.navigator.msSaveOrOpenBlob(blob, file_name)
                } else { // not IE
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.target = '_self'
                    if (file_name) link.download = file_name
                    link.click()
                }
            })
        },
        is_loading(){
            return this.loading
        },
        set_loading(_loading){
            this.loading = _loading
        },
        
   
    }

}
</script>
<style scoped>
.section-border-gray{
    background-color: #ffffff !important;
    border: 1px solid gray !important;

}
.section-border-darkblue{
    background-color: #ffffff !important;
    border: 1px solid darkblue !important;
}
.word-wrap-func{
    word-break: break-all !important; /* 단어가 길 경우 줄바꿈 */
}
.fixed-global-width{
    width:1300px !important;
}

</style>