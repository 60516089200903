<template>
  <div class="table" :style="{ width: width, height: height }" style="display:flex !important; justify-content:left;">
    <table v-if="this.tableData && this.tableData.AREA && this.tableData.DATA">
      <thead>
        <tr>
          <th>지역</th>
          <template v-if="!isColumnZero('NUMBER')">
            <th>진단수</th>
          </template>
          <template v-if="!isColumnZero('RATIO')">
            <th>비율 </th>
          </template>
          <th>지역</th>
          <template v-if="!isColumnZero('NUMBER')">
            <th>진단수</th>
          </template>
          <template v-if="!isColumnZero('RATIO')">
            <th>비율</th>
          </template>
        </tr>
      </thead>
      <tbody>

        <tr v-for="(area, index) in tableData.AREA.slice(0, Math.ceil(tableData.AREA.length / 2))" :key="area">
          <!-- 첫 번째 세트 -->
          <td style="text-align:center !important;">{{ area }}</td>
          <template v-if="!isColumnZero('NUMBER')">
            <td style="text-align:right !important;">{{ tableData.DATA[area]?.NUMBER || 'N/A' }}</td>
          </template>
          <template v-if="!isColumnZero('NUMBER')">
            <td style="text-align:right !important;">{{ tableData.DATA[area]?.RATIO || 'N/A' }} %</td>
          </template>

          <!-- 두 번째 세트 -->
          <template v-if="tableData.AREA[index + Math.ceil(tableData.AREA.length / 2)]">
            <td style="text-align:center !important;">{{ tableData.AREA[index + Math.ceil(tableData.AREA.length / 2)] }}</td>
            <template v-if="!isColumnZero('NUMBER')">
              <td style="text-align:right !important;">{{ tableData.DATA[tableData.AREA[index + Math.ceil(tableData.AREA.length / 2)]]?.NUMBER || 'N/A' }}</td>
            </template>
            <template v-if="!isColumnZero('NUMBER')">
              <td style="text-align:right !important;">{{ tableData.DATA[tableData.AREA[index + Math.ceil(tableData.AREA.length / 2)]]?.RATIO || 'N/A' }} %</td>
            </template>
          </template>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'AreaScreenDonutTable',
  props: {
    tableData: {
      type: Object,
      default: () => ({
        AREA: ['A', 'B', 'C', 'D'],
        DATA: {
          A: { NUMBER: 1, RATIO: "2.0"},
          B: { NUMBER: 1, RATIO: "2.0"},
          C: { NUMBER: 1, RATIO: "2.0"},
          D: { NUMBER: 1, RATIO: "2.0"}
        }
      })
    },
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    }
  },
  methods: {
    // calculateProportion(hospital, type) {
    //   const data = this.tableData.DATA[hospital];
    //   const total = data.POS + data.NEG + data.INC;
    //   const value = data[type];
      


    //   let proportion="0.00";
    //   if (total != 0){
    //     proportion = ((value / total) * 100).toFixed(2);
    //   }
      
    //   return proportion;
      
    // },
    isColumnZero(type) {
      return Object.values(this.tableData.DATA).every(data => data[type] === 0);
    }
  }
}
</script>

<style scoped>
.table {
  margin-top: 10px;
  width: v-bind(width);
  height: v-bind(height);
  justify-content: center !important;
}

.table th {
  background-color:#04AA !important;
  color:white !important;
  font-size:10px !important;

  padding-left:7px !important;
  padding-right:8px !important;
  padding-bottom:4px !important;
  padding-top:4px !important;
  
  text-align: center !important;
  border: 2px sold rgba(2, 14, 31, 0.667) !important;
}

.table td {
  text-align: center;
  font-size: 10px; /* Smaller font size */
  padding:1px !important;
  margin-left:1px !important;
  border: 1px solid #04AA !important;
}
.table tbody {
  margin-top: 10px; /* 10px margin at the top of tbody */
}
.table tr {
  border-bottom: none;
}
</style>
