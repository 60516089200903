<template>
  <div class="table" :style="{ width: width, height: height }" style="display:flex !important; justify-content:left;">
    <table v-if="this.tableData && this.tableData.HOSPITALS && this.tableData.DATA">
      <thead>
        <tr>
          
          <th>병원</th>
          <template v-if="!isColumnZero('POS')">
            <th>POS</th>
          </template>
          <template v-if="!isColumnZero('NEG')">
            <th>NEG</th>
          </template>
          <template v-if="!isColumnZero('INC')">
            <th>INC</th>
          </template>
          
        </tr>
      </thead>
      <tbody>
        <tr v-for="hospital in tableData.HOSPITALS" :key="hospital">
          <template v-if="!isColumnZero('POS') && !isColumnZero('NEG') && !isColumnZero('INC')">
            <td style="text-align:left !important;">{{ hospital }} ({{ tableData.DATA[hospital].POS + tableData.DATA[hospital].NEG +tableData.DATA[hospital].INC  }})</td>
          </template>
          <template v-if="!isColumnZero('POS')">
            <td style="text-align:right !important;">{{ tableData.DATA[hospital].POS }} ({{ calculateProportion(hospital, 'POS') }}%)</td>
          </template>
          <template v-if="!isColumnZero('NEG')">
            <td  style="text-align:right !important;">{{ tableData.DATA[hospital].NEG }} ({{ calculateProportion(hospital, 'NEG') }}%)</td>
          </template>
          <template v-if="!isColumnZero('INC')">
            <td  style="text-align:right !important;">{{ tableData.DATA[hospital].INC }} ({{ calculateProportion(hospital, 'INC') }}%)</td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'HospitalScreenTable',
  props: {
    tableData: {
      type: Object,
      default: () => ({
        HOSPITALS: ['A', 'B', 'C', 'D'],
        DATA: {
          A: { POS: 1, NEG: 2, INC: 2},
          B: { POS: 1, NEG: 2, INC: 2},
          C: { POS: 1, NEG: 2, INC: 2},
          D: { POS: 1, NEG: 2, INC: 2}
        }
      })
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: 'auto'
    }
  },
  methods: {
    calculateProportion(hospital, type) {
      const data = this.tableData.DATA[hospital];
      const total = data.POS + data.NEG + data.INC;
      const value = data[type];
      


      let proportion="0.00";
      if (total != 0){
        proportion = ((value / total) * 100).toFixed(2);
      }
      
      return proportion;
      
    },
    isColumnZero(type) {
      return Object.values(this.tableData.DATA).every(data => data[type] === 0);
    }
  }
}
</script>

<style scoped>
.table {
  margin-top: 12px;
  width: v-bind(width);
  height: v-bind(height);
}

.table th {
  background-color:#04AA !important;
  color:white !important;
  font-size:12px !important;

  padding-left:10px !important;
  padding-right:12px !important;
  padding-bottom:4px !important;
  padding-top:4px !important;
  
  text-align: center !important;
  border: 1px sold rgba(2, 14, 31, 0.667) !important;
}

.table td {
  text-align: center;
  font-size: 11px; /* Smaller font size */
  padding:2px !important;
  margin-left:2px !important;
  border: 1px solid #04AA !important;
}
.table tbody {
  margin-top: 10px; /* 10px margin at the top of tbody */
}
.table tr {
  border-bottom: none;
}
</style>
