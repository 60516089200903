<template>
<statview-manager-data ref="statviewManagerData" @stat_loaded="barChartStatDataLoaded"  />
<div class="d-flex flex-row" id='#layout-schematics-frame-row-height' >
  <div class="d-flex flex-column" id='#layout-schematics-frame-row-space' style="height:60px;width:100%">
  </div>
</div>
<div class="d-flex flex-row div-total-width" id='#layout-schematics-frame'  >
  <!-- 시작: 왼쪽 여백 -->
  <!--div class="d-flex flex-column" id="#layout-left-space" style="width:0px"> 
  </div-->
  <!-- 끝: 왼쪽 여백 -->
  <!--시작: 실제 contents-->
  <div class="d-flex flex-column div-total-width" id='#layout-schematics-pages' >
    <!--시작: 첫번째 줄 대시보드 통계 WES, Patients, Familiies, Completed, In Progress, Pre -->
    <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space" style="height:110px">
      <div class="d-flex flex-column" id="#layout-left-space" style="width:270px"> 
      </div>
      <div class="d-flex flex-column div-logo-layout" id='#layout-left-frame-leftcolumn' >
        <img src="../../assets/sig_1.png" class="img-fluid img-logo-image" alt="소아암희귀질환사업단로고">
      </div>
      <div class="d-flex flex-column" id="#layout-left-space" style="width:30px"> 
      </div>
      <div class="d-flex flex-column div-dash-item-layout" id='#layout-schematics-pages-contents-status-row-title' >
        <div v-if="this.statDash" >
          WES {{ this.priceToString(this.statDash["TOTAL"]) }} 명 <br/>
          Patients {{ this.priceToString(this.statDash["PROBAND"]) }} 명 <br/>
          Families {{ this.priceToString(this.statDash["FAMILY"]) }} 명 <br/>
        </div>
      </div>
      <div class="d-flex flex-column" id="#layout-left-space" style="width:50px"> 
      </div>
      <div class="d-flex flex-column" id="#layout-left-space" style="width:50px"> 
      </div>
      <div class="d-flex flex-column div-logo-layout" id='#layout-left-frame-leftcolumn' >
        <img src="../../assets/sig_2.png" class="img-fluid img-logo-image" alt="희귀질환사업부로고" >
      </div>
      <div class="d-flex flex-column" id="#layout-left-space" style="width:30px"> 
      </div>
      <div class="d-flex flex-column div-dash-item-layout" id='#layout-schematics-pages-contents-status-row-title' >
        <div v-if="this.statDash">
          Completed {{ this.priceToString(this.statDash["SCRCOMPLETE"]) }} 명 <br/>
          In Progress  {{ this.priceToString(this.statDash["SCRING"]) }} 명 <br/>
          Pre {{ this.priceToString(this.statDash["SCRPRE"]) }} 명 <br/>
        </div>
      </div>
      <div class="d-flex flex-column" id="#layout-left-space" style="width:270px"> 
      </div>
      
    </div>
    <!--끝: 첫번째 줄 대시보드 통계 WES, Patients, Familiies, Completed, In Progress, Pre -->

    <!-- 시작: DASH 보드 다음 공백 -->
    <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space">
      <div class="d-flex flex-column" id='#layout-schematics-pages-contents-status-row-title' style="width:100%;height:2px">
      </div>
    </div>
    <!-- 끝: DASH 보드 다음 공백 -->

    <!-- 시작: 통계 제목 -->
    <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space" style="width:100%">
      <div class="d-flex flex-column" id='#layout-schematics-pages-contents-status-row-title' style="width:100%">
        <div class="p-3 bg-gradient text-white div-title-layout" >
          유전체/기능연구 기반 희귀질환 진단지원 사업 참여 환자 전국 현황
        </div>
      </div>
    </div>
    <!-- 끝: 통계 제목  -->

    <!-- 시작: 제목과 통계 사이 여백 -->
    <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space" style="width:100%">
      <div class="d-flex flex-column" id='#layout-schematics-pages-contents-status-row-title' style="width:100%;height:10px">
      </div>
    </div>
    <!-- 끝: 제목과 통계 사이 여백  -->

    <!-- 시작: 진단율, 병원별 진단 현황 -->
    <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space" style="width:100%;height:600px;display:flex;justify-content:center">
      <!-- 시작: 진단율 차트 -->
      <div class="d-flex flex-column div-stat-column-layout" id='#layout-schematics-pages-contents-status-column-title' >
        <!-- 시작: 진단율 제목 -->
        <div class="p-3 bg-gradient div-stat-title-layout" >
          <label v-if="this.statScreen != undefined" class="label-stat-title">진단율  ({{ this.priceToString(this.statScreen['TOTAL'])}} 명)</label>
        </div>
        <!-- 끝: 진단율 제목 -->
        <!-- 시작: 진단율 piechart -->
        <div class="div-stat-chart-layout" id='#layout-schematics-pages-contents-status-column-title' >
          <screen-donut-chart  ref="screenDonutChart" :chartData="screen_data_collection" :options="screen_option_setting" :width="360" :height="360" v-if="this.statScreen != undefined" />
        </div>
        <!-- 끝: 진단율 piechart -->
      </div>
      <div style="width:30px">
      </div>
      <!-- 끝: 진단율 차트 -->

      <div class="d-flex flex-column div-stat-column-layout" id='#layout-schematics-pages-contents-status-column-title' >

        <!-- 시작: 병원별 진단 현황 통계 제목 -->
        <div class="d-flex flex-row">
          <div class="d-flex flex-column div-stat-region-layout">
            <div class="p-3 bg-gradient div-stat-region-title-layout" @click="this.toggleHosScreen()" >
              <label class="label-stat-title" v-if="this.isHosScreen">병원별 진단 현황 (표 보기)</label> 
              <label class="label-stat-title" v-else>병원별 진단 현황 (차트 보기)</label> 
              
            </div>
          </div>
        </div>
        <!-- 끝: 병원별 진단 현황 통계  제목-->
        <!-- 시작: 병원별 진단 현황 통계 차트 -->
        <div class="d-flex flex-row">
          <div class="d-flex flex-column" id="#layout-schematics-pages-contents-column1" style="width:800px">
            <div class="d-flex flex-row" id='#layout-schematics-pages-contents' style="width:100%;height:30px">
            </div> 

            <!-- 시작: 병원별 진단 현황 차트 -->  
            <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space" style="width:100%">
              <!-- 시작: 병원별 진단 현황 차트와 테이블 -->
              <div class="d-flex flex-column" id='#layout-schematics-pages-contents-status-row-left' style="width:100%" v-if="this.isHosScreen">
                <div class="div-stat-hosscr-chart-layout" id='#layout-schematics-pages-contents-status-column-title' style="width:34vw;height:34vh" >
                  <hospital-screen-chart :data="hosscrchart_data_collection" :options="hosscr_option_setting" :width=800 :height=500  />
                </div>
              </div>
              <div class="d-flex flex-column" id='#layout-schematics-pages-contents-status-row-left' style="width:100%" v-else>
                <div class="div-stat-hosscr-chart-layout div-stat-table-container" id='#layout-schematics-pages-contents-status-column-title' style="width:34vw;height:44vh" >
                  <hospital-screen-table :tableData="hosscrtable_data_collection" :width=800 :height=500  />
                </div>
              </div>
              <!-- 끝: 병원별 진단 현황 차트와 테이블-->

            </div>
            <!-- 끝: 병원별 진단 현황 차트-->
            
          </div>
        </div>
        <div style="width:30px">
        </div>
        <!-- 끝: 병원별 진단 현황 통계 차트 -->
      </div>
    </div>
    <!-- 끝: 진단율, 병원별 진단 현황 -->



    <!-- 시작: 환아 의뢰 형태, 병원별 의뢰 현황 -->
    <div class="d-flex flex-row" id="#layout-schematics-pages-contents-status-row-space" style="width:100%;height:600px;display:flex;justify-content:center">
      <!-- 시작: 환아 의뢰 형태 차트-->
      <div class="d-flex flex-column div-stat-column-layout" id='#layout-schematics-pages-contents-status-column-title' >
        <!-- 시작: 환아의뢰 형태 제목 -->
        <div class="p-3 bg-gradient div-stat-title-layout" >
          <label v-if="this.statReqType !=undefined" class="label-stat-title" >환아의뢰 형태 ({{ this.priceToString(this.statReqType['TOTAL'])}} 명)</label> 
        </div>
        <!-- 끝: 환아의뢰 형태 제목 -->
      

        <!-- 시작: 환아의뢰형태 doughnutchart -->
        <div class="div-stat-chart-layout" id='#layout-schematics-pages-contents-status-column-title'>
          <reqtype-donut-chart ref="reqtypeDonutChart" :chartData="reqtype_data_collection" :options="reqtype_option_setting" :width="360" :height="360" v-if="this.statReqType != undefined" />
        </div>
        <!-- 끝: 환아의뢰형태 doughnutchart -->
      </div>
      <!-- 끝 : 환아 의뢰 형태 차트 -->
      <div style="width:30px">
      </div>
      <!--시작: 병원별 의뢰 현황-->
      <div class="d-flex flex-column div-stat-column-layout" id='#layout-schematics-pages-contents-status-coloumn-title' >


        <!-- 시작: 병원별 의뢰 현황 제목 -->
        <div class="d-flex flex-row">
          <div class="d-flex flex-column div-stat-region-layout">
            
            <div class="p-3 bg-gradient div-stat-region-title-layout" @click="this.toggleHosType()" >
              <label class="label-stat-title" v-if="this.isHosType">병원별 의뢰 현황 (표 보기)</label> 
              <label class="label-stat-title" v-else>병원별 의뢰 현황 (차트 보기)</label> 
              
            </div>
          </div>
        </div>
        <!-- 끝: 병원별 의뢰 현황  제목-->

        <div class="d-flex flex-row" >
          <div class="d-flex flex-column" id="#layout-schematics-pages-contents-column1" style="width:800px">
            <div class="d-flex flex-row" id='#layout-schematics-pages-contents' style="width:100%;height:30px">
            </div> 

            <!-- 시작: 병원별 검사 현황 차트와 테이블 -->
            <div class="d-flex flex-column" id='#layout-schematics-pages-contents-status-row-left' style="width:100%" v-if="this.isHosType">
              <div class="div-stat-hosscr-chart-layout" id='#layout-schematics-pages-contents-status-column-title' style="width:34vw;height:34vh" >
                <hospital-type-chart :data="hostypechart_data_collection" :options="hosscr_option_setting" :width=800 :height=500  />
              </div>
            </div>
            <div class="d-flex flex-column" id='#layout-schematics-pages-contents-status-row-left' style="width:100%" v-else>
              <div class="div-stat-hosscr-chart-layout div-stat-table-container" id='#layout-schematics-pages-contents-status-column-title' style="width:34vw;height:44vh" >
                <hospital-type-table :tableData="hostypetable_data_collection" :width=800 :height=500  />
              </div>
            </div>
            <!-- 끝: 병원별 검사 현황 차트와 테이블 -->
          </div>
        </div>

      </div>
      <!-- 끝: 병원별 의뢰 현황-->
    </div>
    <!-- 끝: 환아 의뢰 형태, 병원별 의뢰 현황 -->


    
  </div>
</div>
<!--끝: 실제 contents-->

</template>


<script>

//import base_parser from '@/helper/base_parser.js'


// import donutchart from './DonutChart.vue'
import HospitalTypeTable from './HospitalTypeTable.vue'
import HospitalTypeChart from './HospitalTypeChart.vue'
import HospitalScreenChart from './HospitalScreenChart.vue'
import HospitalScreenTable from './HospitalScreenTable.vue'


import ScreenDonutChart from './ScreenDonutChart.vue'
import ReqTypeDonutChart from './ReqTypeDonutChart.vue'

import StatViewManagerData from './StatViewManagerData.vue'



export default {
  name:'StatViewManagerHospitals',
  components: {
//    'age-donut-chart': donutchart,
//    'reqtype-donut-chart': donutchart,
    'hospital-type-table': HospitalTypeTable,
    'hospital-type-chart': HospitalTypeChart,
    'hospital-screen-chart': HospitalScreenChart,
    'hospital-screen-table': HospitalScreenTable,

    'screen-donut-chart': ScreenDonutChart,
    'reqtype-donut-chart': ReqTypeDonutChart,


    'statview-manager-data': StatViewManagerData,
//    'hospital-req-status-bar-chart': HospitalReqStatusBarChart,
//    'hospital-screen-status-bar-chart':HospitalScreenStatusBarChart,
  },
  props: {
  },

  data() {
    return {
      province: undefined,            // 마우스가 지역구 위에 있을 때 정보
      currentProvince: undefined,     // 지역구를 클릭했을 때 정보
      allarea:'no',// check all area
      statData : undefined,      // 지역별 의뢰 현황 및 지도 통계
      statDash : undefined,      // 대쉬 보드 현황 통계 
      statScreen: undefined, // 진단율 통계 pie chart
      statReqType: undefined,    // 의뢰 현황 타입 통계 piechart
      statHospitalScreenChartStatus:undefined, // Hospital 별 검사결과(Screening ) Chart Data 분포
      statHospitalScreenTableStatus:undefined, // Hospital 별 검사결과(Screening ) Table Data 분포
      statHospitalTypeChartStatus:undefined, // Hospital 별 Sequencing Type Chart 분포
      statHospitalTypeTableStatus:undefined, // Hospital 별 Sequencing Type Table 분포

      colorFirstHalfHexArray:[],
      colorLastHalfHexArray:[],

      chartData:{},

      
      isHosScreen:true, // Hospital 별 Screening Chart rendering or Table rendering : true -> Chart rendering
      isHosType:true // Hospital 별 의뢰 유형 Chart rendering or Table rendering : true -> Chart rendering
  //---------------
    }
  },
  async created() {

  },
  async mounted() {
    this.summarize();
  },
  computed:{
    // hospital screen table data collection data 를 렌더링하기 위한 데이터 받고 설정하기
    hosscrtable_data_collection(){
      // console.log("hostypetable_data_collection")
      let statData = this.getStatHospitalScreenTableStatus()
      const data={
        HOSPITALS: ['A', 'B', 'C', 'D'],
        DATA: {
          A: { POS: 1, NEG: 2, INC: 2},
          B: { POS: 1, NEG: 2, INC: 2},
          C: { POS: 1, NEG: 2, INC: 2},
          D: { POS: 1, NEG: 2, INC: 2}
        }
      }
       

      if (statData == undefined){
        return data
      }
      
      
      const hospitals = statData['HOSPITALS']
      const stat_data = statData['DATA']
      let return_data={'HOSPITALS':hospitals,'DATA':stat_data}

      return return_data

    },


    // hostype_data_collection data 를 렌더링하기 위한 데이터 받고 설정하기
    hostypetable_data_collection(){
      // console.log("hostypetable_data_collection")
      let statData = this.getStatHospitalTypeTableStatus()
      const data={
        'HOSPITALS':['A','B','C','D'],
        'DATA':{
          'A':{'SINGLE':1,'DUO':2,'TRIO':4,'QUARTET':3},
          'B':{'SINGLE':3,'DUO':4,'TRIO':2,'QUARTET':5},
          'C':{'SINGLE':5,'DUO':6,'TRIO':3,'QUARTET':7},
          'D':{'SINGLE':8,'DUO':9,'TRIO':5,'QUARTET':10}
        }
      }
       

      if (statData == undefined){
        return data
      }
      
      
      const hospitals = statData['HOSPITALS']
      const stat_data = statData['DATA']
      let return_data={'HOSPITALS':hospitals,'DATA':stat_data}

      return return_data

    },
    // hospital_request type data 를 렌더링하기 위한 데이터 받고 설정하기
    hostypechart_data_collection(){

      let statData = this.getStatHospitalTypeChartStatus()

      // const labels =["January", "February","March","April","May"];
      const labels =[];

      //key_diag_result=["POS","NEG","INC"]
      const hospitalBackgroundColor=[this.colorLastHalfHexArray[5],this.colorLastHalfHexArray[2],this.colorFirstHalfHexArray[5],this.colorFirstHalfHexArray[3]]
      //POS =>0
      const data = {
        labels: labels,
        
        datasets: [
          {
            label: 'SINGLE',
            data: [],
            backgroundColor:hospitalBackgroundColor[0]
          },
          {
            label: 'DUO',
            data: [],
            backgroundColor:hospitalBackgroundColor[1]
          },
          {
            label: 'TRIO',
            data: [],
            backgroundColor:hospitalBackgroundColor[2]
          },
          {
            label: 'QUARTET',
            data: [],
            backgroundColor:hospitalBackgroundColor[3]
          },
        ]
      };
      // console.log("StatViewManager")


      if (statData == undefined){
        return data
      }

      const hospitalData = statData['REQTYPE']

      statData['labels'] = hospitalData['LABELS']
      statData['datasets']= hospitalData['DATASETS']


      //key_diag_result=["POS","NEG","INC"]
      //POS => 0
      //NEG=>1
      //INC=>2
      // Nomalize 
      for (let c=0;c<hospitalBackgroundColor.length;c++){

        statData['datasets'][c]['backgroundColor']=hospitalBackgroundColor[c]

        
      }


      return statData

      },
    // hospital_screening data 를 렌더링하기 위한 데이터 받고 설정하기
    hosscrchart_data_collection(){

      let statData = this.getStatHospitalScreenChartStatus()
      //console.log("statData:"+JSON.stringify(statData))

      // const labels =["January", "February","March","April","May"];
      const labels =[];

      //key_diag_result=["POS","NEG","INC"]
      const hospitalBackgroundColor=[this.colorLastHalfHexArray[5],this.colorLastHalfHexArray[2],this.colorFirstHalfHexArray[5]]
      //POS =>0
      const data = {
        labels: labels,
        
        datasets: [
          {
            label: 'POS',
            data: [],
            backgroundColor:hospitalBackgroundColor[0]
          },
          {
            label: 'NEG',
            data: [],
            backgroundColor:hospitalBackgroundColor[1]
          },
          {
            label: 'INC',
            data: [],
            backgroundColor:hospitalBackgroundColor[2]
          },
        ]
      };
      // console.log("StatViewManager")
      

      if (statData == undefined){
        return data
      }

      const hospitalData = statData['DIAG']

      statData['labels'] = hospitalData['LABELS']
      statData['datasets']= hospitalData['DATASETS']
      
      
      //key_diag_result=["POS","NEG","INC"]
      //POS => 0
      //NEG=>1
      //INC=>2
      // Nomalize 
      for (let c=0;c<hospitalBackgroundColor.length;c++){

        statData['datasets'][c]['backgroundColor']=hospitalBackgroundColor[c]

        
      }
      
      
      return statData

    },
    // hospital_screening_status를 렌더링하기 위한 그래프설정
    hosscr_option_setting(){

      const options= {
        indexAxis: 'y',
        scales: {
          x: {
            stacked: true,
            ticks: {
              callback: function(value) {
                return value + '%';
              }
            }
          },
          y: {
            stacked: true
          }
        },
        plugins: {
          datalabels: {
            display: true,
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map(data => {
                sum += data;
              });
              let percentage = (value * 100 / sum).toFixed(2) + "%";
              return percentage;
            }
          }
        },
        // plugins: {
        //   title: {
        //     display: true,
        //     text: '병원별 진단 현황'
        //   },
        // },
        // indexAxis: 'y',
        // responsive: true,
        //   scales: {
        //   x: {
        //     stacked: true,
        //     reverse:true,
        //   },
        //   y: {
        //     stacked: true
        //   }
        // }
      }
      return options;
    },

    // 진단율 현황 donut chart의 옵션 설정
    screen_option_setting(){
      var options={
        plugins: {
          legend: {
            display: false,
            position: "chartArea",
            align:"center", // legend vertical 위치
            labels: {
              boxWidth: 8,
              padding: 10,
              usePointStyle: true,
              pointStyle: "circle",
              font: {
                size: 7.5
              }
            },
            fullSize: true
          },
          tooltip: {
            boxWidth: 15,
            bodyFont: {
              size: 14
            }
          },
          datalabels:{  //options plugins datalabels
            display: false, // default는 true, false 로 하면 값을 숨길 수 있다.
//            align: "end",
//            anchor: "end",
//            borderWidth: 2,
//            padding: {
//              bottom: 0,
//              left: 20,
//            },
            labels:{
              title:{
                font:{
                  weight:'bold'
                }
              },
              value:{
                color:'blue'
              }
            }
            
          },
        },
        maintainAspectRatio: false,
      //  maintainAspectRatio: true,
      //  cutoutPercentage: 120, // 중앙 공간 설정 - 안먹힘
        responsive: true,       
        layout: {
          padding: {
            top: 0,
            bottom: 0
          }
        },
        elements: {
          arc: {
            borderWidth: 2
          }
        },
        animation: {
          duration: 1000
        },
        cutout: '60%' // 안쪽 원 크기 제한 숫자 클수록 넓어짐
      }

      return options;
    },
    // 환자 의뢰 현황 donut chart의 옵션 설정
    reqtype_option_setting(){
      var options={
        plugins: {
          legend: {
            display: false,
            position: "chartArea",
            align:"center", // legend vertical 위치
            labels: {
              boxWidth: 8,
              padding: 10,
              usePointStyle: true,
              pointStyle: "circle",
              font: {
                size: 8
              }
            },
            fullSize: true
          },
          tooltip: {
            boxWidth: 15,
            bodyFont: {
              size: 14
            }
          },
          datalabels:{  //options plugins datalabels
            display: false, // default는 true, false 로 하면 값을 숨길 수 있다.
//            align: "end",
//            anchor: "end",
//            borderWidth: 2,
//            padding: {
//              bottom: 0,
//              left: 20,
//            },
            labels:{
              title:{
                font:{
                  weight:'bold'
                }
              },
              value:{
                color:'blue'
              }
            }
            
          },
        },
        maintainAspectRatio: false,
      //  maintainAspectRatio: true,
      //  cutoutPercentage: 120, // 중앙 공간 설정 - 안먹힘
        responsive: true,       
        layout: {
          padding: {
            top: 0,
            bottom: 0
          }
        },
        elements: {
          arc: {
            borderWidth: 2
          }
        },
        animation: {
          duration: 1000
        },
        cutout: '60%' // 안쪽 원 크기 제한 숫자 클수록 넓어짐
      }

      return options;
    },
    // 진단율 현황을 렌더링하기 위해 데이터를 구체화함
    screen_data_collection(){
      const statData = this.getStatScreen()

      var datasets = {
        label:['진단율 현황'],
        //backgroundColor:["#92BCF5","#7CC6C5","#9A97EB", "#FFC000","#888888"],
        backgroundColor:[this.colorLastHalfHexArray[5],this.colorLastHalfHexArray[2],this.colorFirstHalfHexArray[5]],
        data:[],
        datalabels:{ // 레이블 위치 옵션
          align:'start',
          anchor:'start',
          clamp:false,
          offset:-40,
        }
      }
      var screen_data ={
        // labels: ['Single','Duo', 'Trio','Quartet', 'NA'],
        labels: ['Positive','Negative', 'Inconclusive'],
        //labels: ['양성','음성', '미결정','검사진행중'],
        datasets: [datasets]
      }
    

      if (statData == undefined){
        return screen_data
      }
      // calculate registered stat number
      //var stat_keys=["SINGLE", "DUO","TRIO","QUARTET", "NA"]
      var stat_keys=['POSITIVE','NEGATIVE', 'INCONCLUSIVE']
      
      // const total = this.statData['TOTAL']['TOTAL']
      // console.log("status_data_collection"+JSON.stringify(statData))
      screen_data['legendlabels']=[]
      screen_data['datasets'][0]['weight']=1
      for (var v=0;v <stat_keys.length;v++){
        screen_data['legendlabels'].push(screen_data['labels'][v] + " ["+this.priceToString(statData[stat_keys[v]]) +"명,"+((100 * statData[stat_keys[v]] / statData['TOTAL'])).toFixed(1)+" %]") // Legend 표기 Type[n명, ratio%]
        screen_data['labels'][v] = screen_data['labels'][v] +" "+((100 * statData[stat_keys[v]] / statData['TOTAL'])).toFixed(1) +"%"
        screen_data['datasets'][0]['data'].push(statData[stat_keys[v]] )
        
        
        
      }
      console.log("screen_data:"+JSON.stringify(screen_data))

      return screen_data
    },
    // 환자 의뢰 현황을 렌더링하기 위해 데이터를 구체화함
    reqtype_data_collection(){
      const statData = this.getStatReqType();
      //console.log("statData in age_data_collection() " + JSON.stringify(statData))
      // generate status_data_collection

     
      var datasets = {
        label:['환자의뢰형태'],
        //backgroundColor:["#92BCF5","#7CC6C5","#9A97EB", "#FFC000","#888888"],
        backgroundColor:[this.colorFirstHalfHexArray[3],this.colorFirstHalfHexArray[5],this.colorLastHalfHexArray[2],this.colorLastHalfHexArray[5]],
        data:[],
        datalabels:{ // 레이블 위치 옵션
          align:'start',
          anchor:'start',
          clamp:false,
          offset:-40,
        }
      }
      var reqstatus_data ={
        // labels: ['Single','Duo', 'Trio','Quartet', 'NA'],
        labels: ['Single','Duo', 'Trio','Quartet'],
        datasets: [datasets]
      }
    

      if (statData == undefined){
        return reqstatus_data
      }
      // calculate registered stat number
      //var stat_keys=["SINGLE", "DUO","TRIO","QUARTET", "NA"]
      var stat_keys=["SINGLE", "DUO","TRIO","QUARTET"]
      
      // const total = this.statData['TOTAL']['TOTAL']
      // console.log("status_data_collection"+JSON.stringify(statData))
      reqstatus_data['legendlabels']=[]
      reqstatus_data['datasets'][0]['weight']=1
      for (var v=0;v <stat_keys.length;v++){
        reqstatus_data['legendlabels'].push(reqstatus_data['labels'][v] + " ["+this.priceToString(statData[stat_keys[v]]) +"명,"+((100 * statData[stat_keys[v]] / statData['TOTAL'])).toFixed(1)+" %]") // Legend 표기 Type[n명, ratio%]
        reqstatus_data['labels'][v] = reqstatus_data['labels'][v] +" "+((100 * statData[stat_keys[v]] / statData['TOTAL'])).toFixed(1) +"%"
        reqstatus_data['datasets'][0]['data'].push(statData[stat_keys[v]] )
        
        
        
      }


      return reqstatus_data

    },
    

  },
  methods: {
    toggleHosScreen(){
      this.isHosScreen = !this.isHosScreen;
    },
    toggleHosType(){
      this.isHosType = !this.isHosType;
    },
    priceToString(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    // RegionBarChart callback function for emit 'stat_loaded' function
    barChartStatDataLoaded(){
      // alert('StatView:barChartStatDataLoaded()'+JSON.stringify(value))
      this.statData = this.$refs['statviewManagerData'].getStatData();
      this.statDash =  this.$refs['statviewManagerData'].getStatDash();
      
      this.statScreen = this.$refs['statviewManagerData'].getStatScreen();
      
      this.statReqType = this.$refs['statviewManagerData'].getStatReqType();
      
      this.statHospitalScreenChartStatus = this.$refs['statviewManagerData'].getStatHospitalScreenChartStatus();
      this.statHospitalScreenTableStatus = this.$refs['statviewManagerData'].getStatHospitalScreenTableStatus();
      this.statHospitalTypeChartStatus = this.$refs['statviewManagerData'].getStatHospitalTypeChartStatus();
      this.statHospitalTypeTableStatus = this.$refs['statviewManagerData'].getStatHospitalTypeTableStatus();
      //console.log("statDash:"+ JSON.stringify(this.statDash))
      this.summarize()
    },
    getStatHospitalScreenTableStatus(){
      if (this.statHospitalScreenTableStatus == undefined){
        return undefined;
      }
      return this.statHospitalScreenTableStatus;
    },
    getStatHospitalScreenChartStatus(){
      
      if (this.statHospitalScreenChartStatus == undefined){
        return undefined;
      }
      return this.statHospitalScreenChartStatus;
    },
    getStatHospitalTypeChartStatus(){
     
      if (this.statHospitalTypeChartStatus == undefined){
        return undefined;
      }
      return this.statHospitalTypeChartStatus;
    },
    getStatHospitalTypeTableStatus(){
      if (this.statHospitalTypeTableStatus == undefined){
        return undefined;
      }
      return this.statHospitalTypeTableStatus;
    },
    getStatScreen(){
      if (this.statScreen == undefined){
        return undefined;
      }
      return this.statScreen;
    },
    getStatReqType(){
      if (this.statReqType == undefined){
        return undefined;
      }
      return this.statReqType;
    },
    getStatDash(){
      if (this.statDash == undefined ){
        return undefined
      }
      return this.statDash;
    },
    
    // summarize data received from child component
    summarize(){
      // console.log("summarize()")
      // alert("StatView::summarize()")
      this.colorFirstHalfHexArray = this.$refs['statviewManagerData'].getTotalColorFirstHalfHexArray()
      this.colorLastHalfHexArray= this.$refs['statviewManagerData'].getTotalColorLastHalfHexArray()
      

    }


    
    
  }
}

</script>


<style lang="scss" scoped>
/* dashboard {
  position: fixed;
  top: 100px;
  left: 1400px;
  width: 400px;
  height: 75px;
  padding: 1rem;
  color: white;
  background: rgb(22, 137, 35);
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

/* 시작: 말안듣게 배치되는 녀석들 강제 배정 CSS 코드 
div-stat-column-container
	div-stat-chart-container
		div-stat-sex-title-layout
			div-stat-title-layout
			div-stat-chart-layout
	div-stat-age-container
		div-stat-age-title-layout
			div-stat-title-layout
			div-stat-chart-layout
*/

.div-stat-column-container{
  position: relative !important;
  width: 500px !important;; /* 원하는 너비로 설정 */
  height: 1000px !important;; /* 원하는 높이로 설정 */
  // border: 1px solid #000 !important;; /* 경계선을 보기 위해 설정 */
}
.div-stat-chart-container{
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
}

.div-stat-table-container{
  position: relative !important;
  top: 0px !important;
  left: 0px !important;
}

.div-stat-chart-container2{
  position: relative !important;
  top: 0px !important;
  left: 0px !important;
}
.div-stat-age-container{
  position: absolute !important;
  top: 470px !important;
  left: 0px !important;
}
/* 끝: 말안듣게 배치되는 녀석들 강제 배정 CSS 코드 */
.table-td-number-text{
  align-items:right !important;
}

.div-stat-chart-layout{
  width: 480px !important;
  height: 480px !important;
  border-radius:0px !important;
  display:flex !important;
  justify-content:center !important;
  align-items:center !important;
  padding:40px !important;
}
.div-stat-hosscr-chart-layout{
  align-items:center !important;
  
  
}
.div-title-layout{
  width:100% !important;
  height:40px !important;
  border-radius:0px !important;
  background-color:#0B2463 !important;
  display:flex !important;
  justify-content:center !important;
  align-items:center !important;
  font-weight:700 !important;
  font-size:16px !important;
  padding:0px !important;
}
.div-total-width{
  width:1520px !important;
  text-align:left !important;
}
.div-dash-item-layout{
  width:300px !important;
  height:100px !important;
  border:solid 1px !important;
  border-color:#D9D9D9 #D9D9D9 #D9D9D9 #D9D9D9 !important;
  font-weight:bold !important;
  color:#172F6B !important;
  font-size:1.0rem !important;
  justify-content:center !important;
  padding:10px !important;

}

.img-logo-image{
  vertical-align:middle !important;
  width:110px !important;
}

.div-logo-layout{
  width:110px !important;
  height:110px !important;
  vertical-align:middle !important
}

/* 시작: stat column과 title layout */
.div-stat-region-title-layout{
  width:1010px !important;
  height:20px !important;
  border-radius:0px !important;
  background-color:#D9D9D9 !important;
  display:flex !important;
  flex-direction:column !important;
  justify-content:center !important;
  text-align:center !important;
}
.div-stat-region-layout{
  width:1010px  !important;
}
.div-stat-region-chart-layout{
  width:1010px !important;
}
.div-stat-column-layout{
  width:100% !important;
  height:20px !important;
  
}
.div-stat-title-layout{
  width:500px !important;
  height:20px !important;
  border-radius:0px !important;
  background-color:#D9D9D9 !important;
  display:flex !important;
  flex-direction:column !important;
  justify-content:center !important;
  text-align:center !important;
}
.label-stat-title{
  font-size:14px;
  color:#1C336E;
  font-weight:600;
}
/* 끝: stat column과 title layout */
.map-wrapper {
  position:relative;
  text-align: center;

  // svg {
  //   border: 1px solid #aaaaaa;
  // }

  // .yellow
  // {
  //   background-color: yellow;
  // }
  // .gray
  // {
  //   background-color: gray;
  // }
  
  .province-title {
    position: absolute;
    top: 50px;
    left: 40px;
    width: 200px;
    color: #D5708B;
    z-index: 100;
    pointer-events: none;
    font-size: 0.9rem;
  }
  .province-info {
    // background: white;
    position: absolute;
    color: #D5708B;
    top: 50px;
    right: 60px;
    width: 600px;
    height: 500px;
    z-index: 101;
    font-size: 0.9rem;
    pointer-events: none;
  }
  .province-state {
    // background: white;
    position: absolute;
    color: #D5708B;
    bottom: 200px;
    right: 10px;
    width: 300px;
    z-index: 102;
    pointer-events: none;
  }
  .province-summary {
    // background: white;
    position: absolute;
    color: #D5708B;
    bottom: 10px;
    right: 10px;
    width: 300px;
    z-index: 103;
    pointer-events: none;
  }
  .province-state > ul, .province-summary > ul {
    list-style: none;
  }
  .background {
    /* fill: #021019; */
    fill: transparent;
    pointer-events: all;
  }
  .map-layer {
    fill: #08304b;
    stroke: #021019;
    stroke-width: 1px;
  }
}
</style>
