<template>
    <div :key="this.isLoggedIn" v-if='this.isLoggedIn'>
        <div style="height:50px" />
        <!-- Start of ReqMain.vue template -->
        <!-- 시작: 진단 의뢰 관련 CR 버튼 -->
        <div class="container d-print-none" style="align-items:left;width:1200px">
            <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_register_req"
                v-on:click.prevent="this.set_CRUDENV_mode('LIST')"
                v-bind:disabled="this.get_CRUDENV_mode()=='LIST'"
            >환자리스트
            </button>
            &nbsp;
            &nbsp;
            <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_register_req"
                v-on:click.prevent="this.set_CRUDENV_mode('ADD')"
                v-bind:disabled="this.get_CRUDENV_mode()=='ADD'"
            >신규환자등록
            </button>
            <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_print"
                v-show="this.get_CRUDENV_mode() == 'UPDATE' || this.get_CRUDENV_mode()=='DETAIL'"
                v-on:click.prevent="this.doPrint()"
                style="float:right; margin-right: 10px;"> 희귀질환 진단 의뢰 보고서 인쇄 </button>
        </div>
        <div style="height:30px" />
        <!-- 끝: 진단 의뢰 관련 CR 버튼 -->
        <div class="loading text-center" v-if="this.is_CRUDENV_loading()"><img src="../../assets/loader.gif" class=""></div>
        <!-- 시작: 조회 -->
        <div class="container " :class="{ 'blur' : this.is_CRUDENV_loading() }" style="align-items:left;width:1200px" v-show="this.get_CRUDENV_mode()=='LIST'">
             <!-- 시작: 조회 옵션 설정 -->
            <div class="row"  >
                <div class="col justify-content-left"  ref='up_position'>
                    <p style="font-weight: bold"><i class="fas fa-check"></i>&#x25A0; 진단 의뢰 환자 리스트  &nbsp; &nbsp;
                    </p>
                </div>
            </div>
            <div class="row" >
                <div class="col" style="height:5px;background-color: #ffffff"></div>
            </div>

            <!-- 시작: 검색 화면 -->
            <div>
                <screen-search @fields_changed="search_list" ref="ref_search" />
            </div>
            <!-- 끝: 검색 화면 -->
             
            <!-- 끝: 조회 옵션 설정 -->

            <!-- 시작: 조회 후 공백 설정 -->

            <div class="row" >
                <div class="col" style="height:30px;background-color: #ffffff">
                </div>
            </div>
            <!-- 끝: 조회 후 공백 설정 -->


            <!-- 시작: 환자 조회 리스트 -->
            <div class="row ">
                <div class="col  col-md-12" style="text-align:left">
                    <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_deelete_patient"
                        v-on:click.prevent="this.deletePatient()"
                     style="float:right;"> 삭제(선택) </button>
                    <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_excel_download"
                        v-on:click.prevent="this.download_excel()"
                     style="float:right; margin-right: 10px;"> 환자 리스트 엑셀 다운로드 </button>
                </div>
            </div>
            <div class="row" >
                <div class="col" style="height:20px;background-color: #ffffff"></div>
            </div>
            <div class="row ">
                <div class="col  col-md-12" style="text-align:left">
                    <!-- 시작: backend로부터 받은 의뢰환자들 리스트 -->
                    <table class="table table-hover" id="request_patient_tbl" style="width:100%">
                    <thead>
                    <tr class="tr-head-center">
                        <th scope="col" width="2%"></th>
                        <th scope="col" width="2%">No</th>
                        <th scope="col" width="6%">환자명</th>
                        <th scope="col" width="6%">환자번호</th>
                        <th scope="col" width="3%">나이</th>
                        <th scope="col" width="3%">성별</th>
                        <th scope="col" width="8%">질환명</th>
                        <th scope="col" width="8%">의뢰기관</th>
                        <th scope="col" width="8%">담당의</th>
                        <th scope="col" width="8%">의뢰일</th>
                        <th scope="col" width="6%">진행상태</th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row,idx) in this.req_pb_obj_list" v-bind:value="idx" v-bind:key="idx" class="tr-row-center" >
                        <!-- <input type='hidden' value="{{row['pb_obj']['id']}}"/> -->
                        <td style='vertical-align:middle'>
                            <input type="checkbox" v-bind:ref="this.$concat_str('chk_row_selected_',idx)" name="chk_search_ignore_date" v-model="checked_req_patient_list" v-bind:value="row['pb_obj']['id']">
                        </td>
                        <td style='vertical-align:middle' scope="row">{{this.total_item_cnt-(((this.current_page-1)*this.page_size)+idx)}}</td>

                        <td style='vertical-align:middle' v-if="(row['pb_obj']['text_pb_reqstatus']=='의뢰전' || row['pb_obj']['text_pb_reqstatus']=='반려') && this.isWriter(row['pb_obj']['writer'])">
                            <a v-on:click="this.set_CRUDENV_mode('UPDATE',row['pb_obj']['id'])" class='req-update'><font color="#0d6efd"> {{row['pb_obj']['text_pb_name']}}</font></a>
                        </td>
                        <td style='vertical-align:middle' v-else-if="row['pb_obj']['text_pb_reqstatus']=='검사완료' && this.isManager() " > <!-- 관리자이고 status가 '검사완료'이면, 클릭시 MODE를 UPDATEMANAGER로 변경한다-->
                            <a v-on:click="this.set_CRUDENV_mode('UPDATEBYMANAGER',row['pb_obj']['id'])" class='req-update'><font color="#0d6efd"> {{row['pb_obj']['text_pb_name']}}</font></a>
                        </td>
                        <td style='vertical-align:middle' v-else>
                            <!-- {{row['pb_obj']['text_pb_name']}} -->
                            <a v-on:click="this.set_CRUDENV_mode('DETAIL',row['pb_obj']['id'])" class='req-update'><font color="#008000"> {{row['pb_obj']['text_pb_name']}}</font></a>
                        </td>
                        <td style='vertical-align:middle'>{{row['pb_obj']['text_pb_number']}}</td>
                        <td style='vertical-align:middle'>{{row['pb_obj']['age']}}</td>
                        <td style='vertical-align:middle'>{{row['pb_obj']['sel_pb_sex']}}</td>
                        <td style="text-align:left;vertical-align:middle">{{row['pb_obj']['pb_diseasename_text']}}</td>
                        <td style='vertical-align:middle'>{{row['pb_obj']['hospital']}}</td>
                        <td style='vertical-align:middle'>{{row['processed_contract_sms']['text_sms_request_doc_name']}}</td>
                        <td style='vertical-align:middle' >{{row['pb_obj']['text_pb_reqreferal_date']}}</td>
                        <td style='vertical-align:middle' v-if="row['pb_obj']['text_pb_reqstatus']=='의뢰전'"><font color="#ff0000">{{this.convert_patient_status(row['pb_obj']['text_pb_reqstatus'])}}</font></td>
                        <td style='vertical-align:middle' v-else-if="row['pb_obj']['text_pb_reqstatus']=='반려'"><font color="#ff7f00">{{this.convert_patient_status(row['pb_obj']['text_pb_reqstatus'])}}</font></td>
                        <td style='vertical-align:middle' v-else>{{this.convert_patient_status(row['pb_obj']['text_pb_reqstatus'])}}</td>
                        
                    </tr>
                    </tbody>
                    </table>
                    <!-- 끝: backend로부터 받은 의뢰환자들 리스트 -->
                </div>
                <!-- 시작: pagination -->
                <div class="col" >
                    <!-- Showing 1 to 5 of 282 rows  rows per page -->
                    <label class="col-1 label-search-center" style="width:200px;">Showing {{this.current_page}} to {{this.page_size}} of {{this.total_item_cnt}} rows </label>
                    <select id="sel_search_page_size" name="sel_search_page_size" v-model="this.page_size" class="form-select-sm select-search" @change="this.search_list()" style="width:60px" >
                        <option value="2">2</option>
                        <option value="5">5</option>
                        <option value="10" selected>10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                    </select>
                    <label for="sel_search_page_size" class="col-1 label-search-center" style="width:95px;">rows per page </label>
                </div>
                <div class="col">
                    <nav aria-label="..." style="float:right;">
                        <ul class="pagination  pagination-sm">
                            <a class="page-link" v-on:click="this.move_page(1)">First</a>
                            <li class="page-item" v-if="this.current_page>=3"><span class="page-link">...</span></li>
                            <li class="page-item" :class="{ 'active' : this.current_page==page_count }" v-for="page_count in (Math.ceil(this.total_item_cnt/this.page_size))" v-bind:key="page_count">
                                <a v-show="page_count>= this.current_page-5 && page_count<= this.current_page+5" v-if="(this.current_page==page_count)" class="page-link">{{page_count}}</a>
                                <a v-show="page_count>= this.current_page-5 && page_count<= this.current_page+5" v-else-if="(page_count>=this.current_page-9) && (page_count<=this.current_page+9)" class="page-link" v-on:click="this.move_page(page_count)">{{page_count}}</a>
                            </li>
                            <li class="page-item" v-if="(this.current_page+1)*this.page_size<this.total_item_cnt&&Math.ceil(this.total_item_cnt/this.page_size)!=this.current_page"><span class="page-link">...</span></li>
                            <a class="page-link" v-on:click="this.move_page(Math.ceil(this.total_item_cnt/this.page_size))">Last</a>
                        </ul>
                    </nav>
                </div>
                <!-- 끝: pagination -->
                <div class="row" >
                    <div class="col" style="height:40px;background-color: #ffffff"></div>
                </div>
            </div>
            <!-- 끝: 환자 조회 리스트 -->
        </div>
         <!-- 끝: 조회 -->


        <!-- 시작: 새환자 등록--->
        <div id="addNewRequestPatientModal" class="container" style="align-items:left;width:1200px" v-show="this.get_CRUDENV_mode()=='ADD' || this.get_CRUDENV_mode() == 'UPDATE' || this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'">
            <div class="row justify-content-left">
                <div style="height:50px" >
                    <button href="#" class="btn btn-primary btn-sm btn-search-action d-print-none" style="font-size:16px; float:right;"
                    v-on:click.prevent="this.set_CRUDENV_mode('LIST')" >
                        뒤로가기
                    </button>
                </div>
                <form id="frm_request" ref ="frm_basis_patient" method="post" @submit.prevent="onReqBPSubmit"  enctype=“multipart/form-data”>

                    <div class="accordion-item d-print-none" v-if="this.pb_obj.text_pb_reqstatus =='반려'" style="padding:15px;">
                        <h4 class="accordion-header">
                            <font color="#0c63e4" >반려사유</font>
                        </h4>
                        <font color="#ff0000" >
                            {{this.pb_obj.text_manager_comment}}
                        </font>
                    </div>
                    <div class="accordion-item d-none d-print-block">
                        <h2 class="accordion-header">
                            <b>이건희사업단 RareDX 희귀질환 진단 의뢰 보고서</b>
                        </h2>
                    </div>
                    <!-- 시작: 기본 환자 정보 탭 -->
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne"
                            v-on:click="toggle_tab_accordian(0)"
                            ref='tab_basis_patient'
                            >
                            PART I 기본 정보
                            </button>
                        </h2>
                        <!-- 시작: 기본 환자 정보 내용  -->
                        <div id="panelsStayOpen-collapseOne" ref="basis_patient_accordian"
                            class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne"
                            v-bind:class="[{show:this.require_validation[0]}]"
                        >

                            <!-- accordion-body for patient_basis_info -->
                            <div class="accordion-body">
                                <table class="table table-hover table-border" style="width:100%" >
                                <tbody>
                                    <tr class="tr-row-center">
                                        <td class="table-td-align" rowspan="9" style="vertical-align:middle;background-color:#cccccc;width:30px;text-align:center"  v-if="this.is_valid_callable_pb_age()" ><b>환자</b></td>
                                        <td class="table-td-align" rowspan="7" style="vertical-align:middle;background-color:#cccccc;width:30px;text-align:center"  v-else><b>환자</b></td>
                                        <td class="table-td-align" style="width:100px;"> <font color="#ff0000" >*</font> 성명 </td>
                                        <td class="table-td-align" style="width: 200px;">
                                            <input type="text" placeholder="이름" id="text_pb_name"
                                                name="text_pb_name"  ref="text_pb_name"
                                                class="form-control-sm input-search" style="height:20px"
                                                v-model="this.pb_obj.text_pb_name"
                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode()=='UPDATEBYMANAGER' "
                                                :required="this.require_validation[0]" />

                                        </td>
                                        <td class="table-td-align" style="width: 90px;"> <font color="#ff0000" >*</font> 환자번호</td>
                                        <td class="table-td-align" colspan="4"> {{store.getters['userStore/getUserHospitalCode']}} -
                                            <input type="text" placeholder="환자번호"
                                                id="text_pb_number"
                                                name="text_pb_number"
                                                ref="text_pb_number"
                                                class="form-control-sm input-search"
                                                v-model="this.pb_obj.text_pb_number"
                                                style="height:20px"
                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode()=='UPDATEBYMANAGER' "
                                                :required="this.require_validation[0]" />
                                        </td>
                                    </tr>

                                    <tr class="tr-row-center" >
                                        <!-- <td class="table-td-align" ><font color="#ff0000">*</font> 질환명 <br> -->
                                        <td class="table-td-align" ><font color="#ff0000">*</font>질환명 <br>
                                        &nbsp;

                                        </td>
                                        <td class="table-td-align" colspan="6" >
                                            <table class="table-noborder">
                                                <tr>
                                                    <td class="table-td-header-align" style="width:100%" colspan="2">
                                                        <table class="table-noborder" style="width:100%">
                                                        <tr>
                                                            <td class="table-td-header-align" style="width:25%;text-align:left">
                                                            <font color='#ff0000'>*</font>Clinical diagnosis
                                                            </td>
                                                            <td class="table-td-header-align" style="width:75%">
                                                            <input v-model="this.pb_obj.pb_diseasename_text"
                                                                class="form-control-sm input-search"
                                                                style="width:100%; float:left;"
                                                                ref ='pb_diseasename_text'
                                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode()=='UPDATEBYMANAGER' "
                                                                />
                                                            </td>
                                                        </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <!-- For HPO -->
                                                    <td class="table-td-header-align"  style="width:50%">
                                                        <table class="table-noborder" style="width:100%">
                                                            <tr>
                                                                <td class="table-td-header-align" style="width:100%;text-align:left">
                                                                    <font color="#ff0000">*</font>HPO
                                                                    <div v-if="this.pb_obj.sel_pb_diseasename_hpo.length==0" >

                                                                        <button href="#" class="btn btn-primary btn-sm btn-search-action d-print-none" id="btn_disease_hpo_add"
                                                                            ref="btn_disease_hpo_add"
                                                                            v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode()=='UPDATEBYMANAGER' "
                                                                            @click.self.prevent="add_diseasename('hpo')">+</button>
                                                                        <button href="#" class="btn btn-primary btn-sm btn-search-action d-print-none" id="btn_disease_hpo_delete"
                                                                            v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode()=='UPDATEBYMANAGER' "
                                                                            @click.self.prevent="remove_diseasename('hpo',idx)">-</button>
                                                                    </div>
                                                                </td>
                                                                <td class="table-td-header-align" style="width:100%">
                                                                    <table class="table-noborder" style="width:400px;" >
                                                                        <tr >
                                                                            <td class="table-td-header-align" style="width:100%">
                                                                                <font color='#0000ff'>*</font> 아래 창에서 HPO 선택 혹은 코드 번호 중 하나는 필수 입력 사항입니다.
                                                                            </td>
                                                                        </tr>
                                                                        <tr v-for="(row,idx) in this.pb_obj.sel_pb_diseasename_hpo" ref="disease_name_hpo" v-bind:value="idx" v-bind:key="idx" class="tr-row-center" >
                                                                            <td class="table-td-header-align" style="width:100%">
                                                                                <input v-model="this.pb_obj.sel_pb_diseasename_hpo[idx].value" list="hpo_data"
                                                                                    class="form-control-sm input-search"
                                                                                    style="width:200px"
                                                                                    v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode()=='UPDATEBYMANAGER' "
                                                                                    v-bind:ref="this.$concat_str('sel_pb_diseasename_hpo_',idx)"
                                                                                />
                                                                                <datalist id="hpo_data">
                                                                                    <option v-for="hpo in this.hpo_list" :key="hpo.code" :value="hpo.display" >{{hpo.display}}</option>
                                                                                </datalist>


                                                                                <input v-model="this.pb_obj.sel_pb_diseasename_hpo[idx].textnumber"
                                                                                    class="form-control-sm input-search"
                                                                                    style="width:80px"
                                                                                    v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode()=='UPDATEBYMANAGER' "
                                                                                    v-bind:ref="this.$concat_str('text_pb_hpo_textnumber',idx)"
                                                                                />




                                                                                <button href="#" class="btn btn-primary btn-sm btn-search-action d-print-none" id="btn_disease_hpo_add"
                                                                                    v-bind:ref="this.$concat_str('btn_disease_hpo_add_',idx)"
                                                                                    v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode()=='UPDATEBYMANAGER' "
                                                                                    @click.self.prevent="add_diseasename('hpo')">+</button>
                                                                                <button href="#" class="btn btn-primary btn-sm btn-search-action d-print-none" id="btn_disease_hpo_delete"
                                                                                    v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode()=='UPDATEBYMANAGER' "
                                                                                    @click.self.prevent="remove_diseasename('hpo',idx)">-</button>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <!-- End of HPO -->
                                                    <!-- For omim -->
                                                    <td class="table-td-header-align"  style="width:50%">
                                                        <table class="table-noborder" style="width:100%">
                                                            <tr>
                                                                <td class="table-td-header-align" style="width:100%">
                                                                    OMIM
                                                                    <div v-if="this.pb_obj.sel_pb_diseasename_omim.length==0" >
                                                                        <button href="#" class="btn btn-primary btn-sm btn-search-action d-print-none" id="btn_disease_omim_add"
                                                                            v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode()=='UPDATEBYMANAGER' "
                                                                            ref="btn_disease_omim_add"
                                                                            @click.self.prevent="add_diseasename('omim')">+</button>
                                                                        <button href="#" class="btn btn-primary btn-sm btn-search-action d-print-none" id="btn_disease_omim_delete"
                                                                            v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode()=='UPDATEBYMANAGER' "
                                                                            @click.self.prevent="remove_diseasename('omim',idx)">-</button>
                                                                    </div>
                                                                </td>
                                                                <td class="table-td-header-align" style="width:100%">
                                                                    <table class="table-noborder" style="width:320px;" >
                                                                        <tr >
                                                                            <td class="table-td-header-align" style="width:100%">

                                                                            </td>
                                                                        </tr>
                                                                        <tr v-for="(row,idx) in this.pb_obj.sel_pb_diseasename_omim" ref="disease_name_omim" v-bind:value="idx" v-bind:key="idx" class="tr-row-center" >
                                                                            <td class="table-td-header-align" style="width:100%">
                                                                                <input v-model="this.pb_obj.sel_pb_diseasename_omim[idx].value" list="omim_data"
                                                                                    class="form-control-sm input-search"
                                                                                    style="width:200px"
                                                                                    v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode()=='UPDATEBYMANAGER' "
                                                                                    v-bind:ref="this.$concat_str('sel_pb_diseasename_omim_',idx)"
                                                                                />
                                                                                <datalist id="omim_data">
                                                                                    <option v-for="omim in this.omim_list" :key="omim.code" :value="omim.display" >{{omim.display}}</option>
                                                                                </datalist>
                                                                                <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_disease_omim_add"
                                                                                    v-bind:ref="this.$concat_str('btn_disease_omim_add_',idx)"
                                                                                    v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode()=='UPDATEBYMANAGER' "
                                                                                    @click.self.prevent="add_diseasename('omim')"
                                                                                >+</button>
                                                                                <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_disease_omim_delete"
                                                                                    v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode()=='UPDATEBYMANAGER' "
                                                                                    @click.self.prevent="remove_diseasename('omim',idx)">-</button>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <!-- End of omim -->
                                                </tr>
                                                
                                            </table>
                                        </td>
                                    </tr>
                                    <tr class = "tr-row-center bottom-line">
                                        <td class="table-td-align" >
                                            <font color="#ff0000">*</font> 생년월일
                                        </td>
                                        <td>
                                            <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
                                            <input v-model="this.pb_obj.text_pb_birthday"
                                                type="date"
                                                id="text_pb_birthday"
                                                name="text_pb_birthday" style="width: 184px;height:20px"
                                                ref='text_pb_birthday'
                                                :max="store.getters['constStore/getMaxDate']"
                                                :required="this.require_validation[0]"
                                                v-on:input="this.show_pb_uploadfiles()"
                                                v-on:select="this.show_pb_uploadfiles()"
                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode()=='UPDATEBYMANAGER' " />

                                            <span variant="danger"
                                                class="error-disp" v-if="!this.is_valid_pb_birthday()">
                                                min:  {{getMinDate()}}
                                                max : {{getToday()}}
                                            </span>


                                            </div>
                                        </td>
                                        <td class="table-td-align"><font color="#ff0000">*</font> 검사처방일</td>
                                        <td colspan="4">
                                            <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
                                                <input v-model="this.pb_obj.text_pb_reqreferal_date"  type="date"
                                                id="text_pb_reqreferal_date" name="text_pb_reqreferal_date"
                                                style="width: 184px;height:20px"
                                                ref='text_pb_reqreferal_date'
                                                :max="store.getters['constStore/getMaxDate']"
                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode()=='UPDATEBYMANAGER' "
                                                v-on:input="this.show_pb_uploadfiles()"
                                                v-on:select="this.show_pb_uploadfiles()" />

                                            </div>
                                        </td>

                                    </tr>

                                    <tr class="tr-row-center">
                                        <td class="table-td-align">
                                            <font color="#ff0000">*</font> 나이
                                        </td>
                                        <td>
                                            <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups"
                                            v-show="this.is_valid_callable_pb_age() || this.get_CRUDENV_mode() == 'DETAIL' "
                                            >

                                            <b> {{ this.pb_obj.age }}</b> <b> <font color="0000ff">{{ this.get_age_type(this.pb_obj.age) }} </font></b>

                                            </div>
                                        </td>

                                        <td class="table-td-align"  ><font color="#ff0000">*</font> 성별</td>
                                        <td style="width:200px">
                                            <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
                                                <div class="btn-group btn-group-sm" role="btn_basis_patientsex" aria-label="First group" style="height:20px" >
                                                    <select id="sel_pb_sex" v-model="this.pb_obj.sel_pb_sex"
                                                        name="sel_pb_sex" class="form-select-sm select-search"
                                                        style="width:100px"
                                                        ref='sel_pb_sex'
                                                        v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode()=='UPDATEBYMANAGER' "
                                                        :required="this.require_validation[0]" >
                                                        <option value="" selected >-</option>
                                                        <option value="M"  >Male</option>
                                                        <option value="F"  >Female </option>
                                                        <option value="NA"  >NA</option>
                                                    </select>

                                                </div>
                                            </div>
                                        </td>
                                        <td class="table-td-align"><font color="#ff0000">*</font> 지역</td>
                                        <td colspan="2">
                                            <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
                                                <div class="btn-group btn-group-sm" role="btn_basis_patientarea" aria-label="First group" style="height:20px" >
                                                    <select id="sel_area_code" v-model="this.pb_obj.sel_area_code"
                                                        name="sel_area_code" class="form-select-sm select-search"
                                                        style="width:100px"
                                                        ref='sel_area_code'
                                                        v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL'"
                                                        :required="this.require_validation[0]" >
                                                        <option value="" selected >-</option>
                                                        <option :key="i" :value="d.id" v-for="(d,i) in this.sel_area_list">{{d.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <!--  동의서 입력 부분 -->
                                    <tr class="tr-row-center d-print-none" v-show="this.get_CRUDENV_mode() != 'DETAIL'">
                                        <td class="table-td-align" colspan ="7" style="text-align:center" >
                                            업로드 파일 (<font color="ff0000" > *</font> 환자 연령에 따라 필수 업로드 파일 결정)
                                            <span variant="danger"
                                                class="error-disp"
                                                v-show="!this.is_valid_callable_pb_age()" style="text-align:center">
                                                입력하신 의뢰 환자의 생년월일과 검사처방일 정보가 유효하지 않아 필수 업로드 파일을 입력할 수 없습니다.
                                            </span>

                                        </td>
                                    </tr>
                                    <tr class="tr-row-center d-print-none" v-show="this.is_valid_callable_pb_age() || this.get_CRUDENV_mode() == 'DETAIL'">
                                        <td class="table-td-align" colspan ="7">
                                            <!-- 필수 동의서 업로드 -->
                                            <div style="display:table; width:100%; float: left; background:white;">
                                                <div style="display:table-row">
                                                    <b style="margin: 10px; font-size:12px;">필수 동의서 업로드 </b>
                                                </div>
                                                <div style="display:table-row;">
                                                    <div style="margin:10px; width:50%; float:left; top:20px;">
                                                        <div style="display:table-row">
                                                            <b style="margin: 10px;">대상 문서</b>
                                                        </div>
                                                        <div style="display:table-row">
                                                            <table class="table-noborder" style="width:100%" v-if="this.get_age_type(this.pb_obj.age) !=''" >
                                                            <tbody>
                                                            <tr v-for="(row,idx) in this.$pb_essential_files[this.get_age_type(this.pb_obj.age)]" ref="uploadable_files" v-bind:value="idx" v-bind:key="idx" class="tr-row-center"  >
                                                                <td class="table-td-align" style="width:40%;text-align:left"  v-show="this.$pb_essential_files[this.get_age_type(this.pb_obj.age)][idx]">
                                                                    <font color="ff0000" >*</font> {{this.$pb_upload_filenames[idx]}}
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div style="margin: 10px; height: 210px; width: 45%; float: left;">
                                                        <div class="file-upload-container"
                                                            @dragenter="pb_reqfiles_onDragenter"
                                                            @dragover="pb_reqfiles_onDragover"
                                                            @dragleave="pb_reqfiles_onDragleave"
                                                            @drop="pb_reqfiles_onDrop"
                                                            @click="pb_reqfiles_onClick"
                                                            style="width:100%; height:80%;"
                                                            >
                                                            <div class="file-upload" :class="this.is_files_dragged ? 'dragged' : ''">
                                                                Drag & Drop<br>Files
                                                            </div>
                                                            <div style="float:left; width:80%; height:100%; vertical-align: middle; padding-left: 10px; background: white;">
                                                                <!-- 업로드된 리스트 -->
                                                                <div class="file-upload-list" style="width:100%; height:100%; overflow:auto; background:white;">
                                                                    <div class="file-upload-list__item" v-for="(file, index) in this.pb_obj.reqfiles" :key="index" style="padding-top: 10px;">
                                                                        <div class="file-upload-list__item__data">
                                                                            <div class="file-upload-list__item__data-name" style="float: left; width: 310px; display: block; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; margin-right:5px; color:black;"  @click.stop="" @click="this.on_download_file(file.file_path, file.name)">
                                                                                {{ file.name }}
                                                                            </div>
                                                                            <div class="file-upload-list-delete-btn" @click.stop="" @click="pb_reqfiles_onHandleRemove(index)" style="float:left;" v-show="this.get_CRUDENV_mode()!='UPDATEBYMANAGER' ">
                                                                                삭제
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div style="margin: 10px; height: 25px; width: 100%;">
                                                            <!-- 파일 업로드 -->
                                                            <input type="file" ref="pb_reqfiles_Input" class="file-upload-input" @change="pb_reqfiles_onChange" multiple
                                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL'">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="tr-row-center d-print-none" v-show="this.is_valid_callable_pb_age() || this.get_CRUDENV_mode() == 'DETAIL'">
                                        <td class="table-td-align" colspan ="7">
                                            <!-- 미 필수 동의서 업로드 -->
                                            <div style="display:table; width:100%; float: left; background:white;">
                                                <div style="display:table-row">
                                                    <!-- 2023.02.07 edited by dsryu -->
                                                    <!-- b style="margin: 10px; font-size:12px;">선택 동의서 및 기타 파일 업로드</b-->
                                                    <b style="margin: 10px; font-size:12px;">기타 파일 업로드 </b>

                                                    <!-- 2023.02.07 end of edited by dsryu -->

                                                </div>
                                                <div style="display:table-row;">
                                                    <div style="margin:10px; width:50%; float:left; top:20px;">

                                                    </div>
                                                    <div style="margin:10px; height:210px; width:45%; float:left;">
                                                        <div class="file-upload-container"
                                                            @dragenter="pb_optfiles_onDragenter"
                                                            @dragover="pb_optfiles_onDragover"
                                                            @dragleave="pb_optfiles_onDragleave"
                                                            @drop="pb_optfiles_onDrop"
                                                            @click="pb_optfiles_onClick"
                                                            style="width:100%; height:80%;"
                                                            >
                                                            <div class="file-upload" :class="this.is_files_dragged ? 'dragged' : ''">
                                                                Drag & Drop<br>Files
                                                            </div>
                                                            <div style="float:left; width:80%; height:100%; vertical-align: middle; padding-left: 10px; background: white;">
                                                                <!-- 업로드된 리스트 -->
                                                                <div class="file-upload-list" style="width:100%; height:100%; overflow:auto; background:white;">
                                                                    <div class="file-upload-list__item" v-for="(file, index) in this.pb_obj.optfiles" :key="index" style="padding-top: 10px;">
                                                                        <div class="file-upload-list__item__data">
                                                                            <div class="file-upload-list__item__data-name" style="float: left; width: 310px; display: block; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; margin-right:5px; color:black;"  @click.stop="" @click="this.on_download_file(file.file_path, file.name)">
                                                                                {{ file.name }}
                                                                            </div>
                                                                            <div class="file-upload-list-delete-btn" @click.stop="" @click="pb_optfiles_onHandleRemove(index)" style="float:left;" v-show="this.get_CRUDENV_mode()!='UPDATEBYMANAGER'">
                                                                                삭제
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style="margin: 10px; height: 25px; width: 100%;">
                                                            <!-- 파일 업로드 -->
                                                            <input type="file" ref="pb_optfiles_Input" class="file-upload-input" @change="pb_optfiles_onChange" multiple
                                                            v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL'">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- 2023.02.07 added by dsryu for etc opinion input-->
                                    <tr class="tr-row-center">

                                        <td class="table-td-align" >기타 중요 소견</td>
                                        <td colspan="7">
                                            <textarea class="md-textarea form-control" rows="7" style="width:100%;font-size:12px"
                                                id="text_pb_etcopinion"
                                                v-model = "this.pb_obj.text_pb_etcopinion"
                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL'"
                                                ref='text_pb_etcopinion'
                                                ></textarea>
                                            <!--/div-->

                                        </td>
                                        
                                    </tr>
                                    <!-- 2023.02.07 end of added by dsryu -->
                                    <tr class="tr-row-center">
                                        <td class="table-td-align">채혈일</td>
                                        <td colspan="7">

                                            <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
                                            <input v-model="this.pb_obj.text_pb_blooddate"  type="date"
                                                id="text_pb_blooddate"
                                                name="text_pb_blooddate" style="width:184px;height:20px"
                                                :max="store.getters['constStore/getMaxDate']"
                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                                ref='text_pb_blooddate' />

                                                <span variant="danger"
                                                class="error-disp" v-if="!is_valid_pb_field('text_pb_blooddate')">
                                                min:  {{getMinDate()}}
                                                max : {{getToday()}}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="table-td-align">
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody>
                                </table>
                                <div class="row justify-content-center d-print-none" >
                                    <div class="col" style="text-align:center">
                                    <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_basis_save_temp"
                                         v-if="this.get_CRUDENV_mode()!='UPDATE' "
                                        @click.self.prevent="store_temp_pb_obj()"
                                        v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                    >
                                        임시저장
                                        </button> &nbsp; &nbsp;
                                    <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_basis_delete_temp"
                                        v-if="this.get_CRUDENV_mode()!='UPDATE' "
                                        @click.self.prevent="delete_temp_pb_obj()"
                                        v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                    >

                                        임시삭제
                                    </button> &nbsp; &nbsp;
                                    <button href="#" class="btn btn-primary btn-sm btn-search-action d-print-none" id="btn_db_update_pb_obj"
                                        v-if="this.get_CRUDENV_mode()== 'UPDATE' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER' "
                                        @click.self.prevent="this.patch_pb_obj()"
                                        v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL'"
                                    >
                                        PART I 기본 환자 정보 수정 (DB 반영)
                                    </button>
                                    </div>
                                </div>

                            </div>
                            <!-- End of accordion-body for patient_basis_info -->
                        </div>
                        <!-- 끝: 기본 환자 정보 내용-->

                    </div>
                    <!-- 끝: 기본 환자 정보 탭 -->
                    <!-- 시작: 환자 가족 정보 탭 -->
                    <div class="accordion-item"  >
                        <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                            <button class="accordion-button collapsed" type="button"
                            data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo"
                            v-on:click="toggle_tab_accordian(1)"
                            ref='tab_patient_family'
                            >
                            PART II 환자 가구원 정보
                            </button>
                        </h2>
                        <!-- div for patient_family_info accordion-body -->
                        <div id="panelsStayOpen-collapseTwo" ref="patient_family_accordian" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo"
                        v-bind:class="[{show:this.require_validation[1]}]"
                        >
                            <!-- accordion-body for patient_family_info -->
                            <div class="accordion-body">
                                <div class="row justify-content-center d-print-none" >
                                    <div class="col" style="text-align:left">

                                        <div  v-if="this.get_CRUDENV_mode()!= 'UPDATE'">
                                            <button href="#" class="btn btn-primary btn-sm btn-search-action d-print-none"
                                                id="btn_family_add"
                                                @click.self.prevent="add_family()"
                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                            >
                                                추가
                                            </button> &nbsp; &nbsp;
                                            <button href="#" class="btn btn-primary btn-sm btn-search-action d-print-none"
                                                id="btn_family_delete"
                                                @click.self.prevent="remove_family()"
                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                            >
                                                삭제
                                            </button> &nbsp; &nbsp;
                                            <button href="#" class="btn btn-primary btn-sm btn-search-action d-print-none"
                                                id="btn_family_save_temp"
                                                @click.self.prevent="store_temp_family()"
                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                            >
                                                임시저장
                                            </button> &nbsp; &nbsp;
                                            <button href="#" class="btn btn-primary btn-sm btn-search-action d-print-none"
                                                id="btn_family_delete_temp"
                                                @click.self.prevent="delete_temp_family()"
                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                            >
                                                임시삭제
                                            </button>
                                        </div>

                                    </div>
                                </div>

                                <table class="table table-border" style="width:100%" >
                                <thead>
                                <tr class="tr-head-center">
                                    <td class="table-td-header-align">
                                    <table class="table-noborder" style="width:100%" >
                                    <tr>
                                        <td class="table-td-header-align" style="width:5%">NO</td>
                                        <td class="table-td-header-align" style="width:15%" ><font color="#ff0000">*</font>성명</td>
                                        <td class="table-td-header-align"  style="width:25%" ><font color="#ff0000">*</font> 관계 </td>
                                        <td class="table-td-header-align" style="width:30%"> 생년월일</td>
                                        <td class="table-td-header-align"  style="width:10%" ><font color="#ff0000">*</font> 증상유무</td>
                                        <td class="table-td-header-align"  style="width:15%" > 비고</td>
                                    </tr>
                                    </table>
                                    </td>
                                </tr>
                                </thead>
                                <tbody>
                                <!-- Start with this.family iteration -->
                                <tr v-for="(row,idx) in this.family" ref="collection" v-bind:value="idx" v-bind:key="idx" class="tr-row-center" >
                                    <td class="table-td-header-align">
                                    <table class="table-noborder" style="width:100%" >
                                    <tr>
                                        <!-- Start with family td 1-->
                                        <td class="table-td-align" style="width:10%;vertical-align:middle" rowspan="3">
                                            <div v-if="this.get_CRUDENV_mode()!= 'UPDATE' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'">
                                                {{idx+1}}
                                                <input type="checkbox" :value="idx"
                                                    name="chk_familiy_checker" style="width:30px"
                                                    v-model="this.family[idx].checked"
                                                    v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                                />
                                            </div>
                                            <div class="d-print-none" v-else >
                                                <button href="#"
                                                    class="btn btn-primary btn-sm btn-search-action"
                                                    id="btn_family_patch"
                                                    @click.self.prevent="patch_family_obj(idx)"
                                                    v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' "
                                                >
                                                수정
                                                </button> &nbsp;
                                                <button href="#"
                                                    class="btn btn-primary btn-sm btn-search-action"
                                                    id="btn_family_delete"
                                                    @click.self.prevent="delete_family_obj(idx)"
                                                    v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL'"
                                                >
                                                삭제
                                                </button>


                                            </div>
                                            <div class="d-none d-print-block">
                                                {{idx+1}}
                                            </div>
                                        </td>
                                        <!-- End with family checker td 1-->
                                        <!-- Start with family custom columns td -->
                                        <td class="table-td-align" colspan="5" style="width:90%">

                                        <table class="table-noborder" style="width:100%" >
                                        <tr>
                                            <td class="table-td-align" style="width:20%">
                                                <input type="text" placeholder="가족이름" id="text_family_name"
                                                    name="text_family_name"
                                                    v-model ="this.family[idx].text_family_name"
                                                    class="form-control-sm input-search"
                                                    v-bind:ref ="this.$concat_str('text_family_name_',idx)"
                                                    style="width:100px;height:20px"
                                                    :required="this.require_validation[1]"
                                                    v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'" />
                                            </td>
                                            <td class="table-td-align" style="width:40%" >
                                                <select id="sel_family_relation" v-model="this.family[idx].sel_family_relation"
                                                    name="sel_family_relation" class="form-select-sm select-search"  style="width:150px"
                                                    :data-key="idx"
                                                    v-bind:ref ="this.$concat_str('sel_family_relation_',idx)"
                                                    :required="this.require_validation[1]" @change="this.onFamilySexChange($event,idx)"
                                                    v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"  >
                                                        <option v-for="option in this.filteredFamilyRelationOptions(idx)" :value="option" :key="option">{{ this.convertFamilyRelationOptions(option)}} </option>

                                                </select>
                                                
                                                {{ this.family[idx].sel_family_sex }}
                                            </td>

                                            <td class="table-td-align" style="width:30%" >
                                                <input
                                                    v-model="this.family[idx].text_family_birthday"
                                                    type="date"
                                                    id="text_family_birthday"
                                                    name="text_family_birthday" style="width:200px;height:26px"
                                                    :data-key="idx"
                                                    v-bind:ref ="this.$concat_str('text_family_birthday_',idx)"
                                                    :max="store.getters['constStore/getMaxDate']"
                                                    v-on:input="this.show_family_uploadfiles(idx)"
                                                    v-on:select="this.show_family_uploadfiles(idx)"
                                                    v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                                /><br/>
                                                
                                            </td>

                                            <td class="table-td-align" style="width:10%">
                                                <div class="btn-toolbar justify-content-left" role="toolbar" aria-label="Toolbar with button groups">
                                                    <select id="sel_family_sympton"
                                                        v-model="this.family[idx].sel_family_sympton"
                                                        name="sel_family_sympton" class="form-select-sm select-search"
                                                        style="width:100px"
                                                        v-bind:ref ="this.$concat_str('sel_family_sympton_',idx)"
                                                        v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                                        :data-key="idx"
                                                    >
                                                    <option value="" selected >-</option>
                                                    <option value="Y"  >Yes</option>
                                                    <option value="N"  >No </option>
                                                    <option value="NA"  >NA</option>
                                                    </select>

                                                </div>
                                            </td>

                                            <td class="table-td-align" style="width:10%">

                                                <input type="text"
                                                    placeholder="비고"
                                                    id="text_family_etc"
                                                    name="text_family_etc"
                                                    v-model ="this.family[idx].text_family_etc"
                                                    v-bind:ref ="this.$concat_str('text_family_etc',idx)"
                                                    :data-key="idx"
                                                    class="form-control-sm input-search" style="width:160px;height:20px"
                                                    v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'" />
                                            </td>
                                        </tr>
                                        </table>

                                        </td>
                                    </tr>
                                    <!-- End with family custom columns td -->

                                    <!--  가족 업로드 파일  입력 부분 -->
                                    <tr class="tr-row-center d-print-none">
                                        <td class="table-td-align" colspan ="4">
                                            <!-- 필수 동의서 업로드 -->
                                            <div style="display:table; width:100%; float: left; background:white;">

                                                <div style="display:table-row;">
                                                    <div style="margin:10px; width:50%; float:left; top:20px;">
                                                        <div style="display:table-row;text-align:center" >
                                                            <b style="margin: 10px; font-size:12px;">필수 동의서 파일 <br/><br/></b>
                                                        </div>
                                                        <div style="display:table-row" v-show="this.family[idx].age ==''">
                                                            [가구원의 <font color='#ff0000'>나이</font>에 따라 업로드할 필수 동의서 종류가 달라집니다.] <br/>
                                                            가구원의 나이는 <font color='#ff0000'>생년월일</font>과 PART1 <font color='#ff0000'>검사 처방일 </font>에 의해 결정되며, <br/>
                                                            미입력시 성인에 해당하는 동의서 파일이 리스트 업됩니다. <br/><br/>
                                                        </div>
                                                        <div style="display:table-row" v-show="this.family[idx].age !=''" >
                                                            <b> 나이: {{ this.family[idx].age }}</b> <b> 유형: <font color="0000ff">{{ this.family[idx].age_type }} </font></b>
                                                        </div>
                                                        <div style="display:table-row" v-if="this.family[idx].age !=''">
                                                            <table class="table-noborder" style="width:100%"  >
                                                            <tbody>
                                                            <tr v-for="(frow,fidx) in this.$pb_essential_files[this.family[idx].age_type]" ref="uploadable_files"
                                                                v-bind:value="fidx" v-bind:key="fidx" class="tr-row-center"  >

                                                                <td class="table-td-align" style="width:40%;text-align:left"  v-show="frow">
                                                                     <font color="ff0000"  >* </font> {{this.$pb_upload_filenames[fidx]}}
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                            </table>

                                                        </div>
                                                        <div style="display:table-row" v-else>
                                                            <table class="table-noborder" style="width:100%">
                                                            <tbody>
                                                            <tr v-for="(frow,fidx) in this.$family_essential_files" ref="uploadable_files" v-bind:value="fidx" v-bind:key="fidx" class="tr-row-center"  >
                                                                <td class="table-td-align" style="width:40%;text-align:left"  v-show="frow">
                                                                     <font color="ff0000"  >* </font> {{this.$family_upload_filenames[fidx]}}
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                            </table>

                                                        </div>
                                                    </div>


                                                    <div style="margin:10px; height:190px; width:45%; float:left;">
                                                        <div class="file-upload-container"
                                                            @dragenter="fa_reqfiles_onDragenter"
                                                            @dragover="fa_reqfiles_onDragover"
                                                            @dragleave="fa_reqfiles_onDragleave"

                                                            @drop="fa_reqfiles_onDrop(idx)"
                                                            @click="fa_reqfiles_onClick(idx)"
                                                            style="width:100%; height:80%;"
                                                            >
                                                            <div class="file-upload" :class="this.is_files_dragged ? 'dragged' : ''">
                                                                Drag & Drop<br>Files
                                                            </div>
                                                            <div style="float:left; width:80%; height:100%; vertical-align: middle; padding-left: 10px; background: white;">
                                                                <!-- 업로드된 리스트 -->
                                                                <div class="file-upload-list" style="width:100%; height:100%; overflow:auto; background:white;">
                                                                    <div class="file-upload-list__item" v-for="(file, fidx) in this.family[idx].reqfiles" :key="fidx" style="padding-top: 10px;">
                                                                        <div class="file-upload-list__item__data">
                                                                            <div class="file-upload-list__item__data-name" style="float: left; width: 260px; display: block; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; margin-right:5px; color:black;"  @click.stop="" @click="this.on_download_file(file.file_path, file.name)">
                                                                                {{ file.name }}
                                                                            </div>
                                                                            <div class="file-upload-list-delete-btn" @click.stop="" @click="fa_reqfiles_onHandleRemove(idx,fidx)" style="float:left;"
                                                                            v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL'">
                                                                                삭제
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style="margin: 10px; height: 40px; width: 45%; float: left;" >
                                                            <!-- 파일 업로드 -->
                                                            <input type="file"
                                                                v-bind:ref="this.$concat_str('fa_reqfiles_Input',idx)"
                                                                class="file-upload-input"
                                                                @change="fa_reqfiles_onChange(idx)"
                                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL'"
                                                                multiple>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <!-- 선택 파일 업로드 -->

                                        </td>
                                    </tr>

                                    </table>
                                    </td>
                                </tr>
                                
                                <tr v-if="this.get_CRUDENV_mode()== 'UPDATE' || this.get_CRUDENV_mode() == 'DETAIL'" class="d-print-none">
                                    <td>
                                        <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_family_add"
                                            v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL'"
                                            @click.self.prevent="add_family_db()"
                                        >
                                        가족 구성원 추가
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- End of accordion-body for patient_family_info -->
                    </div>
                    <!-- 끝: 환자 가족 정보 탭 -->
                    <!-- 시작: 임상 정보 탭 -->
                    <div class="accordion-item d-print-none">
                        <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree"
                                aria-expanded="false" aria-controls="panelsStayOpen-collapseThree"
                                v-on:click="toggle_tab_accordian(2)" >
                                PART III 임상 정보
                            </button>
                        </h2>
                        <!-- accordion-body for patient_clinical_info -->
                        <div id="panelsStayOpen-collapseThree" ref="ecrf_accordian"  class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingThree"
                            v-bind:class="[{show:this.require_validation[2]}]" >
                            <div class="accordion-body">
                                <!-- 시작: 검사 소견 -->
                                <table class="table table-hover " style="width:100%" >
                                <tbody>
                                <tr class="tr-row-center">
                                    <td class="table-td-align" style="width:8%;vertical-align:middle"><b>현병력</b></td>
                                    <td class="table-td-align" style="width:8%;vertical-align:middle"><b>주증상</b></td>
                                    <td class="table-td-align" style="width:84%">
                                        <table class="table table-hover " style="width:100%" >
                                        <tbody>
                                        <tr class="tr-row-center">
                                            <td class="table-td-align" style="width:10%;vertical-align:middle">
                                                <b>Clinical diagnosis</b>
                                            </td>
                                            <td class="table-td-align" style="width:90%">
                                               {{this.pb_obj.pb_diseasename_text}}
                                            </td>
                                        </tr>
                                        <tr class="tr-row-center">
                                            <td class="table-td-align" style="width:10%;vertical-align:middle">
                                                <b>HPO</b>
                                            </td>
                                            <td class="table-td-align" style="width:90%">
                                                <table class="table table-hover " style="width:100%" >
                                                <tbody>
                                                <tr v-for="(row,idx) in this.pb_obj.sel_pb_diseasename_hpo" ref="pb_obj_diseasename_hpo" v-bind:value="idx" v-bind:key="idx"   >
                                                    <td class="table-td-align" style="width:90%">
                                                         <span variant="danger" class="warn-disp" >
                                                            {{  this.$abbreviate(row['value'],100) }}
                                                        </span>
                                                    </td>
                                                </tr>
                                                </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr class="tr-row-center">
                                            <td class="table-td-align" style="width:10%;vertical-align:middle">
                                                <b>OMIM</b>
                                            </td>
                                            <td class="table-td-align" style="width:90%">
                                                <table class="table table-hover " style="width:100%" >
                                                <tbody>
                                                <tr v-for="(row,idx) in this.pb_obj.sel_pb_diseasename_omim" ref="pb_obj_diseasename_omim" v-bind:value="idx" v-bind:key="idx"   >
                                                    <td class="table-td-align" style="width:90%">
                                                         <span variant="danger" class="warn-disp" >
                                                            {{ this.$abbreviate(row['value'],100)}}
                                                        </span>
                                                    </td>
                                                </tr>
                                                </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr class="tr-row-center">
                                    <td class="table-td-align" style="width:8%;vertical-align:middle"><b>가족력</b></td>
                                    <td class="table-td-align" style="width:12%;vertical-align:middle"><b>이환된 가족 구성원</b></td>
                                    <td class="table-td-align" style="width:80%">
                                        <table class="table table-hover " style="width:100%" >
                                        <tbody>
                                        <tr class="tr-row-center">
                                            <td class="table-td-align" style="width:90%" >
                                                <table class="table table-hover " style="width:100%" >
                                                <tr class="tr-row-center">
                                                    <td class="table-td-align" style="width:15%;vertical-align:middle">
                                                        성함
                                                    </td>
                                                    <td class="table-td-align" style="width:15%;vertical-align:middle">
                                                        관계
                                                    </td>
                                                    <td class="table-td-align" style="width:10%;vertical-align:middle">
                                                        성별
                                                    </td>
                                                    <td class="table-td-align" style="width:15%;vertical-align:middle">
                                                        생일
                                                    </td>
                                                    <td class="table-td-align" style="width:10%;vertical-align:middle">
                                                        증상
                                                    </td>
                                                    <td class="table-td-align" style="width:35%;vertical-align:middle">
                                                        비고
                                                    </td>
                                                </tr>
                                                </table>

                                            </td>
                                        </tr>
                                        <tr v-for="(row,idx) in this.family" ref="family_sel_family_symtoms" v-bind:value="idx" v-bind:key="idx"   >

                                            <td class="table-td-align" style="width:90%" > <!-- v-if="row['sel_family_sympton']!=''"-->
                                                <table class="table table-hover " style="width:100%" >
                                                <tr class="tr-row-center">
                                                    <td class="table-td-align" style="width:15%;vertical-align:middle">
                                                        {{row['text_family_name']}}
                                                    </td>
                                                    <td class="table-td-align" style="width:15%;vertical-align:middle">
                                                        {{this.getFamilyRelationship(row['sel_family_relation'])}}
                                                    </td>
                                                    <td class="table-td-align" style="width:10%;vertical-align:middle">
                                                        {{row['sel_family_sex']}}
                                                    </td>
                                                    <td class="table-td-align" style="width:15%;vertical-align:middle">
                                                        {{row['text_family_birthday']}}
                                                    </td>
                                                    <td class="table-td-align" style="width:10%;vertical-align:middle">
                                                         {{row['sel_family_sympton']}}
                                                    </td>
                                                    <td class="table-td-align" style="width:35%;vertical-align:middle">
                                                        {{row['text_family_etc']}}
                                                    </td>
                                                </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr class="tr-row-center">
                                    <td class="table-td-align" style="vertical-align:middle;text-align:center" rowspan="4"><b>검사소견</b></td>
                                    <td class="table-td-align" style="vertical-align:middle;text-align:center"><b>영상의학검사</b></td>
                                    <td class="table-td-align" style="">
                                        <table class="table table-hover " style="width:100%" >
                                        <tbody>
                                        <tr class="tr-row-center">

                                            <td class="table-td-align" style="width:100%;vertical-align:top;text-align:left">
                                                <textarea id="ecrf_obj_imgscr" class="md-textarea form-control" rows="6" style="width:100%;font-size:12px"
                                                v-model = "this.ecrf_obj.imgscr"
                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL'"
                                                ></textarea>
                                            </td>
                                        </tr>
                                        </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr class="tr-row-center">
                                    <td class="table-td-align" style="vertical-align:middle;text-align:center"><b>조직 검사</b></td>
                                    <td class="table-td-align" style="">
                                        <table class="table table-hover " style="width:100%" >
                                        <tbody>
                                        <tr class="tr-row-center">
                                            <td class="table-td-align" style="width:100%;vertical-align:top;text-align:left">
                                                <textarea id="ecrf_obj_org" class="md-textarea form-control" rows="6" style="width:100%;font-size:12px"
                                                v-model = "this.ecrf_obj.org"
                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL'"
                                                ></textarea>
                                            </td>
                                        </tr>
                                        </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr class="tr-row-center">
                                    <td class="table-td-align" style="vertical-align:middle;text-align:center"><b>혈액 검사 </b><br>(특수생화학검사 <br> 포함)</td>
                                    <td class="table-td-align" style="">
                                        <table class="table table-hover " style="width:100%" >
                                        <tbody>
                                        <tr class="tr-row-center">
                                            <td class="table-td-align" style="width:100%;vertical-align:top;text-align:left">
                                                <textarea id="ecrf_obj_blood" class="md-textarea form-control" rows="6" style="width:100%;font-size:12px"
                                                v-model = "this.ecrf_obj.blood"
                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL'"
                                                ></textarea>
                                            </td>
                                        </tr>
                                        </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr class="tr-row-center">
                                    <td class="table-td-align" style="vertical-align:middle;text-align:center"><b>유전자 검사</b></td>
                                    <td class="table-td-align" style="">
                                        <table class="table table-hover " style="width:100%" >
                                        <tbody>
                                        <tr class="tr-row-center">
                                            <td class="table-td-align" style="width:100%;vertical-align:top;text-align:left">
                                                <textarea id="ecrf_obj_genescreen" class="md-textarea form-control" rows="6" style="width:100%;font-size:12px"
                                                v-model = "this.ecrf_obj.genescreen"
                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL'"
                                                ></textarea>
                                            </td>
                                        </tr>
                                        </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr class ="tr-row-center d-print-none" >
                                    <td class="table-td-align" colspan="3" >
                                        <button href="#" class="btn btn-primary btn-sm btn-search-action"
                                            id="btn_ecrf_save_temp"
                                            v-if="this.get_CRUDENV_mode()!='UPDATE' "
                                            @click.self.prevent="store_temp_ecrf_info()"
                                            v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER' "
                                        >임시저장
                                        </button> &nbsp; &nbsp;
                                        <button href="#" class="btn btn-primary btn-sm btn-search-action"
                                            id="btn_ecrf_delete_temp"
                                            v-if="this.get_CRUDENV_mode()!='UPDATE' "
                                            @click.self.prevent="delete_temp_ecrf_info()"
                                            v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER' "
                                        >임시삭제
                                        </button> &nbsp; &nbsp;
                                        <button href="#" class="btn btn-primary btn-sm btn-search-action"
                                            id="btn_db_update_ecrf_obj"
                                            v-if="this.get_CRUDENV_mode()== 'UPDATE' || this.get_CRUDENV_mode()== 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                            @click.self.prevent="this.patch_ecrf_obj()"
                                            v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL'"
                                        >
                                        PART III 임상 정보 수정 (DB 반영)
                                        </button> &nbsp; &nbsp;
                                    </td>
                                </tr>
                                </tbody>
                                </table>

                                <!-- 끝: 검사 소견 -->

                            </div>
                        </div>
                        <!-- End of accordion-body for patient_clinical_info -->
                    </div>
                    <!-- 끝: 임상 정보 탭 -->

                    <!-- 시작: 담당자 정보 탭 시작 -->
                    <div class="accordion-item d-print-none">
                        <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour"
                            v-on:click="toggle_tab_accordian(3)"
                            ref='tab_contact_sms'
                            >
                            PART IV 담당자 정보
                            </button>
                        </h2>
                        <!-- accordion-body for additional sms  -->
                        <div id="panelsStayOpen-collapseFour" ref="contact_sms_accordian" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour"
                            v-bind:class="[{show:this.require_validation[3]}]"
                        >
                            <div class="accordion-body">
                                <table class="table table-hover " style="width:100%" >
                                <tbody>


                                    <tr class="tr-row-center">
                                        <td class="table-td-align" > 의뢰의사 정보</td>
                                        <td class="table-td-align" v-if="this.get_CRUDENV_mode()== 'UPDATE' || this.get_CRUDENV_mode()== 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'">
                                            {{this.pb_obj.writername}}
                                        </td>
                                        <td class="table-td-align" v-else >  {{store.getters['userStore/getUserDoctorName']}}
                                            </td>
                                        <td class="table-td-align"  colspan="2" v-if="this.get_CRUDENV_mode()== 'UPDATE' || this.get_CRUDENV_mode()== 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'">
                                            전화번호
                                            {{this.pb_obj.phone_number}}
                                        </td>
                                        <td class="table-td-align" colspan="2" v-else >
                                            전화번호
                                            {{store.getters['userStore/getUserPhoneNumber']}}
                                        </td>
                                    </tr>
                                    <tr class="tr-row-center">
                                        <td class="table-td-align" >전문과목</td>
                                        <td class="table-td-align" colspan="3" v-if="this.get_CRUDENV_mode()== 'UPDATE' || this.get_CRUDENV_mode()== 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'" >
                                            {{this.pb_obj.doctormajor}}
                                        </td>
                                        <td class="table-td-align" colspan="3" v-else>
                                            {{store.getters['userStore/getUserDoctorMajor']}}
                                        </td>
                                    </tr>
                                    <tr class="tr-row-center">

                                        <td class="table-td-align" colspan="4" >
                                            * 본 양식을 작성하였거나 연락 가능한 담당자의 성명, 핸드폰, Email 주소를 기재해주시면 감사하겠습니다. <br>
                                            &nbsp; &nbsp; 해당 담당자에게 핸드폰과 Email로 검사완료, 접수취소, 보완요청, 검사불가능 등의 내용이 발송됩니다.
                                        </td>
                                    </tr>
                                    <tr class="tr-row-center">
                                        <td class="table-td-align" > 담당의사 정보</td>
                                        <td class="table-td-align" > <font color='#ff0000'>*</font> 성명
                                            <input type="text" placeholder="성명"
                                            id="text_sms_request_doc_name" name="text_sms_request_doc_name"
                                            class="form-control-sm input-search"
                                            ref="text_sms_request_doc_name"
                                            v-model="this.sms_obj.text_sms_request_doc_name"
                                            v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL'|| this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                            style="height:20px" /> </td>
                                        <td class="table-td-align"  ><font color='#ff0000'>*</font> 전화번호
                                            <input type="text" id="text_sms_request_doc_phonenumber"
                                                name="text_sms_request_doc_phonenumber"
                                                class="form-control-sm input-search"
                                                v-model="this.sms_obj.text_sms_request_doc_phonenumber"  style="height:20px" maxlength="13"
                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL'|| this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                                ref='text_sms_request_doc_phonenumber' />

                                            <span variant="danger"
                                                class="error-disp" v-if="!is_valid_sms_obj_field('text_sms_request_doc_phonenumber')">
                                                양식 EX: 01011111111
                                            </span>
                                        </td>
                                        <td class="table-td-align"  ><font color='#ff0000'>*</font> Email
                                            <input type="text" placeholder="rare@ccrdp.org"
                                                    id="text_sms_request_doc_email"
                                                    name="text_sms_request_doc_email"
                                                    v-model="this.sms_obj.text_sms_request_doc_email"
                                                    maxlength="100"

                                                    class="form-control-sm input-search"
                                                    ref = "text_sms_request_doc_email"
                                                    style="width: 200px;height:20px"
                                                    v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL'|| this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                                    :required="require_validation" />

                                                <span variant="danger"
                                                    class="error-disp" v-if="!is_valid_sms_obj_field('text_sms_request_doc_email')">
                                                    양식 EX: rare@ccrdp.org
                                                </span>
                                        </td>
                                    </tr>
                                    <tr class="tr-row-center">
                                        <td class="table-td-align" > 담당자 정보</td>
                                        <td class="table-td-align" > <font color='#ff0000'>*</font> 성명
                                            <input type="text" placeholder="성명"
                                            id="text_sms_stakeholder_name" name="text_sms_stakeholder_name"
                                            class="form-control-sm input-search"
                                            ref="text_sms_stakeholder_name"
                                            v-model="this.sms_obj.text_sms_stakeholder_name"
                                            v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                            style="height:20px" /> </td>
                                        <td class="table-td-align"  ><font color='#ff0000'>*</font> 전화번호
                                            <input type="text" id="text_sms_stakeholder_phonenumber"
                                                name="text_sms_stakeholder_phonenumber"
                                                class="form-control-sm input-search"
                                                v-model="this.sms_obj.text_sms_stakeholder_phonenumber"  style="height:20px" maxlength="13"
                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                                ref='text_sms_stakeholder_phonenumber' />

                                            <span variant="danger"
                                                class="error-disp" v-if="!is_valid_sms_obj_field('text_sms_stakeholder_phonenumber')">
                                                양식 EX: 01011111111
                                            </span>
                                        </td>
                                        <td class="table-td-align"  ><font color='#ff0000'>*</font> Email
                                            <input type="text" placeholder="rare@ccrdp.org"
                                                id="text_sms_stakeholder_email"
                                                name="text_sms_stakeholder_email"
                                                v-model="this.sms_obj.text_sms_stakeholder_email"
                                                maxlength="100"

                                                class="form-control-sm input-search"
                                                ref = "text_sms_stakeholder_email"
                                                style="width: 200px;height:20px"
                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                                :required="require_validation" />

                                            <span variant="danger"
                                                class="error-disp" v-if="!is_valid_sms_obj_field('text_sms_stakeholder_email')">
                                                양식 EX: rare@ccrdp.org
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class ="tr-row-center" >
                                        <td class="table-td-align" colspan="7" >
                                            <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_doctor_add_sms" @click.self.prevent="add_stakeholder_obj()"
                                            v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'">추가</button> &nbsp; &nbsp;
                                            <button href="#" class="btn btn-primary btn-sm btn-search-action" id="btn_doctor_delete_sms" @click.self.prevent="remove_stakeholder_obj()"
                                            v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'">삭제 </button>
                                        </td>
                                    </tr>
                                    <tr class ="tr-row-center" >
                                        <td class="table-td-align" rowspan="1" colspan="2">SMS 추가<br>수신번호</td>
                                        <td class="table-td-align" colspan="6"  style="align-items:center;text-align: center">
                                        <table class="table table-hover table-border" style="width:100%">
                                        <th class ="tr-row-center">

                                            <td class="table-td-align" colspan="2" >
                                            수신자 번호
                                            </td>

                                        </th>
                                        <tbody>

                                            <tr v-for="(row,idx) in this.stakeholder_obj"  v-bind:value="idx" v-bind:key="idx" class="tr-row-center" >
                                                <td class="table-td-align"  style="width:100px">
                                                <input type="checkbox"
                                                    id="chk_doctor_add_wholephonenumber"
                                                    name="chk_doctor_add_wholephonenumber"
                                                    v-model="this.stakeholder_obj[idx].checked"
                                                    v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                                />
                                                </td>
                                                <td class="table-td-align" style="width:400px" >
                                                <input type="text" placeholder="010-0000-0000"
                                                    id="text_stakeholder_sms_number"
                                                    name="text_stakeholder_sms_number"
                                                    v-model="this.stakeholder_obj[idx].text_stakeholder_sms_number"
                                                    maxlength="13"

                                                    class="form-control-sm input-search"
                                                    v-bind:ref = "this.$concat_str('text_stakeholder_sms_number_',idx)"
                                                    style="width: 200px;height:20px"
                                                    v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                                    :required="require_validation" />
                                                <span variant="danger"
                                                    class="error-disp" v-if="!is_valid_stakeholder_field(idx,'text_stakeholder_sms_number')">
                                                    양식 EX: 01011111111
                                                </span>
                                                &nbsp; &nbsp;
                                                <input type="text" placeholder="rare@ccrdp.org"
                                                    id="text_stakeholder_email"
                                                    name="text_stakeholder_email"
                                                    v-model="this.stakeholder_obj[idx].text_stakeholder_email"
                                                    maxlength="100"

                                                    class="form-control-sm input-search"
                                                    v-bind:ref = "this.$concat_str('text_stakeholder_email_',idx)"
                                                    style="width: 200px;height:20px"
                                                    v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                                    :required="require_validation" />


                                                <span variant="danger"
                                                    class="error-disp" v-if="!is_valid_stakeholder_field(idx,'text_stakeholder_email')">
                                                    양식 EX: rare@ccrdp.org
                                                </span>
                                                </td>

                                            </tr>
                                        </tbody>
                                        </table>
                                        </td>
                                    </tr>
                                    <tr class ="tr-row-center" >
                                        <td class="table-td-align" colspan="7" >
                                            <button href="#" class="btn btn-primary btn-sm btn-search-action"
                                                v-if="this.get_CRUDENV_mode()!='UPDATE' "
                                                id="btn_doctor_save_temp" @click.self.prevent="store_temp_sms_obj()"
                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                                >임시저장
                                                </button> &nbsp; &nbsp;
                                            <button href="#" class="btn btn-primary btn-sm btn-search-action"
                                                v-if="this.get_CRUDENV_mode()!='UPDATE' "
                                                id="btn_doctor_delete_temp" @click.self.prevent="delete_temp_sms_obj()"
                                                v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                                >임시삭제
                                            </button> &nbsp; &nbsp;
                                            <button href="#" class="btn btn-primary btn-sm btn-search-action"
                                            id="btn_db_update_sms_obj"
                                            v-if="this.get_CRUDENV_mode()== 'UPDATE' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                            @click.self.prevent="this.patch_sms_obj()"
                                            v-bind:disabled="this.get_CRUDENV_mode() == 'DETAIL' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'"
                                        >
                                        PART IV 담당자 정보 수정 (DB 반영)
                                        </button> &nbsp; &nbsp;
                                        </td>
                                    </tr>
                                </tbody>
                                </table>

                            </div>
                        </div>
                        <!-- End of accordion-body for additional sms  -->

                    </div>
                    <!-- 끝: 담당자 정보 탭 -->
                    <div class="accordion-item d-print-none" style="padding:16px; text-align: right;">
                        <button href="#" class="btn btn-primary btn-lg btn-search-action" id="btn_overview"
                        ref="btn_overview"
                        v-if="this.get_CRUDENV_mode() =='ADD' "
                        v-on:click.self.prevent="overview_data()" style="font-size:16px; float:right;">
                        저장 </button> &nbsp; &nbsp;
                        <div v-if="this.get_CRUDENV_mode() =='ADD'" style="float:right; padding:7px;">환자 리스트에 가서 의뢰전 환자의 진단의뢰요청을 누르셔야 최종 환자의뢰가 됩니다.&nbsp; &nbsp;&nbsp; &nbsp;</div>
                        
                        <button href="#" class="btn btn-primary btn-lg btn-search-action"
                            id="btn_request_screening"
                            ref="btn_request_screening"
                            v-if="this.get_CRUDENV_mode()=='UPDATE'"
                            v-on:click.self.prevent="this.patch_pb_obj_reqstatus('검토중')"
                            style="font-size:16px;"
                            >
                            진단의뢰요청 </button> &nbsp; &nbsp;
                    </div>
                </form>
                <div style="height:50px" >
                    <button href="#" class="btn btn-primary btn-lg btn-search-action d-print-none" style="font-size:16px; float:right;"
                    v-on:click.prevent="this.set_CRUDENV_mode('LIST')" >
                        뒤로가기
                    </button>
                </div>
            </div>

        </div>
        <!-- 끝:새환자 등록--->
        <!-- End of ReqMain.vue template -->
    </div>
</template>

<script>
import { useStore } from 'vuex'
import {mapGetters} from 'vuex'



import { reactive, computed, isProxy, toRaw } from 'vue'
import useVuelidate from '@vuelidate/core'

import { helpers, required} from "@vuelidate/validators";

import Search from '@/views/base/Search.vue';
import base_parser from '@/helper/base_parser.js'

import api from '@/services/base/index.js'
import { useRouter } from 'vue-router'

// 시작: Commom functions
//=====================================================
import {validEmail, validMobile, validDate,validAge, getMaximumDate,getMinimumDate,run_validate_value} from  '@/helper/validator.js'


// EVENT 발생 혹은 받아서 처리 하기 위한 함수
// evt_cmd_[component:SMS,CLI]_[function body] : 지정 컴포넌트에 이벤트를 전달해서 데이터 처리를 함. 지정 컴포넌트 CMD에 영향을 끼침
// evt_proc_[component:SMS,CLI]_[event] : 지정 컴포넌트에서 발생한 이벤트를 받아서 처리하기 위한 함수 지정. 컴포넌트의 EVT에 영향을 받은
// 끝: Commom functions
//=====================================================

//----------------------------------------------------
// comman grammer
// validation 관련 함수들 시작
// is_valid_[json:bp,family,smscontractor,clinic]_[jsonfield]: [json]의 [jsonfield]의 상태 $error 값 상태만 반환
// is_valid_callable_[json:bp,family,smscontractor,clinic]_[jsonfield] : [json]의 [jsonfield] 가 처리가능한 상태인지 반환

// validate_[json:bp,family,smscontractor,clinic]_[jsonfield]: [json]의 [jsonfield]에 대해 touch 후 $error 값 상태 반환
//                                                        : $touch 및 사용자 alert, [jsonfield] , focus 수행됨
// validate_wrap_[json:bp, family,smscontract,clinic] : [json]의 상태 반환
//                                                  : 사용자 alert 실행 안됨
// run_validate_atomic : atomic level의 validation을 위해 $touch를 수행하고,  erorr item에 대해 focus 한다
// run_validate_value  : atomic level의 validation을 위해, validator의 상태값{required,$invalid,$error}을 입력 받고 erorr item에 대해 focus 한다
//----------------------------------

// eslint-disable-next-line
const userStore = 'userStore'


export default {
    components: {
       //'contract-sms': ContractSMS,
       //'clinical-info':ClinicalInfo, // evt_ process

       'screen-search':Search,
    },
    name: 'ReqMain',
    data() {

        // 시작: 1. Interface 관련 변수와 함수들
        //=========================================================

        // 시작: CRUD 관련 환경 변수들
        //------------------------
        // CRUDENV.mode 와 1:1 매핑됨

        let CRUDENV = reactive({
            loading: false,
            // mode:'LIST' // this.$REQ_CRUDMODES[0]
            mode: this.$REQ_CRUDMODES[0]
        });
        // 끝 : CRUD 관련 환경 변수들
        //------------------------

        // 시작: 데이터 및 상태 현황 관리 변수들
        //--------------------------
        // 진단 의뢰 요청 환자 입력 창들의 필수 조건에 대한 상태 저장
        // [0] = Part 1. 기본 정보
        // [1] = Part 2. 가족 정보
        // [2] = Part 3. 임상 정보
        // [3] = Part 4. 담당자 정보
        const require_validation=reactive([true,false,true,true]);
        // 라우터
        const router = useRouter ()
        // SPA 진단 의뢰 환자 요청 데이터(pb_obj) 리스트
        const req_pb_obj_list = reactive([])
        // 끝: 데이터 및 상태 현황 관리 변수들
        //--------------------------
        let checked_req_patient_list = reactive([]);

        // paging 관련
        //=========================================================
        let current_page = 1; // 현재 페이지
        let page_size = 10; // 한번에 표시하는 페이지 단위
        let total_item_cnt = 0; // 총 검색 건 수
        //=========================================================

        let search_requesting_institution_list = reactive([]);

        let sel_area_list = reactive([]);

        // 끝: 1. Interface 관련 변수와 함수들
        //=========================================================



        // 시작: pb_obj
        //----------------------------
        // omim list
        let omim_list = reactive([]);

        let hpo_list = reactive([]);

        let patient_number_list = reactive([]);

        // 시작: ADD data 저장을 위한 변수들
        //================================
        // 기본 환자 정보 저장:  basis_patient , basis_patient_rules, v_pb$
        // 가족 정보 저장: family , family_ruls,  v_family$
        // 추가 담당자 연락처 저장: sms_obj - ContractSMS와 통신을 위해 임시 저장하기 위한 변수
        //--------------------------------

        // dragged
        const is_files_dragged=false;

        // TODO pb_obj
        // basis_patient object for data input
        const pb_obj = reactive({

            text_pb_name: '',// 환자 이름 저장
            text_pb_number: '', // 환자 phonenumber 저장
            sel_pb_sex:'',   // 환자 성별 저장


            age:'',
            age_type:'',

            sel_pb_diseasename_omim:[ // 다중 input box reactive 처리를 위함 임시저장 backend로 데이터 전송은 안됨
                {value:''}
            ],
            sel_pb_diseasename_hpo:[ // 다중 input box reactive 처리를 위함 임시저장 backend로 데이터 전송은 안됨
                {value:''}
            ],


            pb_diseasename_hpo_textnumber:'',
            pb_diseasename_omim:'', //sel_basis_diseasenmae_omim 처리 후 모아서  backend에 전달하기 위한 변수 - validation 점검안함
            pb_diseasename_hpo:'', // sel_basis_diseasenmae_hpo 처리 후 모아서  backend에 전달하기 위한 변수 - validation 점검안함
            pb_diseasename_text:'', // sel_basis_diseasenmae_text 처리 후 모아서  backend에 전달하기 위한 변수 - validation 점검안함

            text_pb_reqreferal_date:'',//병원에 내원해서 진단 받은 날짜

            text_pb_birthday: '',  // 환자 생일 저장


            text_pb_reqdate:'', // 진단 의뢰 요청한 날짜 저장 => 값 있으면 '의뢰대기'

            text_pb_reqstatus:'', // '의뢰대기', '의뢰완료','검사진행중','검사완료'

            text_pb_requested:'', // 진단 의뢰 인지된 날짜 저장 => 값 있으면 '의뢰완료'

            text_pb_reqscreening:'', // 검사 시작 날짜 저장 => 값 있으면 '검사진행중'

            text_pb_reqscreened:'', // 검사 완료된 날자 저장 => 값 있으면 '검사완료'

            result_url:'', // 결과 url

            text_key_clinical_info:'', // 주요 임상 정보

            api_auth_key_1:'', // 3billion Auth Key_1

            api_auth_key_2:'', // 3billion Auth Key_2

            sel_area_code:'', // 지역 코드


            // required agreement files
            reqfiles:[],


            //optional agreement and other files
            optfiles:[],

            // uploadfiles_meta:[{filename:''},{filename:''},{filename:''},{filename:''},{filename:''},{filename:''},{filename:''},{filename:''},{filename:''},{filename:''}], // upload할 파일에 대한 메타 정보들 저장
            // uploadfiles:[null, null,null, null,null,null,null,null,null,null], // INMEMORY에 저장되어 backend에 전달될 실제 파일들

            text_pb_etcopinion:'', // 기타 중요 소견


            text_pb_blooddate: '', // 채혈일 저장
        });

        // basis_patient validation rules
        const pb_rules = computed(() =>{
            return {
                text_pb_name: {required},
                text_pb_number: {required}, //, isBetween:[10,20]},
                sel_pb_sex:{required},
                sel_area_code:{required},

                pb_diseasename_text:{required},


                age:{required,validAge},
                age_type:{required},

                text_pb_birthday:{required, validDate},
                text_pb_reqreferal_date:{required, validDate},


                reqfiles:{
                    $each:{
                        required,
                    }
                },
                text_pb_etcopinion:{}, //기타 중요 소견

                text_pb_blooddate:{validDate}
            };
        });
        // validators declareration for each validation
        const v_pb$ = useVuelidate(pb_rules, pb_obj);

        // 끝: pb_obj
        //----------------------------


        // 시작: pb_reqfiles, pb_opt_files
        //----------------------------
        // Drag & drop files

        // const v_pb_reqfiles$ = useVuelidate(pb_reqfiles_rules,pb_reqfiles);
        // 끝: pb_reqfiles, pb_opt_files
        //----------------------------

        // 시작: family
        //----------------------------

        // const filetype_fp={
        // '0': 'FORM5',
        // '1': 'FORM6',
        // '2': 'FORM7',
        // '3': 'FORM8',
        // '4': 'ETC'
        // }
        // let filetype_fp_idx=Object.keys(filetype_fp).reduce((acc, k) => (acc[filetype_fp[k]] = [...(acc[filetype_fp[k]] || []), k], acc) , {});


        // // 환자 의뢰를 위한 patient basic 업로드 파일명
        // const family_upload_filenames=[
        //     '서식 5. 대상자 설명문 및 동의서(희귀질환자 및 가족)',
        //     '서식 6. 대상자 설명문 및 동의서(희귀질환자 및 가족)- 2차적 데이터 공유 보관용',
        //     '서식 7. 인체유래물 동의서',
        //     '서식 8. 유전자 검사 동의서',
        //     '기타 파일'
        // ];


        // const family_essential_files=reactive([true, true, true, true, false]); //  부,모: [필수 업로드 파일: 5,6,7 - 선택업로드파일: 8 유전자 검사 동의서는 선택]

        // //const family_essential_files=reactive([false, false, false, true, false]); //  부,모: [필수 업로드 파일: 5,6,7 - 선택업로드파일: 8 유전자 검사 동의서는 선택]


        // family object for data input
        const family = reactive(
            [{
                checked:false,
                text_family_name:'',
                sel_family_relation:'',
                // sel_family_generation:'',
                sel_family_sex:'',
                age:'',
                age_type:'',

                reqfiles:[],
                optfiles:[],

                // uploadfiles_meta:[{filename:''},{filename:''},{filename:''},{filename:''},{filename:''}],
                //uploadfiles:[null,null,null,null,null],



                text_family_birthday:'',
                sel_family_sympton:'',
                text_family_etc:'',
                show_uploadfiles:true,

            }]
        )
        // family validation rules

        const family_rules = {

            $each:helpers.forEach({
                checked:{},
                text_family_name:{required},
                sel_family_relation:{required},
                // sel_family_generation:{required},
                sel_family_sex:{required},

                age:{required,validAge},
                age_type:{required},

                reqfiles:{},
                optfiles:{},


                //text_family_birthday:{validDate},
                text_family_birthday:{validDate},
                sel_family_sympton:{required},
                text_family_etc:{},
                show_uploadfiles:{}
            })

        };
        const v_family$ = useVuelidate(family_rules, family);

        const familyRelationAllOptions=["", "부", "모", "형제", "자매","기타남","기타여"]


        // 끝: family
        //----------------------------

        // 시작: ecrf_obj 변수들
        //----------------------------
        // basis_patient object for data input
        const ecrf_obj = reactive({
            imgscr: '',// 영상의학 검사
            org: '', // 조직 검사
            blood:'',   // 혈액검사
            genescreen:'', // 유전자 검사
            // genescreen:[
            //     {symbol:'',desc:''}
            // ], // 유전자 검사
            // uploadfiles_meta:[{filename:''},{filename:''},{filename:''},{filename:''}],
            // uploadfiles:[null,null,null,null],


        })
        const ecrf_rules = computed(()=>{
            return {
                imgscr:{},
                org:{},
                blood:{},
                genescreen: {},
            }
        });

        const v_ecrf$ = useVuelidate(ecrf_rules, ecrf_obj);

        // 끝: ecrf_obj 변수들
        //----------------------------

        // 시작: sms_obj ContractSMS component 활용을 위한 변수들
        //----------------------------
        // contract_sms object for data input
        const sms_obj = reactive({
            text_sms_stakeholder_name:'',
            text_sms_stakeholder_phonenumber:'',
            text_sms_stakeholder_email:'',
            text_sms_request_doc_name:'',
            text_sms_request_doc_phonenumber:'',
            text_sms_request_doc_email:'',


            // text_sms_doctor_name:'',
            // text_sms_doctor_phonenumber:'',
            // text_sms_doctor_major:'',
            // stakeholder:[]
            // stakeholder:[{
                // checked:false,
                // text_stakeholder_sms_number:'',
                // text_stakeholder_email:'',
            //}],
        })

        // contract_sms validation rules


//  every({
//     name: { required },
//     email: { email }
//   })
        const sms_rules = {

            text_sms_stakeholder_name:{required},
            text_sms_stakeholder_phonenumber:{required,validMobile},
            text_sms_stakeholder_email:{required,validEmail},
            text_sms_request_doc_name:{required},
            text_sms_request_doc_phonenumber:{required,validMobile},
            text_sms_request_doc_email:{required,validEmail},
        };
        const v_sms$ = useVuelidate(sms_rules, sms_obj);


        const stakeholder_obj = reactive([{
            checked:false,
            text_stakeholder_sms_number:'',
            text_stakeholder_email:'',
        }])


        const stakeholder_rules = {

            $each:helpers.forEach({
                checked:{},
                id:{},
                text_stakeholder_sms_number:{required, validMobile},
                text_stakeholder_email:{required,validEmail}
            })

        };
        const v_stakeholder_obj$ = useVuelidate(stakeholder_rules, stakeholder_obj);


        let search_data=reactive({
            search_progress:'',
            search_requesting_institution:'',
            search_disease_name:'',
            search_request_doc_name:'',
            search_request_date_start:'',
            search_request_date_end:'',
            search_request_patient_name:'',
            //search_screened_date_start:'',  // 결과 보고일 시작
            // search_screened_date_end:''  // 결과 보고일 끝
        })

        // 끝: sms_obj ContractSMS component 활용을 위한 변수들
        //----------------------------


        // 시작: Update 를 위한 변수들
        //----------------------------

        // hcode 중복 검사 수행을 위해, 임시로 저장함
        // getRequestedPatient 함수에서 설정됨
        const old_text_pb_number = ''

        // 끝: Update 를 위한 변수들
        //----------------------------

        return {
            search_requesting_institution_list,
            sel_area_list,
            current_page,
            page_size,
            total_item_cnt,
            req_pb_obj_list,
            CRUDENV,
            router,
            require_validation,
            pb_obj,
            v_pb$,
            family,
            v_family$,
            familyRelationAllOptions,
            omim_list,
            hpo_list,
            checked_req_patient_list,
            patient_number_list,
            // pb_upload_filenames,pb_essential_files,
            // family_upload_filenames,  family_essential_files,
            // pb_reqfiles, v_pb_reqfiles$, pb_optfiles,
            is_files_dragged,
            sms_obj,
            v_sms$,
            stakeholder_obj,
            v_stakeholder_obj$,
            ecrf_obj,
            v_ecrf$,
            old_text_pb_number,
            search_data
        }

    },
    computed : {
        ...mapGetters(userStore,['isLogin']),
        isLoggedIn : function(){
            // alert('isLogin:'+this.isLogin)
            return this.isLogin
        },

    },
    methods: {
        
        download_excel(){
            const XLSX = require('xlsx');
            // Acquire Data (reference to the HTML table)
            var table_elt = document.getElementById("request_patient_tbl");

            // Extract Data (create a workbook object from the table)
            var workbook = XLSX.utils.table_to_book(table_elt);

            // Process Data (add a new row)
            var ws = workbook.Sheets["의뢰 환자 리스트"];
            XLSX.utils.sheet_add_aoa(ws, [["Created "+new Date().toISOString()]], {origin:-1});

            // Package and Release Data (`writeFile` tries to write and save an XLSB file)
            XLSX.writeFile(workbook, "Request_patient_list.xlsx");
        },
        inquiry_reuslt(result_url){
            window.open(result_url);
        },
        move_page(target_page){
            this.current_page = target_page;
            // this.set_CRUDENV_mode('LIST');
            this.set_CRUDENV_mode(this.$REQ_CRUDMODES[0]);
        },
        // 시작: 1. 환경 설정 및 Interface 동작 관련 함수들
        //================================================
        set_CRUDENV_loading:function(_loading){
            this.CRUDENV.loading = _loading;
        },
        is_CRUDENV_loading:function(){
            return this.CRUDENV.loading;
        },
        get_CRUDENV_mode:function(){
            return this.CRUDENV.mode;
        },
        search_list:function(){
            this.current_page = 1;
            // this.set_CRUDENV_mode('LIST')
            this.set_CRUDENV_mode(this.$REQ_CRUDMODES[0]);
        },
        // relation 형재, 자매 -> '남자형제','여자형제'
        getFamilyRelationship(_relation){
            var ret_value = _relation
            if (_relation=='형제'){
                ret_value='남자형제'
            }
            if (_relation =='자매'){
                ret_value='여자형제'
            }
            return ret_value

        },
        // 사용자가 입력한 search data set 을 저장한다.
        async construct_search_data(){

            
            let fields = this.$refs['ref_search'].get_fields()
            if (isProxy(fields)){
                fields = toRaw(fields)
            }



            this.search_data['search_progress'] = fields.search_progress
            this.search_data['search_requesting_institution'] = fields.search_requesting_institution
            this.search_data['search_disease_name'] = fields.search_disease_name

            this.search_data['search_request_doc_name'] = fields.search_request_doc_name
            this.search_data['search_request_date_start'] =fields.search_request_date_start

            this.search_data['search_request_date_end'] = fields.search_request_date_end
            this.search_data['search_request_patient_name'] = fields.search_request_patient_name

            //this.search_data['search_screened_date_start'] = fields.search_screened_date_start
            //this.search_data['search_screened_date_end'] = fields.search_screened_date_end

        },
        get_search_data(){
            return this.search_data
        },
        // CRUDENV mode 설정
        async set_CRUDENV_mode(_mode,_patient_id){
            // CRUDENV.mode 와 1:1 매핑됨
          
            // Vue.config.globalProperties.$REQ_CRUDMODES=['LIST','ADD','UPDATE','DELETE','DETAIL','UPDATEBYMANAGER'];
            // let CRUDENV = reactive({
            //     loading: false,
            //     mode:['true','false','false','false']
            // });
            // 다른 CRUD 모드를 위해 this.pb_obj.id 를 Reset 한다
            this.pb_obj.id = '';

            // alert ("mode:"+_mode+",isManager():"+this.isManager())

            if (this.is_CRUDENV_loading()){
                alert ('데이터 프로세싱이 처리 중입니다')
                return false;
            }

            if (this.$REQ_CRUDMODES.includes(_mode)){
                this.CRUDENV.mode = _mode

                // if (_mode == 'LIST'){ //'LIST'
                if (_mode == this.$REQ_CRUDMODES[0]){ //'LIST'
                    const userEmail = this.store.getters['userStore/getUserEmail']

                    this.set_CRUDENV_loading(true);

                    // 검색 데이터 구성
                    this.construct_search_data()
                    
                    
                    // 시작: 등록된 환자 리스트 만들기 (from backend)
                    //--------------------------------------------------------
                    api.getRequestedPatients(userEmail, this.current_page, this.page_size, "REQUEST",  this.get_search_data())
                    .then(response =>{
                        //console.log('mounted:function after api.getRequestedPatient (response)'+JSON.stringify(response))
                        this.req_pb_obj_list = Object.freeze(response.data)
                        this.patient_number_list = [];
                        for (let i = 0; i < response.data.length; i++) {
                            this.patient_number_list.push(response.data[i].pb_obj.text_pb_number)
                        }
                        this.current_page = response['page_info']['current_page'];
                        this.total_item_cnt = response['page_info']['total_item_cnt'];

                        api.getHospitalList()
                        //axios.get('/hospital/') //,axios_config)
                        .then(response => {
                            // this.user.hospital=null;
                            this.search_requesting_institution_list = response.data.map( d => ({
                                hcode: d.hcode,
                                id:d.id,
                                name:d.name,
                                title:d.name
                            }))
                        }).catch(err=>{
                            console.log(err)
                        })

                        this.set_CRUDENV_loading(false);
                    }).catch(err =>{
                        console.log(err)
                    })

                    //console.log('this.req_pb_obj_list:'+ JSON.stringify( this.req_pb_obj_list ))
                    // 끝: 등록된 환자 리스트 만들기 (from backend)
                    //--------------------------------------------------------

                }
                else if (_mode == this.$REQ_CRUDMODES[1]){ //'ADD'
                // localStorage에 저장된 데이터를 가져온다
                // load pb_info if the temporally stored data exist
                    this.set_CRUDENV_loading(true);
                    //--------------------------------------------------------

                    var temp_basis_info = localStorage.getItem('pb_obj_info');

                    this.initialize_pb_as_values(temp_basis_info)



                    // load family info if the temporally stored data exist
                    var temp_family_info = localStorage.getItem('family_info')
                    this.initialize_family_as_values(temp_family_info)

                    var temp_ecrf_info = localStorage.getItem('ecrf_info')
                    this.initialize_ecrf_as_values(temp_ecrf_info)

                    var temp_sms_obj_info = localStorage.getItem('sms_obj')
                    this.initialize_sms_obj_as_values(temp_sms_obj_info)


                    var temp_stakeholder_obj_info = localStorage.getItem('stakeholder_obj')
                    this.initialize_stakeholder_as_values(temp_stakeholder_obj_info)

                    api.getAreaList()
                        .then(response => {
                            // this.user.hospital=null;
                            this.sel_area_list = response.data.map( d => ({
                                id: d.id,
                                code: d.code,
                                name:d.name
                            }))
                        }).catch(err=>{
                            console.log(err)
                        })

                    // close whole input interface
                    this.close_whole_accordion()
                    this.set_CRUDENV_loading(false);

                    return true;
                }
                //else if (_mode == 'UPDATE'){ //'UPDATE'
                else if (_mode == this.$REQ_CRUDMODES[2] || _mode == this.$REQ_CRUDMODES[5]){ // 'UPDATE' or  'UPDATEBYMANAGER'
                    // alert ('CRUDMODE:' + _mode +", _pateint_id:"+_patient_id);
                    this.set_CRUDENV_loading(true);
                    this.getRequestedPatient(_patient_id);
                    // FOR UPDATE
                    this.pb_obj.id = _patient_id

                    api.getAreaList()
                        .then(response => {
                            // this.user.hospital=null;
                            this.sel_area_list = response.data.map( d => ({
                                id: d.id,
                                code: d.code,
                                name:d.name
                            }))
                        }).catch(err=>{
                            console.log(err)
                        })

                    // open whole input interface
                    this.open_whole_accordion()
                    this.set_CRUDENV_loading(false);
                }
                // else if (_mode == 'DETAIL'){ //'DETAIL'
                else if (_mode == this.$REQ_CRUDMODES[4]){  // 'DETAIL'
                    //alert ('CRUDMODE:' + _mode);
                    this.set_CRUDENV_loading(true);
                    this.getRequestedPatient(_patient_id);

                    api.getAreaList()
                        .then(response => {
                            // this.user.hospital=null;
                            this.sel_area_list = response.data.map( d => ({
                                id: d.id,
                                code: d.code,
                                name:d.name
                            }))
                        }).catch(err=>{
                            console.log(err)
                        })


                    // FOR UPDATE
                    this.pb_obj.id = _patient_id

                    // open whole input interface
                    this.open_whole_accordion()
                    this.set_CRUDENV_loading(false);
                }
                

            }
            else{
                return false;
            }

            return true;

        },
        isWriter(_writer_email){
            // console.log ('pb_obj.writer:'+ _writer_email +", userStore/getUserEmail:" + this.store.getters['userStore/getUserEmail'])
            return _writer_email == this.store.getters['userStore/getUserEmail']

        },
        isManager(){
            return this.store.getters['userStore/isManager'];

        },
        getToday(){
            return getMaximumDate();
        },
        getMinDate(){
            return getMinimumDate();
        },
        is_opened_whole_tab: function(){
            for (let i=0;i<this.require_validation.length;i++){
                if (!this.require_validation[i]){
                    return false;
                }

            }
            return true;

        },
        async close_whole_accordion(){
            for (let i=0;i<this.require_validation.length;i++){
                this.require_validation[i] = false;
            }
        },
        // 탭 화면을 열고 검증을 진행함
        async open_whole_accordion(){
            // For validation inspection, all of tabs should be opend
            for (let i=0;i<this.require_validation.length;i++){
                this.require_validation[i] = true;
            }
        },
        
        // toggle
        toggle_tab_accordian:function(idx){
            //alert('toggle_tab_accordian:'+field+", value:"+this.require_validation[field])
            this.require_validation[idx]=! this.require_validation[idx]

            //this.$refs['btn_submit'].disabled=true
            if (this.is_opened_whole_tab() && this.$refs['btn_overview'].disabled){
                //this.$refs['btn_submit'].disabled = false
                this.$refs['btn_overview'].disabled= true
            }
            else{
                //this.$refs['btn_submit'].disabled = true
                this.$refs['btn_overview'].disabled= false
            }

        },
        // 끝: 1. 환경 설정 및 Interface 동작 관련 함수들
        //================================================

        // 시작: 2. pb_obj 관련 함수
        //================================================

        // 2.1 Window와 객체 Initialize 함수들
        //------------------------------------------------
        // pb_obj 객체를 초기화한다.
        // @ return : empty
        init_pb_obj(){

            this.pb_obj.pb_diseasename_omim = '';
            this.pb_obj.pb_diseasename_hpo_textnumber='';
            this.pb_obj.pb_diseasename_hpo = '';

            this.pb_obj.text_pb_name =''
            this.pb_obj.text_pb_number = ''
            this.pb_obj.sel_pb_sex = ''
            this.pb_obj.age =''
            this.pb_obj.age_type = ''
            this.pb_obj.sel_pb_diseasename_omim=[{id:'',codes:'', title:'', value:'', textnumber:''}]
            this.pb_obj.sel_pb_diseasename_hpo=[{id:'',codes:'', title:'', value:'',textnumber:''}]

            this.pb_obj.pb_diseasename_text = '';
            this.pb_obj.text_pb_reqreferal_date ='';
            this.pb_obj.text_pb_birthday = ''
            this.pb_obj.text_pb_reqdate = ''
            this.pb_obj.text_pb_reqstatus = ''
            this.pb_obj.text_pb_requested = ''
            this.pb_obj.text_pb_reqscreening = ''
            this.pb_obj.text_pb_reqscreened =''
            this.pb_obj.result_url=''
            this.pb_obj.text_key_clinical_info = ''
            this.pb_obj.api_auth_key_1 = ''
            this.pb_obj.api_auth_key_2 = ''
            this.pb_obj.sel_area_code = ''

            // 기타 중요 소견 추가
            this.pb_obj.text_pb_etcopinion ='';

            this.pb_obj.text_pb_blooddate ='';


            this.init_pb_uploadfiles()
        },
        // pb_obj 를 값으로 초기화한다.
        // init_pb_obj() 를 호출하고, values 값들로 복사한다.
        // @ return [true:문제가 없을 경우| false: values 값이 정의가 안된 경우]
        initialize_pb_as_values(values){
            this.init_pb_obj()
            // pb_obj.reqfiles 세팅
            if (values != "undefined" && values != null){
                this.copy_pb(values)

                // 질환명을 로드 한다.
                this.summarize_disease_data();
                this.v_pb$.$touch();
            }

            return true;
        },
        // pb_obj upload 파일 변수들을 초기화한다
        init_pb_uploadfiles(){
            this.pb_obj.reqfiles=[]
            this.pb_obj.optfiles=[]
        },

        //------------------------------------------------

        // 2.2 로컬 브라우저 조작과 관련된 함수들 (함수규칙: 조작[delete|store]_temp_pb_obj)
        //------------------------------------------------
        // 로컬웹브라우저에 임시로 저장된 pb_obj 를 삭제 한다.
        // @ 변수명: pb_obj_info
        // init_pb_obj 를 호출해서 empty pb_obj를 만듬
        // localStorage에 저장된 pb_obj data를 삭제한다.
        delete_temp_pb_obj:function(){ // 기본 환자 정보 임시삭제
            localStorage.removeItem('pb_obj_info');
            this.init_pb_obj();
            alert ('The tempoaray data has been deleted')
            return true
        },
        // 웹 인터페이스에 저장된 pb_obj 값을 로컬웹브라우저에 임시로 저장한다.
        // validation 수행 후 저장
        // @ 변수명: pb_obj_info
        store_temp_pb_obj: function(){ //기본 환자 정보 임시 저장



            //basis_patient 데이터에 대한 전체적인 유효성을 점검한다
            const validate_result = this.validate_pb_data();
            if (!validate_result){
                alert ('기본 환자 정보를 임시적으로 저장하는데 문제가 있습니다.')

                return false;
            }

            // 질환명을 요약해서 저장될 변수에 미리 데이터 저정함
            this.summarize_disease_data();



            //alert ('store_temp_pb:'+JSON.stringify(this.pb_obj))

            localStorage.setItem('pb_obj_info',JSON.stringify(this.pb_obj));

            alert ('기본 환자 정보를 임시적으로 저장 하였습니다. 업로드될 파일 정보는 브라우저 보안상 저장되지 않습니다.')
            return true

        },
        //------------------------------------------------

        // 2.3 validation 관련 함수들
        //------------------------------------------------
        is_valid_pb_field(_field){
            return !this.v_pb$[_field].$error
        },
        // 기본 환자 정보 입력 데이터에 대한 validation을 수행한다.
        validate_pb_data(){

            // UPDATE 모드일 경우, old_patient_number 는 중복 검사에서 제외한다.
            if (this.get_CRUDENV_mode() =='UPDATE' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'){
                if (this.old_text_pb_number != this.pb_obj.text_pb_number){
                    if (this.patient_number_list.includes(this.pb_obj.text_pb_number)){
                        alert ('이미 사용된 환자 번호 입니다.')
                        this.$refs['text_pb_number'].focus();
                        return false;
                    }
                }
            }
            else{
                if (this.patient_number_list.includes(this.pb_obj.text_pb_number)){
                    alert ('이미 사용된 환자 번호 입니다.')
                    this.$refs['text_pb_number'].focus();
                    return false;
                }
            }


            // 환자 이름 정보
            if (!this.run_validate_atomic(this.v_pb$.text_pb_name,'환자 이름','text_pb_name')){
                return false;
            }

            // 환자 번호 정보
            if (!this.run_validate_atomic( this.v_pb$.text_pb_number,'환자 번호','text_pb_number')){
                return false;
            }

            // 환자 성별 정보
            if (!this.run_validate_atomic( this.v_pb$.sel_pb_sex,'환자 성별','sel_pb_sex')){
                return false;
            }

            // 환자 지역 정보
            if (!this.run_validate_atomic( this.v_pb$.sel_area_code ,'환자 지역','sel_area_code')){
                return false;
            }

            // 환자 기타 중요 소견
            if (!this.run_validate_atomic( this.v_pb$.text_pb_etcopinion,'환자 기타 중요 소견','text_pb_etcopinion')){
                return false;
            }

            // 환자 채혈일 정보
            if (!this.run_validate_atomic( this.v_pb$.text_pb_blooddate,'환자 채혈일','text_pb_blooddate')){
                return false;
            }

            // 날짜 정보들(req_referal_date, birthday, age) 관련 validation
            if (!this.validate_pb_dates()){
                return false;
            }

            // 질환명 (hpo, omim) 관련 validation
            if (!this.validate_pb_data_diseasename()){

                return false;
            }

            if (! this.run_validate_atomic(this.v_pb$.pb_diseasename_text,'Clinical diagnosis','pb_diseasename_text')){
                return false;
            }

            // 담당자 휴대폰 정보
            // if (!this.run_validate_atomic(this.v_pb$.stakeholder.text_sms_stakeholder_phonenumber,'담당자 연락처','text_sms_stakeholder_phonenumber')){
            //     return false;
            // }
            // // 담당의 휴대폰 정보
            // if (!this.run_validate_atomic(this.v_pb$.stakeholder.text_sms_doctor_phonenumber,'담당의사 연락처','text_sms_doctor_phonenumber')){
            //     return false;
            // }


            return true
        },
        // 기본 환자 정보 중 날짜 관련된 항목들(생년월일, 진단 의뢰일, 나이)를 점검한다.
        validate_pb_dates(){
            //console.log('validate_pb_dates')

            // text_pb_birthday
            const callable_condition_birthday = this.is_valid_pb_birthday();
            if (!callable_condition_birthday){
                alert ('환자 생년월일 기본 정보 입력에 문제가 있습니다. ');
                this.$refs['text_pb_birthday'].focus();
                return false;
            }
            const callable_condition_reqreferaldate = this.is_valid_pb_reqreferal_date();
            if (!callable_condition_reqreferaldate){
                alert ('환자 진단 의뢰일 기본 정보 입력에 문제가 있습니다. ');

                this.$refs['text_pb_reqreferal_date'].focus();
                return false;
            }

            const age = base_parser.get_age(this.pb_obj.text_pb_reqreferal_date, this.pb_obj.text_pb_birthday)

            if (age < 0 ){
                alert ('환자 진단의뢰일 정보와 환자 생년월일 정보를 바탕으로 나이를 계산할 수 없습니다.');
                this.$nextTick(()=>{
                    this.$refs['text_pb_birthday'].focus();
                });

                return false;
            }

            // "계산" 버튼이 안눌러져있을 경우,
            if (!this.run_validate_atomic( this.v_pb$.age,'환자 나이 기본','btn_calc_patient_age')){
                return false;
            }

            return true;



        },

        // 나이를 계산하기 위한 상태 점검
        is_valid_callable_pb_age(){
            const callable_condition = (this.is_valid_pb_reqreferal_date() && this.is_valid_pb_birthday())
            //console.log ('is_valid_callable_pb_age()');

            if (callable_condition){
               const age = base_parser.get_age(this.pb_obj.text_pb_reqreferal_date, this.pb_obj.text_pb_birthday)
                if (age < 0 ){
                    return false;
                }
                return true;
            }
            else{
                return false;
            }
        },
        // basisspatient req_referal_date 이 유효한지 반환
        is_valid_pb_reqreferal_date(){
            // console.log ('is_valid_pb_reqreferal_date()');
            return (this.is_valid_pb_field('text_pb_reqreferal_date') && this.pb_obj.text_pb_reqreferal_date != '')
        },
        // basisspatient birthday가 유효한지 반환
        is_valid_pb_birthday(){
            //console.log ('is_valid_pb_birthday()');
            return (this.is_valid_pb_field('text_pb_birthday') && this.pb_obj.text_pb_birthday != '')
        },
        is_valid_pb_disease_text(){

            return (this.is_valid_pb_field('pb_diseasename_text') && this.pb_obj.diseasename_text != '')
        },

        //--------------------
        // 질환명 시작
        // 입력된 질환명 검증을 진행한다.
        is_validate_pb_diseasename:function(){
            // const omim_callable_condition = this.is_valid_pb_disease_value('omim');

            const hpo_callable_condition =  this.is_valid_pb_disease_value('hpo');
            //const disease_text_condition =  this.is_valid_pb_disease_text()

            // const text_callable_condition = this.pb_obj.pb_diseasename_text.length == 0 ? false: true;
            // if (omim_callable_condition || hpo_callable_condition || text_callable_condition){
            //     return true;
            // }
            // if (hpo_callable_condition && disease_text_condition){
            if (hpo_callable_condition ){
                return true;
            }


            return false;

        },
        // 질환명 입력 값이 유효한지를 검사한다.
        is_valid_pb_disease_value(disease_type){
            // alert ('validate_disease_format')



            let p_data_array = this.pb_obj.sel_pb_diseasename_omim;
            if (disease_type=="hpo"){
                p_data_array = this.pb_obj.sel_pb_diseasename_hpo;
            }
            if (p_data_array.length == 0 ){
                return false;
            }


            // 리스트에서 선택한 값이 있는지 점검한다
            for (let j=0;j<p_data_array.length;j++){
                const value = p_data_array[j].value


                if (!this.is_pb_disease_value_selected(value,disease_type)){

                    if (value != ''){ // 입력된 값이 리스트에 없음 (임의로 타이핑된 값)
                        return false;
                    }

                }
                if (disease_type=='hpo'){ // 둘다 비어 있으면
                    if (p_data_array[j].textnumber == '' && value == ''){
                        return false
                    }
                }
                else{
                    if (value == ''){
                        return false
                    }
                }
            }




            return true;
        },
        // disease_type 에 키보드로 입력한 값이 있을 경우를 대비해서, 입력된 값이 기존 omim,hpo list에 있는 값과 일치하는지를 파악함, 선택된 값에 해당하는 값들로 제한
        is_pb_disease_value_selected(checkingvalue,disease_type){
        //check_disease_format(checkingvalue,disease_type){

            let val =checkingvalue;
            let select = false;
            let options = this.omim_list;
            if (disease_type == 'hpo'){
                options = this.hpo_list;
            }
            for (var i = 0; i < options.length; i++) {
                //check if value in input box is one of the options
                if (options[i].display === val.trim()) {
                    //value was selected
                    //do something
                    select = true;
                    //bluring input field so as not to show datalist again
                    //event.target.blur();
                    break;
                }
            }
            //value was typed
            if (!select) {

                return false;
                //this.fetchAutocomplete(val);
            }
            return true;
        },
        validate_pb_data_diseasename: function(){
            //touch 실행


            if (this.is_validate_pb_diseasename()){
                // console.log('validate_pb_data_diseasename')
                return true;
            }


            alert ('입력된 질환명이 유효하지 않습니다.')



            let ref_id = 'btn_disease_hpo_add';
            if (this.pb_obj.sel_pb_diseasename_hpo.length == 0 ){
                this.$refs[ref_id].focus();
            }else{
                ref_id = 'sel_pb_diseasename_hpo_'+ (this.pb_obj.sel_pb_diseasename_omim.length -1);
                this.$refs[ref_id][0].focus();
            }


            return false
        },

        // upload 파일에 대한 validation을 수행한다.
        validate_pb_uploadfiles(){

            if (!this.is_valid_callable_pb_age()){
                //this.validate_pb_dates();
                alert ('환자의 나이를 계산할 수 있는 유효한 날짜 입력 부탁드립니다.')
                return false;
            }
            //const essential_files = this.get_age_type()
            if (this.pb_obj.reqfiles.length <= 0){
                this.$refs['pb_reqfiles_Input'].focus()
                return false;

            }
            return true;
        },
        //------------------------------------------------

        // 2.4 웹이벤트, 배열 조작과 관련된 함수들
        //------------------------------------------------
        // 나이를 계산하여 age 변수를 변경함으로써, 필수 업로드 파일 목록을 보여준다.
        show_pb_uploadfiles(){
            if (this.is_valid_callable_pb_age()){
                this.calculate_pb_age();
            }
        },
        // UPDATE시 patient db id 를 반환한다.
        get_patch_pb_id(){
            const req_id = this.pb_obj.id
            if (req_id == undefined){
                alert ('의뢰 요청한 환자 ID가 없습니다.')
                return false
            }
            return req_id
        },
        // calcuate_[json]_[jsonfield]
        // [json].[jsonfield]의 값을 계산하고 반영한다.
        // 계산한 나이를 인터페이스에 반영한다
        calculate_pb_age: function(){
            const age= base_parser.get_age(this.pb_obj.text_pb_reqreferal_date, this.pb_obj.text_pb_birthday)
            this.pb_obj.age = age;
            this.pb_obj.age_type = this.get_age_type(age)
        },
        // 나이 타입에 맞는 종류를 반환한다.
        get_age_type: function(age){
            // const age = this.pb_obj.age
            if ( age >= -1  && age <= 6){
                return "EARLYCHILDHOOD";
            }
            else if (age >=7 && age <=12){
                return "MIDDLECHILDHOOD";
            }
            else{
                return "ADOLESCENCE";
            }
        },
        // localstorage에 임시저장된 disasename으로 select box 데이터를 채운다
        load_diseasename(_values,_disease_type){
            if (_disease_type=="hpo"){
                this.pb_obj.sel_pb_diseasename_hpo=[];
            }
            else{
                this.pb_obj.sel_pb_diseasename_omim=[];
            }

            for (let j=0;j<_values.length;j++){
                if (_disease_type=="hpo"){
                    // this.pb_obj.sel_pb_diseasename_hpo.push({'id':_values[j].id,'codes':_values[j].codes,'title':_values[j].title, 'value': _values[j].value});
                    this.pb_obj.sel_pb_diseasename_hpo.push({'id':_values[j].id,'codes':_values[j].codes,
                        'title':_values[j].title, 'value': _values[j].value,
                        'textnumber':_values[j].textnumber});


                }
                else if (_disease_type =="omim"){
                    this.pb_obj.sel_pb_diseasename_omim.push({'id':_values[j].id,'codes':_values[j].codes,
                        'title':_values[j].title,
                        'value': _values[j].value});
                }
            }
            if (_disease_type=="hpo"){
                this.pb_obj.pb_diseasename_hpo = '';
                this.pb_obj.pb_diseasename_hpo_textnumber='';
            }
            else{
                this.pb_obj.pb_diseasename_omim = '';
            }
        },
        // 선택된 질병 입력 항목들로 부터 pb_diseasename_hpo 혹은 basis_disasename_omim 데이터를 완성한다.
        // 구분자는 "|"로 연결해서 backend로 전송한다
        // "임시 저장" 버튼 클릭시 local storage에 데이터 저장을 위해 호출
        // "데이터 전송" 버튼 클릭시 호출됨
        set_diseasename:function(disease_type){
            let p_data_array = this.pb_obj.sel_pb_diseasename_omim;
            let processed_diseasename = "";
            let processed_textnumber = "";
            //alert ('start perform_diseasename:'+disease_type);

            if (disease_type=="hpo"){
                p_data_array = this.pb_obj.sel_pb_diseasename_hpo;

            }

            for (let j=0;j<p_data_array.length;j++){
                const value = p_data_array[j].value
                const textnumber = p_data_array[j].textnumber


                if (j ==0){

                    processed_diseasename =value
                    if (disease_type=='hpo'){
                        processed_textnumber = textnumber
                    }

                }
                else{
                    processed_diseasename = processed_diseasename +"|"+value
                    if (disease_type=='hpo'){
                       processed_textnumber = processed_textnumber +"|"+textnumber
                    }

                }
            }

            if (disease_type=="hpo"){
                this.pb_obj.pb_diseasename_hpo = processed_diseasename;
                this.pb_obj.pb_diseasename_hpo_textnumber = processed_textnumber;
            }
            else{
                this.pb_obj.pb_diseasename_omim = processed_diseasename;
            }

            //alert ('end perform_diseasename:'+disease_type);

        },
        // pb_obj 의 disease point 반환
        get_disease_data_structure(disease_type){
            if (disease_type == "omim"){
                return this.pb_obj.sel_pb_diseasename_omim;
            }
            else if (disease_type == "hpo"){
                return this.pb_obj.sel_pb_diseasename_hpo;
            }
            return this.pb_obj.sel_pb_diseasename_omim;
        },
        // add disease tr layer
        add_diseasename(disease_type){
            let new_data ={
                value:'',
                textnumber:''

            }
            let p = this.get_disease_data_structure(disease_type);

            p.push(new_data);
        },
        // remove disease tr layer
        remove_diseasename(disease_type,idx){
            let p = this.get_disease_data_structure(disease_type);
            p.splice(idx, 1);
        },
        // 데이터 저장 및 전송을 위해 질병 데이터를 정리한다.
        summarize_disease_data(){
            this.set_diseasename('omim');
            this.set_diseasename('hpo');
        },

        // copy_values 값들을 this.pb_obj 에 복사한다
        copy_pb(copy_values){
            this.init_pb_obj()

            const values = JSON.parse(copy_values)
            base_parser.clone(values, this.pb_obj);
            //alert ('loading temporary saved data :'+JSON.stringify(this.pb_obj));

            //  values로 부터 업로드된 파일 명을 재 배열 한다.
            this.init_pb_uploadfiles()


            // 필수로 업로드된 파일 명을 재 배열 한다
            const reqfiles = values.reqfiles
            for (let i=0;i<reqfiles.length;i++){

                const reqfile = reqfiles[i]
                if (reqfile.filename == undefined || reqfile.filname ==''){
                    break
                }
                const name = reqfile.filename
                const db_id =reqfile.id
                const file_path =reqfile.file_path
                this.pb_obj.reqfiles.push({name:name,id:db_id,file_path:file_path});
            }
            // 선택적으로 업로드된 파일 명을 재 배열 한다
            const optfiles = values.optfiles
            for (let i=0;i<optfiles.length;i++){
                const optfile = optfiles[i]
                const name = optfile.filename
                const db_id =optfile.id
                const file_path =optfile.file_path
                if (optfile.filename == undefined || optfile.filname ==''){
                    break
                }
                this.pb_obj.optfiles.push({name:name,id:db_id,file_path:file_path});
            }
            // this.rearrange_uploadfiles_meta(values.uploadfiles_meta, 'PB')

            // omim 과  hpo 관련 localStroage에 저장된 실제 backend 로 전송될 값(pb_diseasename_omim, pb_diseasename_hpo) 처리
            // console.log('copy_pb:copy_values:'+values)
            this.load_diseasename(values['sel_pb_diseasename_hpo'],'hpo')
            this.load_diseasename(values['sel_pb_diseasename_omim'],'omim')

        },
        //------------------------------------------------


        // 2.5 pb_obj 관련된 backend 통신 함수들
        //------------------------------------------------
        // pb_obj 를 업데이트(patch) 한다
        patch_pb_obj(){
            this.set_CRUDENV_loading(true);
            let userEmail = this.store.getters['userStore/getUserEmail']

           if (this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'){
                userEmail = this.pb_obj.writer
           }
           // alert ('userEmail:' + userEmail)

            // 파일 입출력을 제외한 pb_obj 데이터 validation을 수행한다.
            if (!this.validate_pb_data()){
                // this.init_disease_data();
                this.set_CRUDENV_loading(false);
                return false
            }
            // TODO : calcuate 질환명
            this.summarize_disease_data()
            

            api.patch_pb_obj(userEmail, this.pb_obj)
            .then(response =>{
                // console.log('ReqMain:function after patch_pb_obj (response)'+JSON.stringify(response))
                if (response['result'] == 'success'){
                    alert ('PART 1 기본 환자 정보가 수정되었습니다')
                    this.set_CRUDENV_loading(false);
                    return true
                }
                else{
                    alert ('PART 1 기본 환자 정보 수정에 문제가 있습니다.')
                    this.set_CRUDENV_loading(false);
                    return false
                }
            })
           

            this.set_CRUDENV_loading(false);
            return true

        },
        // 요청한 환자 진단 의뢰 요청함
        async patch_pb_obj_reqstatus(_status){
            const overview_result = await this.overview_data();

            if(!overview_result){
                alert ('입력하신 데이터에 문제가 있습니다.')
                return;
            }else{
                alert ('환자의 진단 의뢰를 요청 합니다. 의뢰된 환자는 중앙 진단 기관에서 검토 후, 검사 진행 됩니다. 의뢰 현황은 의뢰환자리스트에서 확인 할 수 있습니다.')
                this.set_CRUDENV_loading(true);

                const req_id = this.get_patch_pb_id()
                // console.log(req_id+_status)
                const userEmail = this.store.getters['userStore/getUserEmail']

                api.patch_pb_obj_reqstatus(userEmail,req_id,_status)
                .then(response =>{
                    console.log('mounted:function after patch_pb_obj_reqstatus (response)'+JSON.stringify(response))
                    if(_status == '검토중'){
                        alert("정상적으로 진단의뢰 신청이 완료 되었습니다.")
                    }
                    this.set_CRUDENV_loading(false);
                    this.set_CRUDENV_mode('LIST')
                    this.router.push({name:'ReqMain'});

                }).catch(err =>{
                    console.log(err)
                    this.set_CRUDENV_loading(false);
                })
                //
                this.set_CRUDENV_loading(false);

                this.router.push({name:'ReqMain'});
            }
        },

        //------------------------------------------------

        // 끝: 2. pb_obj 관련 함수
        //================================================

        // 시작: 3. family 관련 함수
        //================================================

        // 3.1 Window와 객체 Initialize 함수들
        //------------------------------------------------
        // fmaily 객체를 초기화한다.
        // @ return : empty
        init_family(){
             for (let i=0;i<this.family.length;i++){
                this.family.splice(i,1);
                i--;
            }
        },
        // family 를 값으로 초기화한다.
        // 초기화 할 값(values)이 정의가 안될 경우 init_family 를 호출해서 empty family를 만듬
        // @ return [true:문제가 없을 경우| false: values 값이 정의가 안된 경우]
        initialize_family_as_values(values){

            if (values != "undefined" && values != null){
                this.init_family()



                const js_values = JSON.parse(values)


                base_parser.clone(js_values, this.family);

                this.init_family_uploadfiles();

                // 필수로 업로드된 파일 명을 재 배열 한다
                for (let i=0;i<js_values.length;i++){
                    const family_info = js_values[i]
                    const reqfiles = family_info.reqfiles
                    for (let j=0;j<reqfiles.length;j++){

                        const reqfile = reqfiles[j]
                        if (reqfile.filename == undefined || reqfile.filname ==''){
                            break;
                        }
                        const name = reqfile.filename
                        const db_id =reqfile.id
                        const file_path =reqfile.file_path
                        this.family[i].reqfiles.push({name:name,id:db_id,file_path:file_path});
                    }

                    // // 선택적으로 업로드된 파일 명을 재 배열 한다
                    // const optfiles = family_info.optfiles
                    // for (let j=0;j<optfiles.length;j++){
                    //     const optfile = optfiles[j]
                    //     const name = optfile.filename
                    //     const db_id =optfile.id
                    //     if (optfile.filename == undefined || optfile.filname ==''){
                    //         break;
                    //     }
                    //     this.family[i].optfiles.push({name:name,id:db_id});
                    // }

                }

                for (let i=0;i<this.family.length;i++){
                    this.calculate_family_age(i)
                }


                this.v_family$.$touch();

            }
            else{
                this.init_family();
            }
            return true
        },
        // family upload 파일 변수들을 초기화한다
        init_family_uploadfiles(){
        // local Storage에 저장된 부분은 파일 접근 관련 보안 문제로 인해, 모호함. 그래서 파일 정보들은 초기화 해버림
            for (let i=0;i< this.family.length;i++){
                this.init_family_uploadfiles_idx(i)

            }
        },
        // 해당 _idx의 family upload 파일을 초기화한다
        //
        init_family_uploadfiles_idx(_idx){
            if ( _idx < 0 ||  _idx >= this.family.length ){
                alert ('Error] invalid idx in init_family_uploadfiles_idx reading '+_idx);
                return false;
            }
            this.family[_idx].reqfiles=[];
            this.family[_idx].optfiles=[];

        },
        //------------------------------------------------

        // 3.2 로컬 브라우저 조작과 관련된 함수들 (함수규칙: 조작[delete|store]_temp_family)
        //------------------------------------------------
        // 로컬웹브라우저에 임시로 저장된 family 를 삭제 한다.
        // @ 변수명: family_info
        // init_family 를 호출해서 empty family를 만듬
        // localStorage에 저장된 family data를 삭제한다.
        delete_temp_family:function(){ // 가족 정보 임시 삭제
            localStorage.removeItem('family_info');
            this.init_family();
            alert ('The tempoaray data has been deleted')
            return true
        },
        // 웹 인터페이스에 저장된 값(family)을 로컬웹브라우저에 임시로 저장한다.
        // validation 수행 후 저장
        // @ 변수명: family_info
        store_temp_family: function(){ // 가족정보 임시 저장
            const validate_result = this.validate_family();
            if (!validate_result){
                alert ('가족 정보를 임시적으로 저장하는데 문제가 있습니다.')
                return false;
            }
            localStorage.setItem('tmp_family_info',JSON.stringify(this.family));

            let tmp_family= JSON.parse(localStorage.getItem('tmp_family_info'))
            for (let i=0;i<tmp_family.length;i++){
                tmp_family[i].reqfiles=[]
                tmp_family[i].optfiles=[]
            }
            localStorage.setItem('family_info',JSON.stringify(tmp_family))
            localStorage.removeItem('tmp_family_info');

            alert ('The tempoaray data has been saved')
            return true
        },

        // 체크박스(this.family[j].checked] 에 해당하는 family element를 삭제 한다.
        remove_family(){ //가종 정보 행 삭제

            //for (let i = 0; i < this.family.collection.length; i++) {
            //    if (this.family.collection[i].checked) {
            for (let i = 0; i < this.family.length; i++) {
                if (this.family[i].checked) {
                    //console.log(i)

                    this.family.splice(i, 1);
                    i--;
                }
            }

        },

        // family에 element를 추가 한다.
        add_family(){ // 가족 정보 행 추가

            //const array_idx = this.family.length;
            this.family.push({
                checked:false,

                //array_idx:array_idx,

                text_family_name:'',
                sel_family_relation:'',
                // sel_family_generation:'',
                sel_family_sex:'',

                age:'',
                age_type:'',


                reqfiles:[],
                optfiles:[],


                // uploadfiles_meta:[{filename:''},{filename:''},{filename:''},{filename:''},{filename:''}],
                // uploadfiles:[null,null,null,null,null],


                text_family_birthday:'',
                sel_family_sympton:'',
                text_family_etc:'',
                show_uploadfiles:true,

            });
            return true;
        },

        filteredFamilyRelationOptions(index){
            const selectedValues = this.family.map(rel => rel.sel_family_relation);
            return this.familyRelationAllOptions.filter(option => {
                return !(selectedValues.includes(option) && (option === '부' || option === '모') && selectedValues.indexOf(option) !== index);
            });
        },

        convertFamilyRelationOptions(value){

            const selectedValue = value.trim()
            if (selectedValue == '형제'){
                return '남자형제'
            }
            else if (selectedValue=='자매'){
                return '여자형제'
            }
            else if (selectedValue=='기타남'){
                return "기타남('비고' 관계 입력 필 - '외조부','삼촌' 등)"
            }
            else if (selectedValue == '기타여'){
                return "기타여('비고' 관계 입력 필 - '외조모','고모' 등)"

            }
            return selectedValue
        },


        //------------------------------------------------

        // 5.3 validation 관련 함수들
        //------------------------------------------------




        //  birthday가 유효한지 반환
        is_valid_family_birthday(idx){
            //console.log ('is_valid_pb_birthday()');
            if (this.family.length >0){
                const birthday_error = this.v_family$['$each']['$response']['$data'][idx].text_family_birthday.$error

                return (! birthday_error)

            }
            return true

        },
        is_valid_callable_family_age(idx){
            const reqreferal =this.is_valid_pb_reqreferal_date()
            const family_birthday = (this.is_valid_family_birthday(idx)  && (this.family[idx].text_family_birthday !=''))
            if (reqreferal && family_birthday){
                const age = this.get_family_age(idx)
                if (age >= 0){
                    return true
                }
                return false

            }
            return false
        },
        show_family_uploadfiles(idx){
            if (this.is_valid_callable_family_age(idx)){
                this.calculate_family_age(idx);
            }
            else{
                this.family[idx].age =''
                this.family[idx].age_type = ''
            }
        },
        get_family_age:function(idx){
            const family_birthday = this.family[idx].text_family_birthday
            return base_parser.get_age(this.pb_obj.text_pb_reqreferal_date, family_birthday)
        },
        calculate_family_age: function(idx){
            if (this.is_valid_callable_family_age(idx)){
                this.family[idx].age = this.get_family_age(idx)
                this.family[idx].age_type = this.get_age_type(this.family[idx].age)
            }
            else{
                this.family[idx].age =''
                this.family[idx].age_type = ''
            }
        },

        // 인터페이스에 입력된 값들의 validation을 객체(v_family$)를 통한 검사 준비(touch)하고
        // 개별적인 validation(this.validate_family() 호출)을 진행한다.
        validate_wrap_family(){
           // console.log('validate_wrap_family')
            this.v_family$.$touch();
            return this.validate_family()
        },
        validate_family_obj(idx){
            const error_items = ['text_family_name','sel_family_relation','sel_family_sympton'];
            const error_words = ['이름','관계','증상유무']
            const f_validator = this.v_family$['$each']['$response']['$data'][idx];
            for (let j=0;j<error_items.length;j++){
                const v_data = f_validator[error_items[j]]

                // run_validate_value(_val_status, _word, _refid){
                if (!run_validate_value(v_data, (idx+1)+"번째 입력된 가족 "+error_words[j], this.$refs[error_items[j]+"_"+idx][0])){
                    return false;
                }
                

            }
            return true;

        },
        // 인터페이스에 입력된 값[family upload 파일(validate_family_uploadfiles 에서 수행)들 제외]의 validation을 수행하고,
        // validation error 인 $refs 객체에 error_words를 포함한 메세지를 출력 하고
        // focus 한다.
        validate_family(){

            for (let i=0;i<this.family.length;i++){
                if (!this.validate_family_obj(i))
                {
                    return false
                }
            }
            return true
        },
        // 인터페이스에 선택될 family file 값[family 값(validate_family 에서 수행)들 제외]의 validation을 수행하고,
        // validation error 인 $refs 객체 파일 인터페이스에 focus 한다.
        validate_family_uploadfiles(){
            for (let i=0;i<this.family.length;i++){
                if (this.family[i].reqfiles.length ==0){
                    const ref_ele = 'fa_reqfiles_Input'+i
                    this.$refs[ref_ele][0].focus()
                    alert (i+'-th 환자의 필수 동의서 업로드 파일이 지정되어 있지 않습니다.')
                    return false;
                }


            }

            return true;
        },
        //------------------------------------------------


        // 3.4 웹이벤트, 배열 조작과 관련된 함수들
        //------------------------------------------------

        // family relation 변경되면 환부모의 성별을 자동으로 선택함
        onFamilySexChange(event, idx){

            const family_relation = event.target.value
            //alert ('family_relation:'+family_relation)



            if (family_relation == '부' || family_relation == '형제' || family_relation == '기타남'){
                this.family[idx].sel_family_sex = "남";
            }
            else if (family_relation == '모' || family_relation == '자매' || family_relation == '기타여'){
                this.family[idx].sel_family_sex = "여";
            }
            else{
                this.family[idx].sel_family_sex = "";
            }
        },


        // 3.5 family 관련된 backend 단일 통신 함수들
        //------------------------------------------------
        // family object 를 삭제 한다
        delete_family_obj(idx){
            const up_family_obj= this.family[idx];
            // alsert (idx+" th-family clicked "+ JSON.stringify(up_family_obj))
            const userEmail = this.store.getters['userStore/getUserEmail']

            if (up_family_obj.id === undefined){ // id 가 없는 경우 사용자가 인터페이스 상에서 가족 구성원 추가한 경우임
                this.family.splice(idx,1)
            }
            else{
                api.delete_family_obj(userEmail,up_family_obj.id)
                .then(response =>{
                    // console.log('ReqMain:function delete_family_obj (response)'+JSON.stringify(response))
                    if (response['result'] == 'success'){
                        this.family.splice(idx,1)
                        alert ('PART II 가족 구성원 정보가 삭제 되었습니다')
                        this.set_CRUDENV_loading(false);
                        return true
                    }
                    else{
                        alert ('PART II 가족 구성원 정보 삭제에 문제가 있습니다.')
                        this.set_CRUDENV_loading(false);
                        return false
                    }


                })
            }



            this.set_CRUDENV_loading(false);
            return true

        },

        add_family_db(){
            const req_patient_id = this.pb_obj.id;
            const userEmail = this.store.getters['userStore/getUserEmail']

            this.add_family()

            api.add_family_db(userEmail,this.family[this.family.length-1],req_patient_id)
            .then(response =>{
                if (response['result'] == 'success'){

                    this.family[this.family.length-1].id = response['data']['family_index']['id']
                    this.set_CRUDENV_loading(false);

                }
                else{
                    alert ('새로운 PART II 가족 구성원 추가하는데, 문제가 있습니다.')
                    this.set_CRUDENV_loading(false);
                    return false
                }


            })
            return true
        },
        
        // family 를 업데이트(patch) 한다.
        patch_families(){
            const userEmail = this.store.getters['userStore/getUserEmail']
            const patient_id = this.pb_obj.id
            // console.log('useremail:'+userEmail+', patient_id:'+patient_id)

            // validation
            const validate_result = this.validate_wrap_family()
            if (!validate_result){
                return false;
            }
            const validate_familyfile_result = this.validate_family_uploadfiles()
            if (!validate_familyfile_result){
                // alert ('Family 필수 동의서 업로드 파일이 없습니다.')
                // const ref_ele = 'fa_reqfiles_Input'+idx
                // this.$refs[ref_ele].focus()
                return false;
            }
            api.patch_families(userEmail, patient_id, this.family,this.$fileconstant)
            .then(response =>{
                console.log('ReqMain:function patch_families (response)'+JSON.stringify(response))
            })
            return true;


        },
        // family object 를 업데이트(patch) 한다.
        patch_family_obj(idx){
            const up_family_obj= this.family[idx];
            // alert (idx+" th-family clicked "+ JSON.stringify(up_family_obj))
            const userEmail = this.store.getters['userStore/getUserEmail']


            const validate_result = this.validate_family_obj(idx)
            if (!validate_result){
                return false;
            }



            api.patch_family_obj(userEmail, up_family_obj)
            .then(response =>{
                // console.log('ReqMain:function patch_family_obj (response)'+JSON.stringify(response))
                if (response['result'] == 'success'){
                    alert ('PART II 가족 구성원 정보가 수정되었습니다')
                    this.set_CRUDENV_loading(false);
                    return true
                }
                else{
                    alert ('PART II 가족 구성원 정보 수정에 문제가 있습니다.')
                    this.set_CRUDENV_loading(false);
                    return false
                }
            })
            this.set_CRUDENV_loading(false);
            return true
        },
        //------------------------------------------------

        // 끝:  3. family 관련 함수
        //================================================

        // 시작: 4. ecrf_obj 관련 함수들
        //================================================

        // 4.1 Window와 객체 Initialize 함수들
        //------------------------------------------------
        // ecrf_obj 를 초기화한다.
        // @ return : empty
        init_ecrf_obj(){
            this.ecrf_obj={
                    imgscr: '',// 영상의학 검사
                    org: '', // 조직 검사
                    blood:'',   // 혈액검사
                    genescreen:'', // 유전자 검사
                }
        },
        // ecrf_obj 를 값(values)으로 초기화한다.
        // 초기화 할 값(values)이 정의가 안될 경우 init_sms_obj 를 호출해서 empty sms_obj를 만듬
        // @ return [true:문제가 없을 경우| false: values 값이 정의가 안된 경우]
        initialize_ecrf_as_values(values){

            if (values != "undefined" && values != null){

                base_parser.clone(JSON.parse(values), this.ecrf_obj);

                if (!this.ecrf_obj){
                    return false;
                }

            }
            else {
                this.init_ecrf_obj();

            }
            return true

        },
        //------------------------------------------------

        // 4.2 로컬 브라우저 조작과 관련된 함수들 (함수규칙: 조작[delete|store]_temp_sms_obj)
        //------------------------------------------------
        // 로컬웹브라우저에 임시로 저장된 ecrf_obj 를 삭제 한다.
        // @ 변수명: ecrf_info
        delete_temp_ecrf_info:function(){
            localStorage.removeItem('ecrf_info');
            alert ('The tempoaray data has been deleted')
            return true
        },
        // 웹 인터페이스에 저장된 값(ecrf_obj)을 로컬웹브라우저에 임시로 저장한다.
        // validation 수행 후 저장 (validate_ecrf_obj)
        // @ 변수명: ecrf_info
        store_temp_ecrf_info:function(){// ecrf_obj 정보 임시 저장

            if (this.validate_ecrf_obj()){
                localStorage.setItem('ecrf_info',JSON.stringify(this.ecrf_obj));
                alert ('임상 정보를 임시적으로 저장 하였습니다. 업로드될 파일 정보는 브라우저 보안상 저장되지 않습니다.')
                return true
            }
            else{
                alert ('임상 정보를 임시적으로 저장 하기에 문제가 있습니다.')
                return false;
            }
        },
        //------------------------------------------------

        // 5.3 validation 관련 함수들
        //------------------------------------------------

        // 인터페이스에 입력된 값들의 validation을 개별적으로 수행하고,
        // validation error 인 $refs 객체에 error_words를 포함한 메세지를 출력 하고
        // focus 한다.
        validate_ecrf_obj(){

            // 환자 필드 정보
            const validator_items = ['imgscr','org','blood','genescreen']
            const error_items = ['ecrf_obj_imgscr', 'ecrf_obj_org','ecrf_obj_blood','ecrf_obj_screen'];
            const error_words = ['영상의학 검사', '조직 검사','혈액 검사','유전자 검사']

            // 환자 이름 정보
            for (let i=0;i<error_items.length;i++){

                if (!this.run_validate_atomic(this.v_ecrf$[validator_items[i]],error_words[i],error_items[i])){
                    return false;
                }
            }
            return true;

        },

        // 인터페이스에 입력된 값들의 validation을 객체(v_ecrf$)를 통한 검사 준비(touch)하고
        // 개별적인 validation(this.validate_ecrf_obj() 호출)을 진행한다.
        validate_wrap_ecrf_obj(){

            this.v_ecrf$.$touch();
            return this.validate_ecrf_obj()

        },
        patch_sms_obj(){
            this.set_CRUDENV_loading(true);
            const userEmail = this.store.getters['userStore/getUserEmail']

            api.patch_sms_obj(userEmail, this.sms_obj, this.stakeholder_obj)
            .then(response =>{
                // console.log('ReqMain:function after patch_sms_obj (response)'+JSON.stringify(response))
                if (response['result'] == 'success'){
                    alert ('PART IV 담당자 정보가 수정되었습니다')
                    this.set_CRUDENV_loading(false);
                    return true
                }
                else{
                    alert ('PART IV 담당자 정보 수정에 문제가 있습니다.')
                    this.set_CRUDENV_loading(false);
                    return false
                }
            })

            this.set_CRUDENV_loading(false);
            return true

        },

        patch_ecrf_obj(){
            this.set_CRUDENV_loading(true);
            const userEmail = this.store.getters['userStore/getUserEmail']

            api.patch_ecrf_obj(userEmail, this.ecrf_obj)
            .then(response =>{
                // console.log('ReqMain:function after patch_ecrf_obj (response)'+JSON.stringify(response))
                if (response['result'] == 'success'){
                    alert ('PART III 임상 정보가 수정되었습니다')
                    this.set_CRUDENV_loading(false);
                    return true
                }
                else{
                    alert ('PART III 임상 정보 수정에 문제가 있습니다.')
                    this.set_CRUDENV_loading(false);
                    return false
                }
            })

            this.set_CRUDENV_loading(false);
            return true

        },
        // 끝: 4. ecrf_obj 관련 함수들
        //================================================

        // 시작: 5. sms_obj 관련 함수들
        //================================================

        // 5.1 Window와 객체 Initialize 함수들
        //------------------------------------------------
        // sms_obj 를 초기화한다.
        // @ return : empty
        init_sms_obj(){
            this.sms_obj.text_sms_stakeholder_name = ''
            this.sms_obj.text_sms_stakeholder_phonenumber = ''
            this.sms_obj.text_sms_stakeholder_email =''
            
            this.sms_obj.text_sms_request_doc_name = this.store.getters['userStore/getUserDoctorName']
            this.sms_obj.text_sms_request_doc_phonenumber = this.store.getters['userStore/getUserPhoneNumber']
            this.sms_obj.text_sms_request_doc_email = this.store.getters['userStore/getUserEmail']


        },
        // sms_obj 를 값으로 초기화한다.
        // 초기화 할 값(values)이 정의가 안될 경우 init_sms_obj 를 호출해서 empty sms_obj를 만듬
        // @ return [true:문제가 없을 경우| false: sms_obj 객체 복사가 안된 경우]
        initialize_sms_obj_as_values(values){
            this.init_sms_obj()

            if (values != "undefined" && values != null){

                // copy_values 값들을 this.family 에 복사한다

                // console.log('value:'+values)
                base_parser.clone(JSON.parse(values), this.sms_obj);

                if (!this.sms_obj){
                    return false;
                }

            }
            else{
                this.init_sms_obj();
            }

            return true;

        },

        //------------------------------------------------

        // 5.2 로컬 브라우저 조작과 관련된 함수들 (함수규칙: 조작[delete|store]_temp_sms_obj)
        //------------------------------------------------
        // 로컬웹브라우저에 임시로 저장된 sms_obj 를 삭제 한다.
        // @ 변수명: sms_obj
        // init_sms_obj 를 호출해서 empty sms_obj를 만듬
        delete_temp_sms_obj(){ //임시 삭제

            localStorage.removeItem('sms_obj');
            this.init_sms_obj();

            localStorage.removeItem('stakeholder_obj');
            this.init_stakeholder_obj();

            alert ('The tempoaray data has been deleted')
        },

        // 웹 인터페이스에 저장된 값(sms_obj,stakeholder_obj)을 로컬웹브라우저에 임시로 저장한다.
        // validation 수행 후 저장
        // @ 변수명: sms_obj, stakeholder_obj
        store_temp_sms_obj(){ //임시저장
            const validate_result = this.validate_sms_obj()
            if (!validate_result){
                // alert ('The tempoaray data has some problem in validation')
                return false;
            }
            localStorage.setItem('sms_obj',JSON.stringify(this.sms_obj));

            const validate_stakholder_obj = this.validate_stakeholder_obj()
            if (!validate_stakholder_obj){
                return false;
            }
            localStorage.setItem('stakeholder_obj',JSON.stringify(this.stakeholder_obj));

            alert ('The tempoaray data has been saved')
        },

        //------------------------------------------------


        // 5.3 validation 관련 함수들
        //------------------------------------------------
        // 인터페이스에 입력된 값들의 validation을 수행하고,
        // validation error 인 $refs 객체에 error_words를 포함한 메세지를 출력 하고
        // focus 한다.

        // reqdate, birthday, age, diseasename 제외한 validation을 수행한다.
        is_valid_sms_obj_field(_field){
            

            this.v_sms$[_field].$validate()
            if (this.v_sms$[_field] != undefined){
                return !this.v_sms$[_field].$error
            }

            return true
        },

        validate_sms_obj(){
            // For validation inspection, all of tabs should be opend
            // console.log('validate_sms_obj');
           // alert ('validate_sms')
            this.v_sms$.$touch();
            // console.log(this.v_sms$);


            const error_items = ['text_sms_stakeholder_name','text_sms_stakeholder_phonenumber','text_sms_stakeholder_email'];
            const error_words = [ '작성자 성함', '작성자 SMS 번호','작성자 EMail']

            for (let i=0;i<error_items.length;i++){
                const f_validator = this.v_sms$[error_items[i]]


                // run_validate_value(_val_status, _word, _refid){this.$refs[error_items[j]+"_"+i][0]
                if (!run_validate_value(f_validator, error_words[i], this.$refs[error_items[i]])){
                    return false;
                }


            }
            return true;

        },
        //------------------------------------------------

        // 끝: 5. sms_obj 관련 함수들
        //================================================


        // 시작: 6. stakeholder 관련 함수들
        //================================================
        init_stakeholder_obj(){

            for (let i=0;i<this.stakeholder_obj.length;i++){
                this.stakeholder_obj.splice(i,1);
                i--;
            }

        },
        initialize_stakeholder_as_values(values){
            this.init_stakeholder_obj()

            if (values != "undefined" && values != null){

                // copy_values 값들을 this.family 에 복사한다
                base_parser.clone(JSON.parse(values), this.stakeholder_obj);

                if (!this.stakeholder_obj){
                    return false;
                }

            }
            else{
                this.init_stakeholder_obj();
            }

            return true;

        },
        is_valid_stakeholder_field(_idx,_field){
            
            return !this.v_stakeholder_obj$['$each']['$response']['$data'][_idx][_field].$error

        },

        // 체크박스(this.sms_obj[j].checked] 에 해당하는 sms_obj element를 삭제 한다.
        remove_stakeholder_obj(){ // 행삭제
            for (let i = 0; i < this.stakeholder_obj.length; i++) {
                if (this.stakeholder_obj[i].checked) {
                    //console.log(i)
                    // alert ('remove_family_info:'+JSON.stringify(this.family[i]))
                    this.stakeholder_obj.splice(i, 1);
                    i--;
                }
            }


        },
        // sms_obj에 element를 추가 한다.
        // return 추가된 배열 인덱스
        add_stakeholder_obj(){ //행추가

            this.stakeholder_obj.push({
                checked:false,
                text_stakeholder_sms_number:'',
                text_stakeholder_email:''
            });
            // this.v_sms$.stakeholder.$validate()
            return this.cur_family_idx;
        },

        validate_wrap_stakeholder_obj(){
           // console.log('validate_wrap_stakeholder_obj')
            this.v_stakeholder$.touch();
            return this.validate_stakeholder_obj()
        },
        validate_stakeholder_obj(){
            const error_items = ['text_stakeholder_sms_number','text_stakeholder_email'];
            const error_words = ['SMS 연락처','Email']

            for (let i=0;i<this.stakeholder_obj.length;i++){
                const f_validator = this.v_stakeholder_obj$['$each']['$response']['$data'][i];
                for (let j=0;j<error_items.length;j++){
                    const v_data = f_validator[error_items[j]]

                    // run_validate_value(_val_status, _word, _refid){
                    if (!run_validate_value(v_data, (i+1)+"번째 입력된 연락처 "+error_words[j], this.$refs[error_items[j]+"_"+i][0])){
                        return false;
                    }

                }
            }
            return true;

        },




        // 끝: 6. stakeholder 관련 함수들
        //================================================

        // 시작: 7. 전체 validation 관련 함수들
        //=================================
        // overview 하기 위한 입력 의뢰 상황을 만든다. 윈도우 다 열고 required_conditions =true
        async overview_data(){

            this.open_whole_accordion();


            const validate_result = await this.validate_wrap_metadata();
            // let user_message = '입력하신 의뢰 환자 정보에 대한 검토 결과 필수 입력 항목들은 다 입력하셨습니다. 서식 파일들 입력 후 제출 하시면 됩니다.'
            if (validate_result){
                if (this.get_CRUDENV_mode()=='ADD'){
                    // commented by dsryu
                    console.log('overview_data with ADD mode')
                    // this.$refs['btn_submit'].disabled=false
                    // this.$refs['btn_submit'].focus();
                }
                if (this.get_CRUDENV_mode()=='UPDATE'){
                    // this.$refs['btn_request_screening'].disabled =false
                    // user_message= ' 진단 의뢰 요청 후에는 요청하신 데이터를 삭제 할 수 없습니다.'
                    return true
                }
                // alert (user_message)
                if(!this.onReqBPSubmit()){
                    // @submit="onReqBPSubmit()"
                    localStorage.removeItem('pb_obj_info');
                    localStorage.removeItem('family_info');
                    localStorage.removeItem('ecrf_info');
                    localStorage.removeItem('sms_obj');
                    localStorage.removeItem('stakeholder_obj');

                    
                }
            }
            else{
                alert ('입력하신 의뢰 환자 정보에 대한 검토 결과 더 입력해야할 항목들이 있습니다.')
                if (this.get_CRUDENV_mode()=='ADD'){

                    // commented by dsryu
                    // this.$refs['btn_submit'].disabled=true
                }
                return false;
            }
        },

        run_validate_atomic(_validator,_word, _refid){
            // 환자 이름 정보
            _validator.$touch();
            if (_validator.$error){
                alert (_word+ ' 정보 입력에 문제가 있습니다. ');
                this.$refs[_refid].focus();
                return false;
            }
            return true;
        },

        async validate_wrap_metadata(){

            const pb_validation = this.validate_pb_data();

            const family_validation = this.validate_wrap_family();

            const ecrf_obj_validation = this.validate_wrap_ecrf_obj();

            // SMS contractor 에 있는 값을 가져온다.
            //this.evt_proc_SMS_PROCESSED()

            const contract_sms_obj = this.validate_sms_obj();

            //-------------------------------
            return ( pb_validation && family_validation && contract_sms_obj && ecrf_obj_validation)
        },
        //
        // 끝: 7. 전체 validation 관련 함수들
        //=================================

        // 시작: 8. 파일 업로드 위한 함수들
        //=================================

        // 8.1 pb_obj.reqfiles
        //---------------------------------
        // 유형/필수 여부에 따른 파일 업로드 유형
        // 1. 유형 : 'pb' / 'f' 환자 / 가족
        // 2. 필수 여부 : 'e'/'n' 필수 / 미필수
        // pb_req_ onF-> 환자 필수 파일 이벤트
        pb_reqfiles_onClick () {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            this.$refs.pb_reqfiles_Input.click()
        },
        pb_reqfiles_onDragenter () {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            this.is_file_dragged = true
        },
        pb_reqfiles_onDragleave () {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            this.is_file_dragged = false
        },
        pb_reqfiles_onDragover (event) {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            event.preventDefault()
        },
        pb_reqfiles_onDrop (event) {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            event.preventDefault()
            this.is_file_dragged = false
            const files = event.dataTransfer.files
            this.pb_req_addFiles(files)
        },
        pb_reqfiles_onChange () {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            const files = event.target.files
            this.pb_req_addFiles(files)
        },
        pb_reqfiles_onHandleRemove (index) {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }

            // delete btn start
            const _file_idx = index;
            if (this.get_CRUDENV_mode() == 'UPDATE'){
                if(this.pb_obj.reqfiles[_file_idx]['id'] != undefined){
                    this.set_CRUDENV_loading(true);
                    api.deleteFileObj(this.$fileconstant.pb_prefix, this.pb_obj.reqfiles[_file_idx]['id'])
                    .then(response =>{
                        // console.log('ReqMain:cancel_pb_uploadfiles (response)'+JSON.stringify(response))
                        if (response['result'] != 'success'){
                            alert ('입력된 파일 정보 수정에 문제가 있습니다.')
                            this.set_CRUDENV_loading(false);
                            return false
                        }
                    }).catch(err =>{
                        console.log(err)
                        this.set_CRUDENV_loading(false);
                        return false
                    })

                    let ref_id = 'pb_reqfiles_Input'
                    this.$refs[ref_id].value=''
                    this.pb_obj.reqfiles[_file_idx].name=''// 메타 정보 삭제
                }
            }

            this.pb_obj.reqfiles.splice(index, 1)

            this.set_CRUDENV_loading(false);
            // delete btn end
        },
        pb_req_addFiles (files) {

            let filenamelist =[];
            let error_text = "";
            let process_stop_flag = false;
            for (let i=0;i<this.pb_obj.reqfiles.length;i++){
                const filename= this.pb_obj.reqfiles[i].name
                filenamelist.push(filename)
            }
            error_text = "중복된 파일의 업로드는 불가능합니다.\n 중복된 파일 : \n";

            for(let i = 0; i < files.length; i++){
                const target_file = files[i];
                if(filenamelist.includes(target_file.name)){
                    error_text += ("[" + target_file.name + "]\n")
                    process_stop_flag = true;
                }
            }

            if(process_stop_flag){
                alert(error_text);
                return false;
            }

            for(let i = 0; i < files.length; i++) {
                const src = this.pb_req_readFiles(files[i])

                files[i].src = src
                files[i].prefix = this.$fileconstant.pb_prefix
                files[i].req_id = this.pb_obj.id
                files[i].filetype='REQ'
                files[i].db_id ='-'

                if(this.get_CRUDENV_mode() == 'ADD'){
                    this.pb_obj.reqfiles.push(files[i])
                }
            }
            if (!files.length) return;
            // file 변경
            //--------------------

            if (this.get_CRUDENV_mode() == 'UPDATE' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'){
                this.set_CRUDENV_loading(true);

                //나중에 required or optional 구분해서 가져와야햠
                api.uploadFileObj(files)
                .then(response =>{
                    // console.log('mounted:function after change_pb_uploadfiles (response)'+JSON.stringify(response))
                    if (response['result'] == 'success'){
                        // alert ('기본 환자 정보가 수정되었습니다')
                        // DB 에 수정된 파일 정보 갱신, DB ID 설정 필요

                        for (let i =0;i<response['data']['message'].length;i++){
                            const updated_file_info = response['data']['message'][i]
                            this.pb_obj.reqfiles.push({name:updated_file_info.filename,id:updated_file_info.id, file_path:updated_file_info.file_path})
                        }

                        this.set_CRUDENV_loading(false);

                        return true
                    }

                    alert ('입력된 파일 정보 수정에 문제가 있습니다.')
                    this.set_CRUDENV_loading(false);
                    return false


                }).catch(err =>{
                    console.log(err)

                    this.set_CRUDENV_loading(false);
                    return false
                })

            }
        },
        // FileReader를 통해 파일을 읽어 thumbnail 영역의 src 값으로 셋팅
        async pb_req_readFiles (files) {
            return new Promise((resolve) => {
                const reader = new FileReader()
                reader.onload = async (e) => {
                resolve(e.target.result)
                }
                reader.readAsDataURL(files)
            })
        },
         //---------------------------------

        // 8.2 pb_optfiles
        //---------------------------------
        // 유형/필수 여부에 따른 파일 업로드 유형
        // 1. 유형 : 'pb' / 'f' 환자 / 가족
        // 2. 필수 여부 : 'e'/'n' 필수 / 미필수
        // pb_req_ onF-> 환자 필수 파일 이벤트
        pb_optfiles_onClick () {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            this.$refs.pb_optfiles_Input.click()
        },
        pb_optfiles_onDragenter () {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            this.is_file_dragged = true
        },
        pb_optfiles_onDragleave () {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            this.is_file_dragged = false
        },
        pb_optfiles_onDragover (event) {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            event.preventDefault()
        },
        pb_optfiles_onDrop (event) {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            event.preventDefault()
            this.is_file_dragged = false
            const files = event.dataTransfer.files
            this.pb_opt_addFiles(files)
        },
        pb_optfiles_onChange () {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            const files = event.target.files
            this.pb_opt_addFiles(files)
        },
        pb_optfiles_onHandleRemove (index) {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            // delete btn start
            const _file_idx = index;
            if (this.get_CRUDENV_mode() == 'UPDATE' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'){
                if(this.pb_obj.optfiles[_file_idx]['id'] != undefined){
                    this.set_CRUDENV_loading(true);
                    api.deleteFileObj(this.$fileconstant.pb_prefix, this.pb_obj.optfiles[_file_idx]['id'])
                    .then(response =>{
                        // console.log('ReqMain:cancel_pb_uploadfiles (response)'+JSON.stringify(response))
                        if (response['result'] != 'success'){
                            alert ('입력된 파일 정보 수정에 문제가 있습니다.')
                            this.set_CRUDENV_loading(false);
                            return false
                        }
                    }).catch(err =>{
                        console.log(err)
                        this.set_CRUDENV_loading(false);
                        return false
                    })

                    let ref_id = 'pb_optfiles_Input'
                    this.$refs[ref_id].value=''
                    this.pb_obj.optfiles[_file_idx].name=''// 메타 정보 삭제
                }

            }
            this.pb_obj.optfiles.splice(index, 1)

            this.set_CRUDENV_loading(false);
            // delete btn end
        },
        pb_opt_addFiles (files) {

            let filenamelist =[];
            let error_text = "";
            let process_stop_flag = false;
            for (let i=0;i<this.pb_obj.optfiles.length;i++){
                const filename= this.pb_obj.optfiles[i].name
                filenamelist.push(filename)
            }
            error_text = "중복된 파일의 업로드는 불가능합니다.\n 중복된 파일 : \n";

            for(let i = 0; i < files.length; i++){
                const target_file = files[i];
                if(filenamelist.includes(target_file.name)){
                    error_text += ("[" + target_file.name + "]\n")
                    process_stop_flag = true;
                }
            }

            if(process_stop_flag){
                alert(error_text);
                return false;
            }

            for(let i = 0; i < files.length; i++) {
                const src = this.pb_opt_readFiles(files[i])

                files[i].src = src
                files[i].prefix = this.$fileconstant.pb_prefix
                files[i].req_id = this.pb_obj.id
                files[i].filetype='OPT'
                files[i].db_id ='-'

                if(this.get_CRUDENV_mode() == 'ADD'){
                    this.pb_obj.optfiles.push(files[i])
                }
            }
            if (!files.length) return;
            // file 변경
            //--------------------

            if (this.get_CRUDENV_mode() == 'UPDATE' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'){
                this.set_CRUDENV_loading(true);

                //나중에 required or optional 구분해서 가져와야햠
                api.uploadFileObj(files)
                .then(response =>{
                    // console.log('mounted:function after change_pb_uploadfiles (response)'+JSON.stringify(response))
                    if (response['result'] == 'success'){
                        // alert ('기본 환자 정보가 수정되었습니다')
                        // DB 에 수정된 파일 정보 갱신, DB ID 설정 필요

                        for (let i =0;i<response['data']['message'].length;i++){
                            const updated_file_info = response['data']['message'][i]
                            this.pb_obj.optfiles.push({name:updated_file_info.filename,id:updated_file_info.id})
                        }

                        this.set_CRUDENV_loading(false);

                        return true
                    }

                    alert ('입력된 파일 정보 수정에 문제가 있습니다.')
                    this.set_CRUDENV_loading(false);
                    return false


                }).catch(err =>{
                    console.log(err)

                    this.set_CRUDENV_loading(false);
                    return false
                })

            }
        },
        // FileReader를 통해 파일을 읽어 thumbnail 영역의 src 값으로 셋팅
        async pb_opt_readFiles (files) {
            return new Promise((resolve) => {
                const reader = new FileReader()
                reader.onload = async (e) => {
                    resolve(e.target.result)
                }
                reader.readAsDataURL(files)
            })
        },




        // 7.2 family[i].reqfiles
        //---------------------------------
        // 유형/필수 여부에 따른 파일 업로드 유형
        // 1. 유형 : 'pb' / 'f' 환자 / 가족
        // 2. 필수 여부 : 'e'/'n' 필수 / 미필수
        // pb_req_ onF-> 환자 필수 파일 이벤트
        fa_reqfiles_onClick (family_idx) {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            //alert ('fa_reqfiles_onClick:family_idx'+family_idx)
            this.$refs['fa_reqfiles_Input'+family_idx][0].click()
        },
        fa_reqfiles_onDragenter () {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            this.is_file_dragged = true
        },
        fa_reqfiles_onDragleave () {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            this.is_file_dragged = false
        },
        fa_reqfiles_onDragover (event) {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            event.preventDefault()
        },
        fa_reqfiles_onDrop (family_idx) {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            event.preventDefault()
            //alert ('fa_reqfiles_onDrop:event:'+event+',family_idx:'+family_idx)

            this.is_file_dragged = false
            const files = event.dataTransfer.files
            this.fa_req_addFiles(family_idx,files)
        },
        fa_reqfiles_onChange (family_idx) {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            const files = event.target.files

            //alert ('fa_reqfiles_onChange:family_idx:'+family_idx)
            this.fa_req_addFiles(family_idx, files)
        },
        fa_reqfiles_onHandleRemove (family_idx,index) {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            //alert ('fa_reqfiles_onHandleRemove:family_idx:'+family_idx+',index:'+index)

            // delete btn start
            const _file_idx = index;
            if (this.get_CRUDENV_mode() == 'UPDATE' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'){
                if(this.family[family_idx].reqfiles[_file_idx]['id'] != undefined){
                    this.set_CRUDENV_loading(true);
                    api.deleteFileObj(this.$fileconstant.fi_prefix, this.family[family_idx].reqfiles[_file_idx]['id'])
                    .then(response =>{
                        // console.log('ReqMain:cancel_pb_uploadfiles (response)'+JSON.stringify(response))
                        if (response['result'] != 'success'){
                            alert ('입력된 파일 정보 수정에 문제가 있습니다.')
                            this.set_CRUDENV_loading(false);
                            return false
                        }
                        else{
                            let ref_id = 'fa_reqfiles_Input'+family_idx
                            this.$refs[ref_id][0].value=''
                            this.family[family_idx].reqfiles[_file_idx].name=''// 메타 정보 삭제
                        }
                    }).catch(err =>{
                        console.log(err)
                        this.set_CRUDENV_loading(false);
                        return false
                    })
                }
            }
            this.family[family_idx].reqfiles.splice(_file_idx, 1)

            this.set_CRUDENV_loading(false);
            // delete btn end
        },
        fa_req_addFiles (family_idx,files) {

            //alert ('fa_req_addFiles:family_idx:'+family_idx)

            let filenamelist =[];
            let error_text = "";
            let process_stop_flag = false;
            for (let i=0;i<this.family[family_idx].reqfiles.length;i++){
                const filename= this.family[family_idx].reqfiles[i].name
                filenamelist.push(filename)
            }
            error_text = "중복된 파일의 업로드는 불가능합니다.\n 중복된 파일 : \n";

            for(let i = 0; i < files.length; i++){
                const target_file = files[i];
                if(filenamelist.includes(target_file.name)){
                    error_text += ("[" + target_file.name + "]\n")
                    process_stop_flag = true;
                }
            }

            if(process_stop_flag){
                alert(error_text);
                return false;
            }

            for(let i = 0; i < files.length; i++) {
                const src = this.fa_req_readFiles(files[i])

                files[i].src = src
                files[i].prefix = this.$fileconstant.fi_prefix
                files[i].req_id = this.family[family_idx].id
                files[i].filetype='REQ'
                files[i].db_id ='-'

                if(this.get_CRUDENV_mode() == 'ADD'){
                    this.family[family_idx].reqfiles.push(files[i])
                }
            }
            if (!files.length) return;
            // file 변경
            // --------------------

            if (this.get_CRUDENV_mode() == 'UPDATE' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'){
                this.set_CRUDENV_loading(true);

                //나중에 required or optional 구분해서 가져와야햠
                api.uploadFileObj(files)
                .then(response =>{
                    // console.log('mounted:function after change_pb_uploadfiles (response)'+JSON.stringify(response))
                    if (response['result'] == 'success'){
                        // alert ('기본 환자 정보가 수정되었습니다')
                        // DB 에 수정된 파일 정보 갱신, DB ID 설정 필요

                        for (let i =0;i<response['data']['message'].length;i++){
                            const updated_file_info = response['data']['message'][i]
                            this.family[family_idx].reqfiles.push({name:updated_file_info.filename,id:updated_file_info.id})
                        }

                        this.set_CRUDENV_loading(false);

                        return true
                    }

                    alert ('입력된 파일 정보 수정에 문제가 있습니다.')
                    this.set_CRUDENV_loading(false);
                    return false


                }).catch(err =>{
                    console.log(err)

                    this.set_CRUDENV_loading(false);
                    return false
                })

            }

        },
        // FileReader를 통해 파일을 읽어 thumbnail 영역의 src 값으로 셋팅
        async fa_req_readFiles (files) {
            return new Promise((resolve) => {
                const reader = new FileReader()
                reader.onload = async (e) => {
                    resolve(e.target.result)
                }
                reader.readAsDataURL(files)
            })
        },
        //---------------------------------

        // 8.3 family[i].optfiles
        //---------------------------------
        // 유형/필수 여부에 따른 파일 업로드 유형
        // 1. 유형 : 'pb' / 'f' 환자 / 가족
        // 2. 필수 여부 : 'e'/'n' 필수 / 미필수
        // pb_req_ onF-> 환자 필수 파일 이벤트

        fa_optfiles_onClick (family_idx) {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            //alert ('fa_reqfiles_onClick:family_idx'+family_idx)
            this.$refs['fa_optfiles_Input'+family_idx][0].click()
        },
        fa_optfiles_onDragenter () {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            this.is_file_dragged = true
        },
        fa_optfiles_onDragleave () {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            this.is_file_dragged = false
        },
        fa_optfiles_onDragover (event) {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            event.preventDefault()
        },
        fa_optfiles_onDrop (event,family_idx) {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            event.preventDefault()
            //alert ('fa_reqfiles_onDrop:event:'+event+',family_idx:'+family_idx)

            this.is_file_dragged = false
            const files = event.dataTransfer.files
            this.fa_opt_addFiles(family_idx,files)
        },
        fa_optfiles_onChange (family_idx) {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            const files = event.target.files

            //alert ('fa_reqfiles_onChange:family_idx:'+family_idx)
            this.fa_opt_addFiles(family_idx, files)
        },
        fa_optfiles_onHandleRemove (family_idx,index) {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            //alert ('fa_reqfiles_onHandleRemove:family_idx:'+family_idx+',index:'+index)
            this.family[family_idx].optfiles.splice(index, 1)

            // delete btn start
            const _file_idx = index;
            if (this.get_CRUDENV_mode() == 'UPDATE' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'){
                if(this.family[family_idx].optfiles[_file_idx]['id'] != undefined){
                    this.set_CRUDENV_loading(true);
                    api.deleteFileObj(this.$fileconstant.fi_prefix, this.family[family_idx].optfiles[_file_idx]['id'])
                    .then(response =>{
                        // console.log('ReqMain:cancel_pb_uploadfiles (response)'+JSON.stringify(response))
                        if (response['result'] != 'success'){
                            alert ('입력된 파일 정보 수정에 문제가 있습니다.')
                            this.set_CRUDENV_loading(false);
                            return false
                        }
                    }).catch(err =>{
                        console.log(err)
                        this.set_CRUDENV_loading(false);
                        return false
                    })

                    let ref_id = 'fa_optfiles_Input'+family_idx
                    this.$refs[ref_id][0].value=null
                    this.family[family_idx].optfiles[_file_idx].name=''// 메타 정보 삭제
                }
            }


            this.set_CRUDENV_loading(false);
            // delete btn end
        },
        fa_opt_addFiles (family_idx,files) {

            let filenamelist =[];
            let error_text = "";
            let process_stop_flag = false;
            for (let i=0;i<this.family[family_idx].optfiles.length;i++){
                const filename= this.family[family_idx].optfiles[i].name
                filenamelist.push(filename)
            }
            error_text = "중복된 파일의 업로드는 불가능합니다.\n 중복된 파일 : \n";

            for(let i = 0; i < files.length; i++){
                const target_file = files[i];
                if(filenamelist.includes(target_file.name)){
                    error_text += ("[" + target_file.name + "]\n")
                    process_stop_flag = true;
                }
            }

            if(process_stop_flag){
                alert(error_text);
                return false;
            }

            for(let i = 0; i < files.length; i++) {
                const src = this.fa_opt_readFiles(files[i])

                files[i].src = src
                files[i].prefix = this.$fileconstant.fi_prefix
                files[i].req_id = this.family[family_idx].id
                files[i].filetype='OPT'
                files[i].db_id ='-'

                if(this.get_CRUDENV_mode() == 'ADD'){
                    this.family[family_idx].optfiles.push(files[i])
                }
            }
            if (!files.length) return;
            // file 변경
            //--------------------

            if (this.get_CRUDENV_mode() == 'UPDATE' || this.get_CRUDENV_mode() == 'UPDATEBYMANAGER'){
                this.set_CRUDENV_loading(true);

                //나중에 required or optional 구분해서 가져와야햠
                api.uploadFileObj(files)
                .then(response =>{
                    // console.log('mounted:function after change_pb_uploadfiles (response)'+JSON.stringify(response))
                    if (response['result'] == 'success'){
                        // alert ('기본 환자 정보가 수정되었습니다')
                        // DB 에 수정된 파일 정보 갱신, DB ID 설정 필요

                        for (let i =0;i<response['data']['message'].length;i++){
                            const updated_file_info = response['data']['message'][i]
                            this.family[family_idx].optfiles.push({name:updated_file_info.filename,id:updated_file_info.id})
                        }

                        this.set_CRUDENV_loading(false);

                        return true
                    }

                    alert ('입력된 파일 정보 수정에 문제가 있습니다.')
                    this.set_CRUDENV_loading(false);
                    return false


                }).catch(err =>{
                    console.log(err)

                    this.set_CRUDENV_loading(false);
                    return false
                })

            }

        },
        // FileReader를 통해 파일을 읽어 thumbnail 영역의 src 값으로 셋팅
        async fa_opt_readFiles (files) {
            return new Promise((resolve) => {
                const reader = new FileReader()
                reader.onload = async (e) => {
                    resolve(e.target.result)
                }
                reader.readAsDataURL(files)
            })
        },
        //---------------------------------


        //---------------------------------

        // 끝: 8. 파일 업로드 위한 함수들
        //=================================

        // Convert patient status
        convert_patient_status(_status){
            return base_parser.convert_patient_status(_status)
        },

        // 시작: 9. 전체 batch 관련 함수들
        //=================================
        // Backend로 부터 _req_id에 해당하는 pb_obj , family, ecrf_obj, sms_obj DB 값을 가져와서 설정한다.
        getRequestedPatient(_req_id){
            const userEmail = this.store.getters['userStore/getUserEmail']
            // 등록된 환자 리스트 만들기 (from backend)
            api.getRequestedPatient(userEmail,_req_id)
            .then(response =>{
                // console.log('before api.getRequestedPatient (response)'+JSON.stringify(response))
                const pb_obj = JSON.stringify(response.data['pb_obj'])

                
                this.initialize_pb_as_values(pb_obj)

                this.old_text_pb_number = this.pb_obj.text_pb_number




                this.initialize_family_as_values(JSON.stringify(response.data['family']))

                // // 파일 정보를 삭제 한다
                // for (let i=0;i<this.family.length;i++){
                //     this.family[i].reqfiles=[];
                // }

                this.initialize_ecrf_as_values(JSON.stringify(response.data['ecrf_obj'][0]))



                // response.data['processed_contract_sms]
                //      .text_stakeholder_email
                //      .text_stakholder_sms_number
                // 를 파싱해서 배열로 만든 뒤 initialize_stakeholder_as_values 로 넘긴다.
                const processed_contract_sms =response.data['processed_contract_sms']
                let stakeholder_obj = []
                if (processed_contract_sms['text_stakeholder_sms_number']!='' && processed_contract_sms['text_stakeholder_sms_number']!=null){
                    let sms_array = processed_contract_sms['text_stakeholder_sms_number'].split('|')
                    let email_array = processed_contract_sms['text_stakeholder_email'].split('|')

                    for (let i=0;i<sms_array.length;i++){
                        stakeholder_obj.push(
                            {
                                'text_stakeholder_email':email_array[i],
                                'text_stakeholder_sms_number':sms_array[i]
                            }
                        )
                    }
                }

                this.initialize_stakeholder_as_values(JSON.stringify(stakeholder_obj))

                // sms_obj processing
                response.data['processed_contract_sms'].text_stakeholder_email =''
                response.data['processed_contract_sms'].text_stakeholder_sms_number=''


                this.initialize_sms_obj_as_values(JSON.stringify(response.data['processed_contract_sms']))

            //    console.log('after api.getRequestedPatient (response)'+JSON.stringify(this.request_patient))

            }).catch(err =>{
                console.log(err)
            })


        },
        // store the whole input data to backend server
        async onReqBPSubmit(){

            if (this.is_CRUDENV_loading()){
                alert ('기존 입력에 대해 처리 중입니다.')
                return false
            }
            this.set_CRUDENV_loading(true);


            // validation
            //===============================
            // 1. basis_patient input data
            //-------------------------------

            const validate_metadata_result =  this.validate_wrap_metadata();
            if (!validate_metadata_result ){
                alert ('입력 데이터에 문제가 있습니다.')

                this.set_CRUDENV_loading(false);
                return false;

            }

            const validate_pb_uploadfiles = this.validate_pb_uploadfiles();
            if (!validate_pb_uploadfiles ){
                alert ('입력 데이터에 문제가 있습니다.')
                this.set_CRUDENV_loading(false);
                return false;

            }


            const validate_family_uploadfiles = this.validate_family_uploadfiles();
            if (!validate_family_uploadfiles ){
                alert ('입력 데이터에 문제가 있습니다.')
                this.set_CRUDENV_loading(false);
                return false;

            }

            //=================================
            // 2. 데이터 전송을 위해 각각의 정보를 취합한다.

            this.summarize_disease_data();
            // console.log ("part 1 pb_info:"+JSON.stringify(this.pb_obj))
            // console.log ("part 2 family info:"+JSON.stringify(this.family))
            // console.log ("part 3 ecrf info:"+JSON.stringify(this.ecrf_obj))
            // console.log ("part 4 sms contractor info:"+JSON.stringify(this.sms_obj))

            // data transfer to backend server
            //------------------------------------
            try {
                console.log('ReqMain.vue/onReqBPSubmit():')
                const userEmail = this.store.getters['userStore/getUserEmail']
                // Construct FormData for file uploads
                //----------------------
                // file upload 준비
                // form data
                if (this.get_CRUDENV_mode() == 'UPDATE' && this.pb_obj.id != ''){ // UPDATE
                    // // call vuex/actions/login (this.login means vuex/actions/login function. In main.js import store from '@/vuex/store.js)'
                    // let processResult = await api.sendRequestPatient(userEmail, this.pb_obj, this.family, this.ecrf_obj, this.sms_obj, this.$fileconstant );
                    // //alert('Login.vue/onSubmit result of userStore.login loginResult: '+loginResult)
                    // if (processResult.isAxiosError){
                    //     this.set_CRUDENV_loading(false);
                    //     return;
                    // }
                }
                else if (this.get_CRUDENV_mode() == 'ADD'){ // ADD
                    // call vuex/actions/login (this.login means vuex/actions/login function. In main.js import store from '@/vuex/store.js)'
                    // let processResult = await api.sendRequestPatient(userEmail, this.pb_obj, this.family, this.ecrf_obj, this.sms_obj, this.stakeholder_obj, this.$fileconstant);
                    api.sendRequestPatient(userEmail, this.pb_obj, this.family, this.ecrf_obj, this.sms_obj, this.stakeholder_obj, this.$fileconstant)
                    .then((processResult)=>{
                        if (processResult.isAxiosError){
                            this.set_CRUDENV_loading(false);
                            return false;
                        }
                        this.set_CRUDENV_loading(false);
                        this.set_CRUDENV_mode('LIST')

                        //this.router.push({name:'ReqMain'})
                        // this.router.push({path:'/request',replace:true})
                    })
                    //alert('Login.vue/onSubmit result of userStore.login loginResult: '+loginResult)
                }
                this.set_CRUDENV_loading(false);
                this.set_CRUDENV_mode('LIST');
                // //this.router.push({name:'ReqMain'})
                // this.router.push({path:'/request'})
            } catch (err) {
                console.error(err)
                //alert(err)
                this.set_CRUDENV_loading(false);
                return false
            }
            //------------------------------------
            this.set_CRUDENV_loading(false);
            return true;
        },
        // 끝: 9. 전체 batch 관련 함수들
        //=================================
        async doPrint(){

            const container = document.getElementsByClassName('container');
            for(let i=0; i<container.length; i++){
                container[i].style.maxWidth = '1000px';
                container[i].style.paddingLeft = '0px';
                container[i].style.marginLeft = '0px';
            }

            alert("프린트를 시작합니다.")
            window.print();

            for(let i=0; i<container.length; i++){
                container[i].style.maxWidth = '1200px';
                container[i].style.paddingLeft = 'var(--bs-gutter-x,.75rem)';
                container[i].style.marginLeft = 'auto';
            }
        },
	// patient delete function
        async deletePatient(){
            // 진단 의뢰 환자 리스트에서 채크된 정보 조회
            if(!confirm("정말 삭제하시겠습니까?")){
                return;
            }
            // 채크된 정보 validation
            if(this.checked_req_patient_list.length == 0){
                alert("삭제할 대상을 하나 이상 선택해 주시기 바랍니다.")
                return ;
            }
            // 채크된 정보 delete
            for (let index = 0; index < this.checked_req_patient_list.length; index++) {
                api.delete_patient_obj(this.checked_req_patient_list[index])
                    .then(response =>{
                        console.log('ReqMain:deletePatient (response)'+JSON.stringify(response))
                        if (response['result'] != 'success'){
                            alert ('의뢰 회원 삭제에 문제가 있습니다.')
                            this.set_CRUDENV_loading(false);
                            return false
                        }
			alert("삭제가 완료되었습니다.")
                        this.set_CRUDENV_mode('LIST');
                    }).catch(err =>{
                        console.log(err)
                        this.set_CRUDENV_loading(false);
                        return false
                    })
            }
            this.set_CRUDENV_loading(false);
            return true;

        },
        async on_download_file (file_path, file_name) {
            if(this.get_CRUDENV_mode() == 'DETAIL'){
                return;
            }
            api.downloadFileObj(file_path).then(response=>{
                let blob = new Blob([response.data], { type: response.headers['content-type'] })
                file_name = decodeURI(file_name) // 파일명 디코딩 (프로젝트에 따라 사용여부 옵션)
                if (window.navigator.msSaveOrOpenBlob) { // IE 10+
                    window.navigator.msSaveOrOpenBlob(blob, file_name)
                } else { // not IE
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.target = '_self'
                    if (file_name) link.download = file_name
                    link.click()
                }
            })
        },
    },
    created(){
        api.getOmimList()
        //axios.get('/hospital/') //,axios_config)
        .then(response => {
            //console.log('received data by API call')
            this.omim_list=null;
            this.omim_list = Object.freeze(response.data.map( d => ({
                display:d.title +'[' + d.code+ ']'
            })))


           // console.log(this.omim_list)
           //alert ('ReqMain:created()')
        }).catch(err=>{
            console.log(err)
        })

        api.getHpoList()
        //axios.get('/hospital/') //,axios_config)
        .then(response => {
            //console.log('received data by API call')
            this.hpo_list=null;
            this.hpo_list= Object.freeze(response.data.map( d => ({
            //this.hpo_list = response.data.map( d => ({
                //code: d.code,
                //id:d.id,
                //title:d.title,
                display:d.title +'[' + d.code+ ']'
            })))

           // console.log(this.omim_list)
           //alert ('ReqMain:created()')
        }).catch(err=>{
            console.log(err)
        })
    },
    mounted: function() {

        console.log('start mounted')
        this.set_CRUDENV_mode('LIST')
        console.log('completed mounted')



    },
    setup(){
        const store = useStore();
        // var temp_basis_info = JSON.parse(localStorage.getItem('pb_info'));
        // alert ('loaded:'+JSON.stringify(temp_basis_info));
        // this.pb_obj = temp_basis_info;

        return {store}
    }
}
</script>

<style scoped>
@import '@/assets/req.css'


/* $(".nav-tabs li a").click(function(){
  $(".nav-tabs li a").removeClass("active");
  $(this).addClass("active");
}); */
</style>

