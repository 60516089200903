// Vuex 때 처럼 create* 함수를 제공한다.
import { createWebHistory, createRouter } from 'vue-router';

// import Vue from 'vue'
// import VueRouter from 'vue-router'
import StatViewManager from '@/views/map/StatViewManager.vue'
import StatView from '@/views/map/StatView.vue'
import RegionBarChart from '@/views/map/RegionBarChart.vue'

import Home from '@/views/base/Home.vue'
import UserSignUp from '@/views/base/UserSignUp.vue'
import Login from '@/views/base/Login.vue'
import ReqMain from '@/views/requestscreen/ReqMain.vue'
import DiagnosisScreenList from '@/views/screen/DiagnosisScreenList.vue'
import DiagnosisScreenManageList from '@/views/screen/DiagnosisScreenManageList.vue'

import UserSignUpNotice from '@/views/base/UserSignUpNotice.vue'
import ReqManageMain from '@/views/requestscreen/ReqManageMain.vue'
import StatViewManagerHospitals from '@/views/map/StatViewManagerHospitals.vue';
import StatViewManagerPatients from '@/views/map/StatViewManagerPatients.vue';
import StatViewManagerAreaScreen from '@/views/map/StatViewManagerAreaScreen.vue';


{/* <router-link to="/home" class="nav-link">Home</router-link>
            <router-link to="/request" class="nav-link">진단의뢰관리</router-link>
            <router-link to="/manage" class="nav-link">진단검사관리</router-link>
            <router-link to="/logout" class="nav-link" click="onLogout">Logout</router-link> */}
const routes = [
  {
    path: '/statviewmanager_areascreen',
    name: 'StatViewManagerAreaScreen',
    component: StatViewManagerAreaScreen
  },
  {
    path:'/statviewmanager_patients',
    name: 'StatViewManagerPatients',
    component: StatViewManagerPatients
  },
  {
    path:'/statviewmanager_hospitals',
    name: 'StatViewManagerHospitals',
    component: StatViewManagerHospitals
  },
  {
    path:'/statviewmanager',
    name:'StatViewManager',
    component:StatViewManager
  },
  {
    path:'/statview',
    name:'StatView',
    component:StatView
  },
  {
    path:'/barchart',
    name:'RegionBarChart',
    component:RegionBarChart
  },
  {
    path: '/',
    name: 'Root',
    component: Home, // 동적 import,
//    beforeEnter: requireAuth()
  },
  {
    path: '/home',
    name: 'Home',
    component: Home, // 동적 import,
//    beforeEnter: requireAuth()
  },
  {
    path: '/request',
    name: 'ReqMain',
    component: ReqMain, // 동적 import,
  },
  {
    path: '/request_manage',
    name: 'ReqManageMain',
    component: ReqManageMain, // 동적 import,
  },
  {
    path: '/screen_list',
    name: 'DiagnosisScreenList',
    component: DiagnosisScreenList, // 동적 import,
  },
  {
    path:'/screen_manage_list',
    name:'DiagnosisScreenManageList',
    component:DiagnosisScreenManageList,

  },
  {
    path: '/usersignup',
    name: 'UserSignUp',
    component: UserSignUp,
  },
  {
    path: '/usersignupnotice',
    name: 'UserSignUpNotice',
    component: UserSignUpNotice,
  },
  {
    path:'/login',
    name:'Login',
    component: Login,
  },

];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

