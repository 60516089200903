<template>
  <div class="d-flex flex-row" id='#layout-schematics-frame' style="height:40px"/>
  <div class="d-flex flex-row" id='#layout-schematics-fram-heightspace' style="width:1300px" align="center">
    
    <div class="d-flex flex-column" id='#layout-schematics-pages' >
      <div class="d-flex flex-row" id='#layout-schematics-pages-row'>
        <!-- 시작: 좌측 이미지 -->
        <div class="d-flex flex-column" id='#layout-left-frame-leftcolumn' style="width:360px">
          <img src="../../assets/home_left.png" class="img-fluid" alt="이건희재단소개">
        </div>
        <!-- 끝: 좌측 이미지 -->
        <div class="d-flex flex-column" id='#layout-left-frame-leftcolumn' style="width:20px">
        </div>
        
        <!-- 시작: 취지와 소개글 그리고 지도 -->
        <div class="d-flex flex-column" id='#layout-center-frame-centercolumn' style="width:920px;vertical-align:top">
          <div class="d-flex flex-row" id="#laytout-center-row" style="width:100%">
            <!-- 시작: 취지와 소개글 -->
            <div class="d-flex flex-column" id='#layout-center-row-column' style="width:500px">
              <!--div class="d-fex flex-row" id="#layout-center-row-description" style="width:100%">
                <div class="d-flex flex-column" id='#layout-center-row-column' style="width:100%"-->
                  <!-- 시작: 사업 취지 설명 -->
                  <div class="d-flex flex-row">           
                    <div class="d-flex flex-column" >
                      <div class="bg-gradient text-white " style="width:500px; border-radius: 5px; background-color:#0D9344; padding:5px;font-weight:bold"> &nbsp; &nbsp; 이건희 소아암·희귀질환 극복사업 소개
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row">
                    <div class="d-flex flex-column" >
                      <div class="shadow-custom" style="height:130px;width:500px;font-size:0.87rem;padding:8px">
                        2021년 故이건희 회장 유족은 어린이의 암 및 희귀질환의 극복을 위해 서울대학교병원에 3천억원을 쾌척하셨습니다. 생명존중의 고귀한 뜻과 어린이의 건강하고 행복한 미래를 희망하시는 고인의 따뜻한 유산을 전하고자 서울대학교 어린이병원은 <b>소아암〮희귀질환지원사업단</b>을 발족하였고 <b>‘이건희 소아암<br/>·희귀질환 극복사업’</b>을 시작하였습니다.
                      </div>
                    </div>
                  </div>
                  <!--div class="d-flex flex-row">           
                    <div class="d-flex flex-column" style="height:10px" >
                    </div>
                  </div-->
                  <!-- 끝: 사업 취지 설명 -->
                  <!-- 시작: 사업 소개 -->
                  <div class="d-flex flex-row">
                    <div class="d-flex flex-column" >&nbsp; &nbsp; 
                      <div class="bg-gradient text-white " style="width:500px; border-radius: 5px; background-color:#0B2463; padding:5px;font-weight:bold">  &nbsp; &nbsp; 유전체/기능연구 기반 희귀질환 진단지원 사업 소개
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row">
                    <div class="d-flex flex-column" >
                      <div class="shadow-custom" style="height:170px;width:500px;font-size:0.87rem;padding:8px">
                        ‘이건희 소아암〮희귀질환 극복사업비’ 일부를 지원받아 기존의 일반적인 검사방법으로 진단받지 못한 희귀질환 어린이를 대상으로 환자 본인과 부모의 유전체 전체를 분석하여 해석함으로써 원인 유전자를 찾아 진단에 도움이 되게 하고자 하며, 유전자의 기능을 연구하여 미래 치료의 초석을 삼고자 하는 사업입니다. 또한 비유전자 질환으로, 검사의 희소성으로 인해 검사가 불가능한 경우, 환자 맞춤형 개별 검사를 시행하여 진단을 하고 환자들에게 치료와 관리 방안을 제시하고자 하는 사업입니다.
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row">           
                    <div class="d-flex flex-column" style="height:20px" >
                    </div>
                  </div>
                  <!-- 끝: 사업 소개 -->
                <!--/div> 
              </div-->
            </div>
            <!-- 끝: 취지와 소개글 -->
            <div class="d-flex flex-column" style="width:20px">

            </div>
            <!-- 시작: 지도와 signitures-->
            <div class="d-flex flex-column" id='#layout-center-row-column' style="width:360px">
              <div class="d-flex flex-row">
                <div class="d-flex flex-column" >
                  <div class="bg-gradient text-white " style="width:350px; border-radius: 5px; background-color:#109BF4; padding:5px;font-weight:bold"> &nbsp; &nbsp; 전국 참여병원 (2024. 12. 기준)
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="d-flex flex-column" style="width:355px">
                <img src="../../assets/home_right_2.png" class="shadow-custom" alt="참여병원" style="width:349px">
                
                </div>
              </div>
            </div>
            <!-- 끝: 지도와 signitures -->
          </div>
          <!-- 시작: 검사방법 -->
          <div class="d-flex flex-row" id="#laytout-center-row-process" style="width:100%">
            <div class="d-flex flex-column" id='#layout-center-row-column' style="width:100%">
              <div class="d-flex flex-row">
                <div class="d-flex flex-column" style="width:915px">        
                  <div class="bg-gradient text-white " style="width:95%; border-radius: 5px; background-color:#0B2463; padding:5px;font-weight:bold">  
                    &nbsp; &nbsp; 검사 방법 및 절차
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="d-flex flex-column" >
                  <!--div class="shadow-custom" style="width:107%;text-align:center"-->
                    <img src="../../assets/home_right.png" class="shadow-custom" alt="사업진행절차" style="width:868px">
                  <!--/div-->  
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="d-flex flex-column" style="height:10px" >
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="d-flex flex-column" style="width:50%"></div>
                <div class="d-flex flex-column" style="width:50%">
                  <img src="../../assets/signitures.png" class="img-fluid" width="400px">
                </div>
                
              </div>
            </div>
          </div>
          <!--끝: 검사방법 -->

        </div>
        <!-- 끝: 취지와 소개글 그리고 지도 -->
      </div>
    </div>
    <div class="d-flex flex-column" id='#layout-schematics-topspace' style='height:40px' />
  </div>
</template>

<script>

/* eslint-disable */

export default {
  name: 'Home',
  components: {
  //  'hospital-display-data':HospitalDisplayData
  },
  data() {
    return {

  //---------------
    }
  },
  async created() {
   
  },
  async mounted() {

  },
} // end of export default
</script>



<style lang="scss">
@import '@/assets/design.css';
.map-wrapper {
  position:relative;
  text-align: center;

  .province-title {
    position: absolute;
    top: 50px;
    left: 40px;
    width: 200px;
    color: #D5708B;
    z-index: 100;
    pointer-events: none;
    font-size: 0.9rem;
  }
  .province-info {
    // background: white;
    position: absolute;
    color: #D5708B;
    top: 50px;
    right: 60px;
    width: 600px;
    height: 500px;
    z-index: 101;
    font-size: 0.9rem;
    pointer-events: none;
  }
  .province-state {
    // background: white;
    position: absolute;
    color: #D5708B;
    bottom: 200px;
    right: 10px;
    width: 300px;
    z-index: 102;
    pointer-events: none;
  }
  .province-summary {
    // background: white;
    position: absolute;
    color: #D5708B;
    bottom: 10px;
    right: 10px;
    width: 300px;
    z-index: 103;
    pointer-events: none;
  }
  .province-state > ul, .province-summary > ul {
    list-style: none;
  }
  .background {
    /* fill: #021019; */
    fill: transparent;
    pointer-events: all;
  }
  .map-layer {
    fill: #08304b;
    stroke: #021019;
    stroke-width: 1px;
  }
}
</style>
