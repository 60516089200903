<template>
<div class = "container" style="max-width: 1600px;">
  <div class="row d-print-none"> <!--header-->
    <div class="col" >
      <main-header /> <!--@updateLoginStatus="$refs.forceRender()" /-->
    </div>
  </div> <!--header end-->
  <div class="row mb-3" style="width:1800px;height:1400px" v-if="isLoggedIn"> <!--body-->
    <div class="col-sm-10" > <!-- content -->
      <main style="width:100%;height:100%">
        <router-view />
        <!-- <req-main-view v-if="this.$route.path=='/request'" /> -->
        <!--req-manage-main-view v-if="this.$route.path=='/request_manager'" /-->
        <!--screen-main-view v-if="this.$route.path=='/screen_list'" /-->
        <!-- screen-manage-main-view v-if="this.$route.path=='/screen_manage_list'" /-->
      </main>
    </div> <!-- content end -->
  </div>
  <div class='col class="form-signin' v-else-if="this.$route.path=='/login' || this.$route.path=='/'">
    <login-view  />
  </div>
  <div class='col' v-else-if="this.$route.path=='/usersignup'">
    <usersignup-view />
  </div>
  <div class='col' v-else-if="this.$route.path=='/usersignupnotice'">
    <usersignupnotice-view />
  </div>
  <div class='col' v-else-if="this.$route.path=='/statview'">
    <stat-view/>
  </div>

  <div class='col' v-else-if="this.$route.path=='/home'">
    <home-view/>
  </div>

</div>
</template>

<script >
import MainHeader from '@/views/base/MainHeader.vue'
// import FunctionMenu from '@/views/base/FunctionMenu.vue'
import Login from '@/views/base/Login.vue'
import UserSignUp from '@/views/base/UserSignUp.vue'
import UserSignUpNotice from '@/views/base/UserSignUpNotice.vue'

/* 진단의뢰관리 */
import ReqMain from '@/views/requestscreen/ReqMain.vue'

import ReqManageMain from '@/views/requestscreen/ReqManageMain.vue'

import DiagnosisScreenList from '@/views/screen/DiagnosisScreenList.vue'
import DiagnosisScreenManageList from '@/views/screen/DiagnosisScreenManageList.vue'

/* StatView */
import StatView from '@/views/map/StatView.vue'

import HomeView from '@/views/base/Home.vue'


/* eslint-disable */

import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import {mapGetters,mapActions} from 'vuex'
const userStore = 'userStore'


export default {
  name: 'App',
  components: {
    'main-header':MainHeader,
    'login-view':Login,
    'usersignup-view':UserSignUp,
    'usersignupnotice-view':UserSignUpNotice,
    'req-main-view':ReqMain,
    'req-manage-main-view':ReqManageMain,
    'screen-main-view':DiagnosisScreenList,
    'screen-manage-main-view':DiagnosisScreenManageList,
    'stat-view':StatView,
    'home-view':HomeView,
  },
  computed : {
    ...mapGetters(userStore,['isLogin']),  //call vuex/userStore.js
    isLoggedIn : function(){
      //alert ('currentpath:'+this.$route.path )
      return this.isLogin
    },
  },
  setup(){
    const store = useStore();
    const router = useRouter ();

    return {store,router}
  }
}


</script>

<style scoped>

@import '@/assets/fonts.css'

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-floating:focus-within {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>

